import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  memo,
} from 'react';
import styles from './CreateInterviewForm.module.css';
import { IoChevronForward } from 'react-icons/io5';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { getApiUrl } from '../utils/apiUtils';
import { addMaxScore } from '../utils/InterviewCreation/AddMaxScore';
import { FaMinus, FaPlus, FaTrash } from 'react-icons/fa';
import { ClientUserContext } from '../context/ClientUserContext';
import { useNavigate } from 'react-router-dom';
import SKILLS_OPTIONS from '../assets/skills.js';
import templateWithAvatarImage from '../assets/template_with_avatar.png';
import templateWithRefTextImage from '../assets/template_with_ref_text.png';

const CreateInterviewForm = ({
  newInterviewFormData,
  setNewInterviewFormData,
  allEvaluationCategories,
  jobData,
}) => {
  const CRITERIA_OPTIONS = Object.values(allEvaluationCategories).reduce(
    (acc, criteria) => {
      const option = {
        label: `${criteria.name} [${criteria.evaluation_level}]`,
        value: criteria,
      };
      return [...acc, option];
    },
    []
  );
  const MAX_PAGES = 4;
  const FORM_PAGE_NAMES = [
    'Create Interview',
    'Instructions',
    'Questions',
    'Review',
  ];
  const VIDEO_TEMPLATE_FORMAT = {
    video_with_avatar:
      '[{"question_script": <question>, "question_set": <question_set|default 1>, "index": <index_number>},{"question_script": <question>, "question_set": <question_set|default 1>, "index": <index_number>}]',

    video_with_ref_text:
      '[{"question_script": <question>, "reference_text": <reference_text>, "question_set": <question_set|default 1>, "index": <index_number>},{"question_script": <question>, "reference_text": <reference_text>, "question_set": <question_set|default 1>, "index": <index_number>}]',
  };

  const [loading, setLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState();
  const [formError, setFormError] = useState();
  const [currentFormPage, setCurrentFormPage] = useState(1);
  const [generatedQuestions, setGeneratedQuestions] = useState();
  const { clientUser } = useContext(ClientUserContext);
  const navigate = useNavigate();
  console.log('Interviews Length: ' + jobData.interviews.length);
  const [additionalSetsGenerated, setAdditionalSetsGenerated] = useState(
    newInterviewFormData.numberOfQuestionSets === 1
  );
  const [currentQuestionSet, setCurrentQuestionSet] = useState(1);

  const isFormPageValid = (pageNumber) => {
    console.log('Checking for page:', pageNumber);
    if (pageNumber === 1) {
      if (newInterviewFormData.interviewName.length === 0) {
        setFormError('Interview Name cannot be empty.');
        return false;
      }
    } else if (pageNumber === 2) {
      if (newInterviewFormData.preInterviewInstructions.length === 0) {
        setFormError('Pre Interview Instructions cannot be empty.');
        return false;
      } else if (newInterviewFormData.welcomeMessage.length === 0) {
        setFormError('Welcome Message cannot be empty.');
        return false;
      }
    } else if (pageNumber === 3) {
      if (newInterviewFormData.interviewEvaluationCriteria.length === 0) {
        setFormError('Add atleast one evaluation criteria');
        return false;
      } else if (newInterviewFormData.skillsToEvaluate.length === 0) {
        setFormError('Add atleast one skill to evaluate');
        return false;
      } else if (
        newInterviewFormData.numberOfQuestionSets < 1 ||
        newInterviewFormData.numberOfQuestionSets > 5
      ) {
        setFormError('Number of sets should be in the range [1-5]');
        // setFormError('Number of sets should be 1');
        return false;
      } else if (
        newInterviewFormData.questionsPerSet < 1 ||
        newInterviewFormData.questionsPerSet > 9
      ) {
        setFormError(
          'Number of questions per set should be in the range [1-8]'
        );
        return false;
      } else if (!newInterviewFormData.timeLimitPerQuestion) {
        setFormError('Time limit per question cannot be empty.');
        return false;
      }

      const questionLevelEvaluationCount =
        newInterviewFormData.interviewEvaluationCriteria.filter(
          (criteria) => criteria.value.evaluation_level === 'question'
        ).length;
      console.log(questionLevelEvaluationCount);
      if (questionLevelEvaluationCount > 1) {
        setFormError(
          'Only one question level evaluation criteria can be selected.'
        );
        return false;
      }

      if (!generatedQuestions) {
        generateQuestions();
      }
    } else if (pageNumber === 4) {
      console.log(
        Object.values(generatedQuestions).filter(
          (question) => question.selected
        ).length
      );
      //   if (
      //     generatedQuestions &&
      //     Object.values(generatedQuestions).filter(
      //       (question) => question.selected
      //     ).length != (newInterviewFormData.questionsPerSet)*(newInterviewFormData.numberOfQuestionSets)
      //   ) {
      //     setFormError(
      //       `Select ${(newInterviewFormData.questionsPerSet)*(newInterviewFormData.numberOfQuestionSets)} questions (${newInterviewFormData.questionsPerSet} questions per set) to proceed.`
      //     );
      //     return false;
      //   }
      if (generatedQuestions) {
        const questionsBySet = Object.values(generatedQuestions).reduce(
          (acc, question) => {
            if (!acc[question.question_set]) {
              acc[question.question_set] = [];
            }
            acc[question.question_set].push(question);
            return acc;
          },
          {}
        );

        for (const setNumber in questionsBySet) {
          const selectedCount = questionsBySet[setNumber].filter(
            (question) => question.selected
          ).length;
          if (selectedCount != newInterviewFormData.questionsPerSet) {
            console.log("No. of ques for set no.: ", setNumber, "are: ", selectedCount)
            console.log("NnewInterviewFormData.questionsPerSet: ", newInterviewFormData.questionsPerSet)
            setFormError(
              `Select ${newInterviewFormData.questionsPerSet} questions for Set ${setNumber} to proceed.`
            );
            return false;
          }
        }
      } else if (
        !generatedQuestions ||
        Object.values(generatedQuestions).length === 0
      ) {
        setFormError(`You must generate questions to proceed.`);
        return false;
      }

      // promptVideoTemplate
      createEndToEndInterview();
      return false;
    }

    return true;
  };

  const handleNext = async (e) => {
    e.preventDefault();

    if (isFormPageValid(currentFormPage)) {
      setFormError(null);
      setCurrentFormPage((currentFormPage) => currentFormPage + 1);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    setFormError(null);
    if (currentFormPage === 1) return;
    setCurrentFormPage((currentFormPage) => currentFormPage - 1);
  };

  const handleNewInterviewDataChange = (e) => {
    let { name, value } = e.target;
    setFormError(null);
    // console.log(name, value);

    setNewInterviewFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name === 'numberOfQuestionSets') {
      setAdditionalSetsGenerated(parseInt(value) === 1);
    }
  };

  // For generating questions
  const generateQuestions = async (considerPrevAnswers = false) => {
    try {
      setLoading(true);
      setLoaderMessage('Generating Questions for Interview...');
      console.log("Current Question Set", currentQuestionSet);
      
      const res = await fetch(getApiUrl('/api/generate-interview-questions'), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userQuery: {
            job_title: jobData.job_title,
            job_description: considerPrevAnswers ? '' : jobData.job_description,
            interview_name: newInterviewFormData.interviewName,
            context_text: newInterviewFormData.contextVideoText,
            question_sets_range: `[${currentQuestionSet}-${currentQuestionSet}]`,
            number_of_sets: 1,
            number_of_questions: newInterviewFormData.questionsPerSet,
            skills: newInterviewFormData.skillsToEvaluate.map(
              (skill) => skill.value
            ),
            question_level: newInterviewFormData.questionDifficultyLevel,
            response_format:
              VIDEO_TEMPLATE_FORMAT[newInterviewFormData.videoTemplate],

            text: newInterviewFormData.questionSpecificationPrompt,
            previous_response:
              considerPrevAnswers && generatedQuestions
                ? Object.values(generatedQuestions)?.filter(
                    (question) => question.selected
                  )
                : '',
          },
        }),
      });

      if (res.ok) {
        let max_idx = 0;
        if (generatedQuestions)
          max_idx =
            Object.values(generatedQuestions).reduce(
              (acc, question) => Math.max(acc, question.id),
              0
            ) + 1;
        console.log(max_idx);
        const data = await res.json();
        console.log(JSON.parse(data.questions));
        const newQuestions = JSON.parse(data.questions).reduce(
          (acc, question, idx) => {
            acc[max_idx + idx] = {
              ...question,
              id: max_idx + idx,
              selected: false,
            };
            return acc;
          },
          {}
        );
        console.log("New Questions: ", newQuestions);
        let prevSelectedQuestions = {};
        if (generatedQuestions) {
          prevSelectedQuestions = Object.values(generatedQuestions).reduce(
            (acc, question) => {
              if (question.selected) acc[question.id] = question;
              return acc;
            },
            {}
          );
        }

        if (prevSelectedQuestions)
          setGeneratedQuestions({ ...prevSelectedQuestions, ...newQuestions });
        else setGeneratedQuestions({ ...newQuestions });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setLoaderMessage(null);
    }
  };

  const generateAdditionalSets = async () => {
    try {
      setLoading(true);
      setLoaderMessage('Generating Additional Question Sets...');

      const res = await fetch(getApiUrl('/api/generate-interview-questions'), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userQuery: {
            job_title: jobData.job_title,
            job_description: '',
            interview_name: newInterviewFormData.interviewName,
            text: `Generate ${
              newInterviewFormData.numberOfQuestionSets - 1
            } sets of questions (where the value of question_set ranges from 2 to ${
              newInterviewFormData.numberOfQuestionSets
            }) with ${
              newInterviewFormData.questionsPerSet
            } questions in each set corresponding to the previously generated answers. Take reference of the previously generated questions to handle difficulty level and the topics to cover.`,
            skills: newInterviewFormData.skillsToEvaluate.map(
              (skill) => skill.value
            ),
            response_format:
              VIDEO_TEMPLATE_FORMAT[newInterviewFormData.videoTemplate],
            previous_response: Object.values(generatedQuestions)
              ?.filter((question) => question.selected)
              .map((question) => question.question_script),
            context_text: newInterviewFormData.contextVideoText,
            number_of_questions: newInterviewFormData.questionsPerSet,
            question_level: newInterviewFormData.questionDifficultyLevel,
          },
        }),
      });

      if (res.ok) {
        const data = await res.json();
        const newQuestions = JSON.parse(data.questions);
        let max_idx = 0;
        if (generatedQuestions)
          max_idx =
            Object.values(generatedQuestions).reduce(
              (acc, question) => Math.max(acc, question.id),
              0
            ) + 1;
        const mappedNewQuestions = newQuestions.reduce((acc, question, idx) => {
          acc[max_idx + idx] = {
            ...question,
            id: max_idx + idx,
            selected: true,
          };
          return acc;
        }, {});
        console.log('New Generated Set Questions:', mappedNewQuestions);

        setGeneratedQuestions((prevQuestions) => ({
          ...prevQuestions,
          ...mappedNewQuestions,
        }));
        console.log('Generated Questions Till Now:', generatedQuestions);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setLoaderMessage(null);
    }
  };

  const createEndToEndInterview = async () => {
    const evaluationCriteria = [];
    newInterviewFormData.interviewEvaluationCriteria.forEach((criteria) => {
      evaluationCriteria.push(allEvaluationCategories[criteria.value.id]);
    });
    const questionLevelEvaluation =
      newInterviewFormData.interviewEvaluationCriteria.filter(
        (criteria) => criteria.value.evaluation_level === 'question'
      );
    console.log(questionLevelEvaluation);
    console.log(evaluationCriteria);
    setLoading(true);
    setLoaderMessage('Creating the Interview');

    let allQuestionsData = Object.values(generatedQuestions).filter(
      (question) => question.selected
    );
     // Group questions by set
     const questionsBySet = allQuestionsData.reduce((acc, question) => {
      if (!acc[question.question_set]) {
          acc[question.question_set] = [];
      }
      acc[question.question_set].push(question);
      return acc;
  }, {});


  // Re-index questions within each set
  for (const setNumber in questionsBySet) {
    if(setNumber !==1 ){
      questionsBySet[setNumber].forEach((question, index) => {
          question.index = index + 1;
      });
    }
  }


 // flatten the questionsBySet object to an array
  allQuestionsData = Object.values(questionsBySet).flat()


    // Generating more questions for different sets
    // if (newInterviewFormData.numberOfQuestionSets > 1 && !additionalSetsGenerated) {
    //   setLoaderMessage('Generating multiple sets');
    //   const questionSets = await fetch(
    //     getApiUrl('/api/generate-interview-questions'),
    //     {
    //       method: 'POST',
    //       headers: { 'Content-Type': 'application/json' },
    //       body: JSON.stringify({
    //         userQuery: {
    //           job_title: jobData.job_title,
    //           job_description: '',
    //           interview_name: newInterviewFormData.interviewName,
    //           text: `Generate ${
    //             newInterviewFormData.numberOfQuestionSets - 1
    //           } sets of questions (where the value of question_set ranges from 2 to ${
    //             newInterviewFormData.numberOfQuestionSets
    //           }) with ${
    //             newInterviewFormData.questionsPerSet
    //           } questions in each set corresponding to the previously generated answers. Take reference of the previously generated questions to handle difficulty level and the topics to cover.`,
    //           skills: newInterviewFormData.skillsToEvaluate.map(
    //             (skill) => skill.value
    //           ),
    //           response_format:
    //             VIDEO_TEMPLATE_FORMAT[newInterviewFormData.videoTemplate],
    //           previous_response: Object.values(generatedQuestions)
    //             ?.filter((question) => question.selected)
    //             .map((question) => question.question_script),
    //           context_text: newInterviewFormData.contextVideoText,
    //           number_of_questions: newInterviewFormData.questionsPerSet,
    //           question_level: newInterviewFormData.questionDifficultyLevel,
    //         },
    //       }),
    //     }
    //   );
    //   const questionSetsData = await questionSets.json();
    //   const newQuestions = JSON.parse(questionSetsData.questions);
    //   newQuestions.forEach((question) => {
    //     question.selected = true;
    //     allQuestionsData.push(question);
    //   });
    // }

    let jobScreeningRequirements = '';
    console.log('All Questions Data: ', allQuestionsData);
		const questionLevelEvalId = questionLevelEvaluation?.[0]?.value?.id ?? null;
		let scoringCriteria = '[1, 2, 3, 4, 5]';
		if(questionLevelEvalId) {
      
      if(allEvaluationCategories[questionLevelEvalId].name.toLowerCase().includes('screening')) {
        // If question level evaluation is present and evaluation name starts with Job Screening [exclusively for screening interviews]
        const screeningRequirementResponse = await fetch(getApiUrl(`/api/client-job-screening-requirements/job/${jobData.id}`));
        const screeningRequirementData = await screeningRequirementResponse.json();

        if(screeningRequirementData) {
          console.log('question level evaluation is present and evaluation name contains \'screening\'');
          const parsedData = JSON.parse(screeningRequirementData.screening_instructions);
          jobScreeningRequirements = Object.values(parsedData).reduce((acc, instr, idx) => `${acc}${idx + 1}. ${instr}\t`, '');
        }
      }
			scoringCriteria = allEvaluationCategories[questionLevelEvalId].scoring_criteria;
		}

		console.log(scoringCriteria);
    try {
      setLoaderMessage('Creating the Interview');
      const res = await fetch(getApiUrl('/api/interviews'), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          client_user_id: clientUser.id,
          interview_name: newInterviewFormData.interviewName,
          time_limit_per_answer: newInterviewFormData.timeLimitPerQuestion,
          interview_time_limit:
            newInterviewFormData.timeLimitPerQuestion *
            newInterviewFormData.questionsPerSet,
          status: 'Draft',
        }),
      });
      const data = await res.json(); // data.id -> interview id
      console.log('Interview Created Successfully');

      if (res.ok) {
        const link = await fetch(
          getApiUrl('/api/client-job-interviews/link/'),
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              job_id: parseInt(jobData.id),
              interview_id: data.id,
              interview_order: jobData.interviews.length + 1,
              question_set_selection_method:
                newInterviewFormData.setSelectionMethod,
              question_set_fixed: 1,
              number_of_question_sets: parseInt(
                newInterviewFormData.numberOfQuestionSets
              ),
            }),
          }
        );

        if (link.ok) {
          const linkData = await link.json(); // linkData.id -> client_job_interview_id
          setLoaderMessage('Adding evaluation criteria');

          // add each evaluation criteria to the interview
          const evaluationResponse = await fetch(getApiUrl('/api/interview-evaluation-criteria/create-with-prompts'), {
						method: 'POST',
						headers: { 'Content-Type' : 'application/json' },
						body: JSON.stringify({
								evaluation_criteria: evaluationCriteria,
								client_job_interview_id: linkData.id
						})
					});
					if(evaluationResponse.ok) console.log('Evaluation criteria and Prompts added to the job for processing');

          setLoaderMessage('Saving Maximum Score for Client Job Interview');
          await addMaxScore(newInterviewFormData,
            questionLevelEvaluation,
            questionLevelEvalId,
            allEvaluationCategories,
            evaluationCriteria,
            linkData,
            getApiUrl);
        }

        setLoaderMessage('Adding the Instructions');
        const instructionsResponse = await fetch(
          getApiUrl(`/api/interviews/${data.id}/instructions`),
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
							pre_interview_instructions:
							newInterviewFormData.preInterviewInstructions,
              welcome_message: newInterviewFormData.welcomeMessage,
              welcome_video_url: newInterviewFormData.welcomeVideoUrl,
              context_video_url: newInterviewFormData.contextVideoUrl,
              context_video_text: newInterviewFormData.contextVideoText,
              interview_response_type:
							newInterviewFormData.interviewResponseType,
							language: newInterviewFormData.interviewLanguage,
            }),
          }
        );
        if (instructionsResponse.ok) {
          console.log('Added instructions to the interview');
        }

        setLoaderMessage('Creating the Questions');
        const res = await fetch(
          getApiUrl('/api/interview-questions/create-interview-questions'),
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              client_user_id: clientUser.id,
              client_name: clientUser.client.name,
              job_name: jobData.job_title,
              job_screening_requirements: jobScreeningRequirements,
              interview_id: data.id,
              interview_name: newInterviewFormData.interviewName,
              context_text: newInterviewFormData.contextVideoText,
              questions_data: allQuestionsData,
              question_type: 'Video',
              template_name: newInterviewFormData.videoTemplate,
              evaluation_instructions: evaluationCriteria.map(
                (criteria) => criteria.description
              ),
              question_level_evaluation_id: questionLevelEvalId,
              scoring_criteria: scoringCriteria,
              media_orientation: newInterviewFormData.mediaOrientation,
            }),
          }
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      navigate(0);
    }
  };

  return (
    <div className={styles.createInterviewContainer}>
      <div className={styles.progressBars}>
        {Array.from({ length: MAX_PAGES }, (_, i) => i + 1).map((idx) => {
          return (
            <div
              key={idx}
              className={`progressBar ${
                currentFormPage >= idx ? styles.done : ''
              }`}
            ></div>
          );
        })}
      </div>

      <div className={styles.progressStatus}>
        {FORM_PAGE_NAMES.map((pageName, idx) => {
          if (idx < currentFormPage)
            return (
              <p
                key={idx}
                onClick={() => setCurrentFormPage(idx + 1)}
                className={idx + 1 === currentFormPage ? styles.done : ''}
              >
                {pageName} <IoChevronForward fontSize="0.8rem" />{' '}
              </p>
            );
        })}
      </div>

      {loading ? (
        <div className={styles.loaderContainer}>
          <div className="loader"></div>
          <div className="loader-message">{loaderMessage}...</div>
        </div>
      ) : (
        <div className={styles.createInterviewMain}>
          <form className={styles.createInterviewForm}>
            {currentFormPage === 1 ? (
              <div className={styles.formPage1}>
                <div className={styles.inputField}>
                  <input
                    placeholder="Interview Name"
                    name="interviewName"
                    value={newInterviewFormData.interviewName}
                    onChange={handleNewInterviewDataChange}
                  />
                  <label className={styles.fieldLabel}>Interview Name *</label>
                </div>
                <div className={styles.selectField}>
                  <select
                    name="interviewLanguage"
                    value={newInterviewFormData.interviewLanguage}
                    onChange={handleNewInterviewDataChange}
                  >
                    <option value="en-IN">English</option>
                    <option value="hi">Hindi (Hinglish)</option>
                  </select>
                  <label className={styles.fieldLabel}>
                    Interview Language
                  </label>
                </div>
                <div className={styles.selectField}>
                  <select
                    name="interviewResponseType"
                    value={newInterviewFormData.interviewResponseType}
                    onChange={handleNewInterviewDataChange}
                  >
                    <option value="audio">audio</option>
                    <option value="text">text</option>
                  </select>
                  <label className={styles.fieldLabel}>
                    Interview Response Type
                  </label>
                </div>
              </div>
            ) : currentFormPage === 2 ? (
              <div className={styles.formPage2}>
                <div className={styles.textareaField}>
                  <textarea
                    placeholder="Pre Interview Instructions"
                    name="preInterviewInstructions"
                    value={newInterviewFormData.preInterviewInstructions}
                    onChange={handleNewInterviewDataChange}
                  ></textarea>
                  <label className={styles.fieldLabel}>
                    Pre Interview Instructions *
                  </label>
                </div>
                <div className={styles.textareaField}>
                  <textarea
                    placeholder="Welcome Message"
                    name="welcomeMessage"
                    value={newInterviewFormData.welcomeMessage}
                    onChange={handleNewInterviewDataChange}
                  ></textarea>
                  <label className={styles.fieldLabel}>Welcome Message *</label>
                </div>
                <div className={styles.textareaField}>
                  <textarea
                    placeholder="Context Text"
                    name="contextVideoText"
                    value={newInterviewFormData.contextVideoText}
                    onChange={handleNewInterviewDataChange}
                  ></textarea>
                  <label className={styles.fieldLabel}>
                    Context Video Text
                  </label>
                </div>
                <div className={styles.inputField}>
                  <input
                    placeholder="Welcome Video Url"
                    name="welcomeVideoUrl"
                    value={newInterviewFormData.welcomeVideoUrl}
                    onChange={handleNewInterviewDataChange}
                  />
                  <label className={styles.fieldLabel}>Welcome Video Url</label>
                </div>
                <div className={styles.inputField}>
                  <input
                    placeholder="Context Video Url"
                    name="contextVideoUrl"
                    value={newInterviewFormData.contextVideoUrl}
                    onChange={handleNewInterviewDataChange}
                  />
                  <label className={styles.fieldLabel}>Context Video Url</label>
                </div>
              </div>
            ) : currentFormPage === 3 ? (
              <div className={styles.formPage3}>
                <div
                  className={`${styles.selectField} ${styles.multiSelectField}`}
                >
                  <CreatableSelect
                    styles={{
                      control: () => ({
                        display: 'flex',
                        background: 'transparent',
                        border: 'none',
                        fontFamily: 'Poppins',
                      }),
                      valueContainer: (baseStyles) => ({
                        ...baseStyles,
                        fontSize: '0.9rem',
                      }),
                      menu: (baseStyles) => ({
                        ...baseStyles,
                        backgroundColor: '#181818',
                        fontFamily: 'Poppins',
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isFocused
                          ? '#222'
                          : 'transparent',
                        fontSize: '0.8rem',
                      }),
                      multiValue: (baseStyles) => ({
                        ...baseStyles,
                        background: '#000',
                      }),
                      multiValueLabel: (baseStyles) => ({
                        ...baseStyles,
                        color: '#bbb',
                      }),
                      input: (baseStyles) => ({ ...baseStyles, color: '#ddd' }),
                    }}
                    placeholder="Start searching..."
                    value={newInterviewFormData.skillsToEvaluate}
                    onChange={(values) =>
                      setNewInterviewFormData({
                        ...newInterviewFormData,
                        skillsToEvaluate: values,
                      })
                    }
                    options={SKILLS_OPTIONS.skills}
                    isMulti={true}
                  />
                  <label className={styles.fieldLabel}>
                    Which skills do you want to evaluate? *
                  </label>
                </div>
                <div
                  className={`${styles.selectField} ${styles.multiSelectField}`}
                >
                  <Select
                    styles={{
                      control: () => ({
                        display: 'flex',
                        background: 'transparent',
                        border: 'none',
                        fontFamily: 'Poppins',
                      }),
                      valueContainer: (baseStyles) => ({
                        ...baseStyles,
                        fontSize: '0.9rem',
                      }),
                      menu: (baseStyles) => ({
                        ...baseStyles,
                        backgroundColor: '#181818',
                        fontFamily: 'Poppins',
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isFocused
                          ? '#222'
                          : 'transparent',
                        fontSize: '0.8rem',
                      }),
                      multiValue: (baseStyles) => ({
                        ...baseStyles,
                        background: '#000',
                      }),
                      multiValueLabel: (baseStyles) => ({
                        ...baseStyles,
                        color: '#bbb',
                      }),
                    }}
                    value={newInterviewFormData.interviewEvaluationCriteria}
                    onChange={(values) =>
                      setNewInterviewFormData({
                        ...newInterviewFormData,
                        interviewEvaluationCriteria: values,
                      })
                    }
                    options={CRITERIA_OPTIONS}
                    isMulti={true}
                  />
                  <label className={styles.fieldLabel}>
                    Evaluation Criteria *
                  </label>
                </div>
                <div className={styles.inputField}>
                  <input
                    name="numberOfQuestionSets"
                    type="number"
                    placeholder="1"
                    value={newInterviewFormData.numberOfQuestionSets}
                    onChange={handleNewInterviewDataChange}
                  />
                  <label className={styles.fieldLabel}>
                    Number of Questions Sets *
                  </label>
                </div>
                <div className={styles.inputField}>
                  <input
                    name="questionsPerSet"
                    type="number"
                    placeholder="4"
                    value={newInterviewFormData.questionsPerSet}
                    onChange={handleNewInterviewDataChange}
                  />
                  <label className={styles.fieldLabel}>
                    Questions per Set *
                  </label>
                </div>
                <div className={styles.inputField}>
                  <input
                    name="timeLimitPerQuestion"
                    type="number"
                    placeholder="30"
                    value={newInterviewFormData.timeLimitPerQuestion}
                    onChange={handleNewInterviewDataChange}
                  />
                  <label className={styles.fieldLabel}>
                    Time Limit per Question[s] *
                  </label>
                </div>
                <div className={styles.selectField}>
                  <select
                    name="questionDifficultyLevel"
                    value={newInterviewFormData.questionDifficultyLevel}
                    onChange={handleNewInterviewDataChange}
                  >
                    <option value="Entry Level">Entry Level</option>
                    <option value="Intermediate">Intermediate</option>
                    <option value="Advanced">Advanced</option>
                  </select>
                  <label className={styles.fieldLabel}>Question Level *</label>
                </div>
                <div className={styles.selectField}>
                  <select
                    name="setSelectionMethod"
                    value={newInterviewFormData.setSelectionMethod}
                    onChange={handleNewInterviewDataChange}
                  >
                    <option value="fixed">
                      Fixed (1st set will be shown for all attempts)
                    </option>
                    <option value="random">
                      Random (A random set will be shown for attempts)
                    </option>
                  </select>
                  <label className={styles.fieldLabel}>
                    Set Selection Method
                  </label>
                </div>
                <div className={styles.selectField}>
                  <select
                    name="mediaOrientation"
                    value={newInterviewFormData.mediaOrientation}
                    onChange={handleNewInterviewDataChange}
                  >
                    <option value="potrait">Potrait</option>
                    <option value="landscape">Landscape</option>
                  </select>
                  <label className={styles.fieldLabel}>
                    Media Orientation *
                  </label>
                </div>
                <div className={styles.imageSelectField}>
                  <div
                    className={
                      newInterviewFormData.videoTemplate === 'video_with_avatar'
                        ? styles.selected
                        : ''
                    }
                    onClick={() =>
                      setNewInterviewFormData((prevData) => ({
                        ...prevData,
                        videoTemplate: 'video_with_avatar',
                      }))
                    }
                  >
                    <img height="200px" src={templateWithAvatarImage} />
                  </div>
                  <div
                    className={
                      newInterviewFormData.videoTemplate ===
                      'video_with_ref_text'
                        ? styles.selected
                        : ''
                    }
                    onClick={() =>
                      setNewInterviewFormData((prevData) => ({
                        ...prevData,
                        videoTemplate: 'video_with_ref_text',
                      }))
                    }
                  >
                    <img height="200px" src={templateWithRefTextImage} />
                  </div>
                  <label className={styles.fieldLabel}>Video Template *</label>
                </div>
              </div>
            ) : (
              <div className={styles.formPage4}>
                <div className={styles.scrollableContainer}>
                  {currentFormPage === 4 &&
                    newInterviewFormData.numberOfQuestionSets > 1 &&
                    additionalSetsGenerated && (
                      <div className={styles.questionSetNavigation}>
                        {Array.from(
                          { length: newInterviewFormData.numberOfQuestionSets },
                          (_, i) => i + 1
                        ).map((setNumber) => (
                          <button
                            key={setNumber}
                            className={
                              currentQuestionSet === setNumber
                                ? `${styles.active} ${styles.secondaryBtn}`
                                : styles.secondaryBtn
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setCurrentQuestionSet(setNumber);
                            }}
                          >
                            Set {setNumber}
                          </button>
                        ))}
                      </div>
                    )}
                  <div className={styles.textareaField}>
                    <textarea
                      placeholder="Change something in the selected questions list..."
                      name="questionSpecificationPrompt"
                      value={newInterviewFormData.questionSpecificationPrompt}
                      onChange={handleNewInterviewDataChange}
                    ></textarea>
                    <label className={styles.fieldLabel}>
                      Question Specifications *
                    </label>
                    <button
                      className={`${styles.reiterateBtn} ${styles.secondaryBtn}`}
                      onClick={(e) => {
                        e.preventDefault();
                        console.log(
                          newInterviewFormData.questionSpecificationPrompt
                        );
                        if (
                          newInterviewFormData.questionSpecificationPrompt
                            .length === 0
                        ) {
                          setFormError(
                            'Question Specification Prompt cannot be empty.'
                          );
                          return false;
                        }
                        generateQuestions(true);
                      }}
                    >
                      Re iterate Questions [taking selected questions as
                      reference]
                    </button>
                  </div>

                  <div className={styles.generatedQuestions}>
                    <div className={styles.generatedQuestionsContainer}>
                      <h4>Available Questions</h4>
                      <div className={styles.generatedQuestionsList}>
                        {generatedQuestions &&
                          Object.values(generatedQuestions)
                            .filter(
                              (question) =>
                                !question.selected &&
                                question.question_set === currentQuestionSet
                            )
                            .map((question, idx) => {
                              return (
                                <AnswerInputField
                                  key={idx}
                                  id={question.id}
                                  type="unselected"
                                  question={question}
                                  setGeneratedQuestions={setGeneratedQuestions}
                                />
                              );
                            })}
                        {generatedQuestions &&
                          Object.values(generatedQuestions).filter(
                            (question) =>
                              !question.selected &&
                              question.question_set === currentQuestionSet
                          ).length === 0 && <p>No questions to show.</p>}
                      </div>
                    </div>
                    <div className={styles.generatedQuestionsContainer}>
                      <h4>Selected Questions</h4>
                      <div className={styles.generatedQuestionsList}>
                        {generatedQuestions &&
                          Object.values(generatedQuestions)
                            .filter(
                              (question) =>
                                question.selected &&
                                question.question_set === currentQuestionSet
                            )
                            .map((question, idx) => {
                              return (
                                <AnswerInputField
                                  key={idx}
                                  id={question.id}
                                  type="selected"
                                  question={question}
                                  setGeneratedQuestions={setGeneratedQuestions}
                                />
                              );
                            })}
                        {generatedQuestions &&
                          Object.values(generatedQuestions).filter(
                            (question) =>
                              question.selected &&
                              question.question_set === currentQuestionSet
                          ).length === 0 && <p>No questions to show.</p>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {formError && <p className={styles.formError}>{formError}</p>}

            <div className={styles.actions}>
              {newInterviewFormData.numberOfQuestionSets > 1 &&
                currentFormPage === 4 && (
                  <button
                    className={styles.secondaryBtn}
                    onClick={(e) => {
                      e.preventDefault();
                      generateAdditionalSets();
                      setAdditionalSetsGenerated(true);
                    }}
                    disabled={
                      additionalSetsGenerated ||
                      !generatedQuestions ||
                      Object.values(generatedQuestions).length === 0 ||
                      !Object.values(generatedQuestions).some(
                        (question) => question.selected
                      )
                    }
                  >
                    Generate Additional Sets
                  </button>
                )}
              <button className={styles.secondaryBtn} onClick={handleBack}>
                <p>Back</p>
              </button>
              <button
                className={styles.primaryBtn}
                onClick={handleNext}
                disabled={currentFormPage === 4 && !additionalSetsGenerated}
              >
                {currentFormPage < 4 ? <p>Next</p> : <p>Create Interview</p>}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

const AnswerInputField = ({ id, type, question, setGeneratedQuestions }) => {
  return (
    <div className={styles.textareaField}>
      {type === 'selected' ? (
        <button
          className={styles.inputActionButton}
          onClick={(e) => {
            e.preventDefault();
            setGeneratedQuestions((questions) => ({
              ...questions,
              [id]: { ...question, selected: false },
            }));
          }}
        >
          <FaMinus color="#eac100" />
        </button>
      ) : (
        <button
          className={styles.inputActionButton}
          onClick={(e) => {
            e.preventDefault();
            setGeneratedQuestions((questions) => ({
              ...questions,
              [id]: { ...question, selected: true },
            }));
          }}
        >
          <FaPlus color="#eac100" />
        </button>
      )}
			
			<textarea
				value={'Script: ' + question.question_script}
				onChange={(e) => {
					setGeneratedQuestions((questions) => ({
						...questions,
						[id]: { ...question, question_script: e.target.value.substring(8) },
					}));
				}}
			></textarea>
      {question.reference_text !== undefined && (
        <textarea
          value={'Reference: ' + question.reference_text}
          onChange={(e) => {
            setGeneratedQuestions((questions) => ({
              ...questions,
              [id]: { ...question, reference_text: e.target.value.substring(11) },
            }));
          }}
        ></textarea>
      )}
    </div>
  );
};

export default CreateInterviewForm;
        