import {
  createSystem,
  defaultConfig,
  defineRecipe,
  defineSlotRecipe,
} from "@chakra-ui/react";
import { defineTextStyles } from "@chakra-ui/react";

const textStyles = defineTextStyles({
  body: {
    description: "The body text style - used in paragraphs",
    value: {
      fontFamily: "Poppins",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24",
      letterSpacing: "0",
      textDecoration: "None",
      textTransform: "None",
    },
  },
});

const buttonRecipe = defineRecipe({
  base: {
    px: 3,
    py: 2,
    fontWeight: "semibold",
    fontFamily: `'Poppins', sans-serif`,
    borderRadius: "{radii.buttonRadius}",
  },
  variants: {
    visual: {
      primary: {
        color: "{colors.buttonColorPrimary}",
        bg: "{colors.buttonBackgroundPrimary}",
        _hover: {
          bg: "{colors.buttonColorPrimary}",
          color: "{colors.buttonBackgroundPrimary}",
        },
      },
      secondary: {
        color: "{colors.buttonColorSecondary}",
        bg: "{colors.buttonBackgroundSecondary}",
        boxShadow: "sm",
        _hover: {
          bg: "{colors.buttonColorSecondary}",
          color: "{colors.buttonBackgroundSecondary}",
        },
      },
      standard: {
        color: "{colors.buttonColorStandard}",
        bg: "{colors.buttonBackgroundStandard}",
        boxShadow: "sm",
        _hover: {
          color: "{colors.buttonColorSecondary}",
          bg: "{colors.buttonBackgroundStandard}",
        },
      },
    },
  },
  defaultVariants: { visual: "primary" },
});

const cardSlotRecipe = defineSlotRecipe({
  className: "chakra-card",
  slots: ["root", "header", "body", "footer", "title", "description"],
  base: {
    root: {
      px: "6",
      py: "4",
      fontFamily: `'Poppins', sans-serif`,
      boxShadow: "md",
      borderRadius: "{radii.cardRadius}",
    },
    title: {
      color: "{colors.highlight}",
    },
    description: {
      color: "{colors.text}",
    },
  },
  variants: {
    orient: {
      horizontal: {
        root: {
          backgroundColor: "{colors.backgroundPrimary}",
          borderWidth: "0px",
          borderColor: "{colors.backgroundPrimary}",
          flexDir: "row",
        },
      },
      vertical: {
        root: {
          backgroundColor: "{colors.backgroundPrimary}",
          borderWidth: "0px",
          borderColor: "{colors.backgroundPrimary}",
          flexDir: "column",
        },
      },
    },
  },
  defaultVariants: {
    orient: "horizontal",
  },
});

const containerRecipe = defineRecipe({
  base: {
    width: "100%",
    backgroundColor: "{colors.backgroundPrimary}",
    maxWidth: "100vw",
    padding: 4,
    display: "flex",
    flexDir: "column",
    borderRadius: "{radii.containerRadius}",
  },
});

const boxRecipe = defineRecipe({
  base: {
    width: "100%",
    backgroundColor: "{colors.backgroundPrimary}",
    padding: 4,
    display: "flex",
    flexDir: "column",
    borderRadius: "{radii.containerRadius}",
  },
});

const textRecipe = defineRecipe({
  base: {
    fontFamily: `'Poppins', sans-serif`,
    fontWeight: "normal",
    lineHeight: "2",
  },
  variants: {
    type: {
      normal: {
        color: "{colors.text}",
      },
      highlight: {
        color: "{colors.highlight}",
      },
    },
  }, // Add a comma here
  defaultVariants: { type: "normal" },
});

const headingRecipe = defineRecipe({
  base: {
    fontFamily: `'Poppins', sans-serif`,
    lineHeight: "4",
    fontWeight: "bold",
  },
  variants: {
    type: {
      normal: {
        color: "{colors.text}",
      },
      highlight: {
        color: "{colors.highlight}",
      },
    },
  },
  defaultVariants: { type: "normal" },
});

const separatorRecipe = defineRecipe({
  base: {
    colorPalette: "gray",
  },
});

export const system = createSystem(defaultConfig, {
  theme: {
    tokens: {
      fontWeights: {},
      fonts: {
        heading: { value: `'Poppins', sans-serif` },
        body: { value: `'Poppins', sans-serif` },
      },
      colors: {
        // Text Colors
        text: "#eee",
        highlight: "#eac100",

        // Background Colors
        backgroundPrimary: "#111",
        backgroundSecondary: "#282828",

        // Button Colors
        buttonColorPrimary: "#222",
        buttonBackgroundPrimary: "#eac500",
        buttonColorSecondary: "#eac500",
        buttonBackgroundSecondary: "#222",
        buttonColorStandard: "#eee",
        buttonBackgroundStandard: "#282828",
      },
      radii: {
        containerRadius: "10px",
        cardRadius: "8px",
        buttonRadius: "6px",
      },
    },
    textStyles: textStyles,
    recipes: {
      button: buttonRecipe,
      text: textRecipe,
      heading: headingRecipe,
      container: containerRecipe,
      separator: separatorRecipe,
      box: boxRecipe,
      heading: headingRecipe,
    },
    slotRecipes: {
      card: cardSlotRecipe,
    },
  },
});
