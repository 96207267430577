export const updateTemplate = ({
    html,
    brandingType,
    brandImage,
    brandText,
    brandColor,
    brandBgColor,
    brandLink,
    buttonColor,
    defaultHTML,
    buttonText,
    buttonLink,
    signature
}) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(defaultHTML, 'text/html');

    // Update branding section
    const headerTd = doc.querySelector('td.email-brand-tableData');
    const brandingLink = doc.querySelector('a.email-brand-link');
    if (headerTd) {
        headerTd.style.backgroundColor = brandBgColor;
        const brandingH1 = headerTd.querySelector('h1');

        if (brandingH1) {
            if (brandingType === 'image') {
                brandingH1.innerHTML = `<img src="${brandImage}" alt="Company Logo" style="max-height: 50px;" />`;
            } else {
                // update branding color : 
                brandingH1.style.color = brandColor;
                brandingH1.innerHTML = `${brandText}`;
            }
        }
    }

    if (brandingLink) {
        brandingLink.href = brandLink;
    }
    // Update button color
    const button = doc.querySelector('a.email-button-link');
    if (button) {
        button.href = buttonLink;
        button.innerHTML = buttonText;
        const style = button.getAttribute('style');
        const newStyle = style.replace(
            /background-color:[^;]+/,
            `background-color:${buttonColor}`
        );
        button.setAttribute('style', newStyle);
    }

    // Updating the email body content 
    const emailBody = doc.querySelector('div.email-body-all-para');
    // Remove any p tags with the class "email-para-content"
    const paragraphs = emailBody.querySelectorAll('p.email-para-content');
    paragraphs.forEach(p => p.remove());

    // Create a temporary container to parse the new HTML content
    // const tempContainer = document.createElement('div');
    emailBody.innerHTML = html;

    // Add the class and style to each p tag in the new HTML content
    const newParagraphs = emailBody.querySelectorAll('p');
    newParagraphs.forEach(p => {
        p.classList.add('email-para-content');
        p.style.fontSize = '16px';
        p.style.lineHeight = '26px';
        p.style.margin = '16px 0';
    });

    // Append the new HTML content to the email body
    // emailBody.appendChild(tempContainer);

    // Editing the signature 
    const signatureSpan = doc.querySelector('span.sender-signature');
    if (signatureSpan) {
        signatureSpan.innerHTML = signature;
    }

    return doc.documentElement.outerHTML;
};