import React, { useEffect, useState } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { getApiUrl } from "../utils/apiUtils";
import HistoricalScores from "./HistoricalScores";
import styles from "./CandidateCvView.module.css";
import {
  Text,
  List,
  Center,
  Heading,
  Separator,
  Button,
  Bleed,
  Box,
  Flex,
  Container,
} from "@chakra-ui/react";

export default function CandidateCvView() {
  // const styles = null;
  const { userId, jobId } = useParams();
  const [cvDetails, setCvDetails] = useState(null);
  const [cvExists, setCvExists] = useState(null);
  const [parsedCv, setParsedCv] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [jobName, setJobName] = useState("");
  const [userDetails, setUserDetails] = useState("");
  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');
  // const [email, setEmail] = useState('');
  // const [phone, setPhone] = useState('');
  const [activeTab, setActiveTab] = useState("cv");
  const location = useLocation();

  useEffect(() => {
    // Get the referrer (previous URL)
    const fromPath = location.state?.from;
    console.log("FROM PATH", fromPath);

    if (fromPath && fromPath.includes("applicant-reports")) {
      setActiveTab("scores");
    }
  }, [location]);

  useEffect(() => {
    const fetchJobName = async () => {
      try {
        const jobDetailsResponse = await fetch(
          getApiUrl(`/api/client-jobs/${jobId}`)
        );
        const jobDetailsData = await jobDetailsResponse.json();
        setJobName(jobDetailsData.job_title);
      } catch (error) {
        console.error("Error fetching job details:", error);
      }
    };

    fetchJobName();
  }, [jobId]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userDetailsResponse = await fetch(
          getApiUrl(`/api/users/${userId}`)
        );
        const userDetailsData = await userDetailsResponse.json();
        setUserDetails(userDetailsData);
        // setFirstName(userDetailsData.firstName);
        // setLastName(userDetailsData.lastName);
        // setEmail(userDetailsData.email);
        // setPhone(userDetailsData.phoneNumber);
      } catch (error) {
        console.error("Error fetching applicant details:", error);
      }
    };

    fetchUserDetails();
  }, [userId]);

  useEffect(() => {
    const checkCvExists = async () => {
      try {
        const response = await fetch(
          getApiUrl(`/api/check-cv/${userId}/${jobId}`)
        );
        const data = await response.json();
        setCvExists(data.uploaded);
        if (!data.uploaded) setLoading(false);
      } catch (error) {
        console.error("Error checking CV existence:", error);
        setError(error);
        setErrorMessage(
          "Failed to check CV existence. Please try again later."
        );
        setLoading(false);
      }
    };

    const fetchCvDetails = async () => {
      if (cvExists) {
        try {
          const response = await fetch(
            getApiUrl(`/api/cv-details/${userId}/${jobId}`)
          );
          const data = await response.json();
          console.log(JSON.parse(data.cv_assessment_system));
          setCvDetails(data);
          setParsedCv(JSON.parse(data.parsed_cv));
        } catch (error) {
          console.error("Error fetching CV details:", error);
          setError(error);
          setErrorMessage(
            "Failed to fetch CV details. Please try again later."
          );
        }
        setLoading(false);
      }
    };

    checkCvExists().then(() => {
      if (cvExists) {
        fetchCvDetails();
      }
    });
  }, [userId, jobId, cvExists]);

  const handlePreview = () => {
    if (cvDetails?.file_url) {
      window.open(cvDetails.file_url, "_blank");
    }
  };

  const handleDownload = () => {
    if (cvDetails?.file_url) {
      const link = document.createElement("a");
      link.href = cvDetails.file_url;
      link.download = `${cvDetails.user.firstName}_${cvDetails.user.lastName}_CV.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <div className={styles.loader}></div>
        <p>Loading Report...</p>
      </div>
    );
  }

  // const userName = firstName ?
  //     `${firstName} ${lastName}` :
  //     'Candidate';

  const evaluationDetails = cvDetails
    ? JSON.parse(cvDetails.cv_assessment_system)?.job_requirements_status || []
    : [];

  const renderTabContent = (
    activeTab,
    cvExists,
    cvDetails,
    evaluationDetails,
    handleDownload,
    parsedCv,
    jobId,
    userId,
    userDetails
  ) => {
    switch (activeTab) {
      case "cv":
        return cvExists && cvDetails ? (
          <>
            <Container mb="2" justifyContent="center" alignItems="center">
              <Heading my="5" color="#EDC500">
                Evaluation (
                {evaluationDetails.filter((req) => req.satisfied).length}/
                {evaluationDetails.length} criteria satisfied)
              </Heading>
              <Container
                display="flex"
                flexDirection="row"
                alignItems="space-between"
                gap="6"
              >
                {evaluationDetails.map((requirement) => (
                  <Box
                    key={requirement.requirement}
                    className={`${styles.cvCard} ${
                      requirement.satisfied === true
                        ? styles.cvSuccess
                        : styles.cvFail
                    }`}
                  >
                    <Heading as="h4">{requirement.requirement}</Heading>
                    <Heading
                      my="5"
                      style={{ color: requirement.satisfied ? "green" : "red" }}
                    >
                      <strong>
                        {requirement.satisfied
                          ? "Criteria Satisfied"
                          : "Criteria Not Satisfied"}
                      </strong>
                    </Heading>
                    <Text lineHeight="short">
                      <strong>Reason - </strong>
                      {requirement.reasoning}
                    </Text>
                  </Box>
                ))}
              </Container>
            </Container>

            <section className={styles.section}>
              <div className={styles.actions}>
                <Heading as="h2" my="5" color="#EDC500">
                  CV Details
                </Heading>
                <Button onClick={handleDownload}>Download CV</Button>
              </div>
              <div className={styles.cvContent}>
                {parsedCv &&
                  Object.keys(parsedCv).map(
                    (field, idx) =>
                      parsedCv[field].length !== 0 && (
                        <CVCard key={idx} name={field} data={parsedCv[field]} />
                      )
                  )}
              </div>
            </section>
          </>
        ) : (
          <div>
            <p>No CV Details Found.</p>
          </div>
        );

      case "scores":
        return (
          <HistoricalScores
            clientJobId={jobId}
            userId={userId}
            candidateName={`${userDetails.firstName} ${userDetails.lastName}`}
            cvEvaluationDetails={evaluationDetails}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Link to="/client-dashboard" className="job-listings-nav">
          Job Listings
        </Link>
        &gt;{" "}
        <Link
          to={`/client-jobs/${jobId}/candidates-list`}
          className="job-listings-nav"
        >
          {jobName} [All Candidates]
        </Link>
        &gt;{" "}
        <Link
          to={`/client-jobs/${jobId}/applicant-reports`}
          className="job-listings-nav"
        >
          Scores
        </Link>
        &gt; Report
        <div className={styles.header}>
          <Heading
            as="h1"
            color="#EDC500"
            // className={styles.name}
          >{`${userDetails.firstName} ${userDetails.lastName}`}</Heading>
          {/* {cvExists && cvDetails && ( */}
          <div className={styles.contactInfo}>
            <div className={styles.contactItem}>{userDetails.email}</div>
            <div className={styles.contactItem}>{userDetails.phoneNumber}</div>
            <div
              className={styles.contactItem}
            >{`${userDetails.city}, ${userDetails.state}`}</div>
            <div className={styles.contactItem}>
              {userDetails.experienceLevel}
            </div>
            <div className={styles.contactItem}>{jobName}</div>
          </div>
        </div>
        <div className={styles.cvTabs}>
          {cvExists && (
            <button
              className={`${styles.cvTabButton} ${
                activeTab === "cv" ? styles.active : ""
              }`}
              onClick={() => setActiveTab("cv")}
            >
              CV Details
            </button>
          )}
          <button
            className={`${styles.cvTabButton} ${
              activeTab === "scores" ? styles.active : ""
            }`}
            onClick={() => setActiveTab("scores")}
          >
            Interview Scores
          </button>
        </div>
        <div className={styles.cvTabContent}>
          {renderTabContent(
            activeTab,
            cvExists,
            cvDetails,
            evaluationDetails,
            handleDownload,
            parsedCv,
            jobId,
            userId,
            userDetails
          )}
        </div>
      </div>
    </div>
  );
}

function CVCard({ name, data }) {
  const snakeCaseToCapitalize = (string) => {
    const words = string.split("_");
    let result = words.reduce((acc, word) => {
      return acc + word[0].toUpperCase() + word.substr(1) + " ";
    }, "");
    return result;
  };

  return (
    <div className={styles.cvCard}>
      <Heading as="h3">{snakeCaseToCapitalize(name)}</Heading>
      {/* <ol type='1'> */}
      {data.map((val, i) => (
        <p key={i}>+ {val}</p>
      ))}
      {/* <ol type='1'> */}
    </div>
  );
}
