import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './ClientDashboard.css';
import { getApiUrl } from '../utils/apiUtils';
import { ClientUserContext } from '../context/ClientUserContext'; // Import ClientUserContext
import { PiReadCvLogoLight } from "react-icons/pi";
import { FaUsers, FaUserPlus } from "react-icons/fa";
import { Box, Button, Heading, Text } from '@chakra-ui/react';


const ClientDashboard = () => {
    const [jobs, setJobs] = useState([]);
    const [clientName, setClientName] = useState('Client');
    const [loading, setLoading] = useState(true); // New state for loading
    const [error, setError] = useState(null); // New state for error handling
    const { clientUser } = useContext(ClientUserContext); // Access client user details from context
    const navigate = useNavigate();

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await fetch(getApiUrl(`/api/client-jobs/client/${clientUser.client_id}`));
                if (!response.ok) {
                    throw new Error('Failed to fetch jobs');
                }
                const data = await response.json();
                setJobs(data.filter(job => job.status === 'Open'));
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false); // Set loading to false once data is fetched
            }
        };

        const getClientName = async () => {
            try {
                const response = await fetch(getApiUrl(`/api/clients/${clientUser.client_id}`));
                if (!response.ok) {
                    throw new Error('Failed to fetch client details');
                }
                const data = await response.json();
                console.log(data);
                setClientName(data.name);
            } catch (error) {
                setError(error.message);
            }
        }

        fetchJobs();
        getClientName();
    }, [clientUser.client_id]);

    if (loading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
                <p className="loader-text">Loading jobs, please wait...</p>
            </div>
        );
    }

    if (error) {
        console.log("Error: ", error);
    }

    return (
        <div className="dashboard-container">
            <header className="dashboard-navbar popins-regular">
                <Link to="/" className="logo">
                    <span className="logo-peh">Peh</span><span className="logo-chaan">chaan</span>
                </Link>
                <div>
                    <Heading as='h3'>Welcome, </Heading>
                    <Heading as='h3'>{clientUser.user_name}</Heading> {/* Display user name from context */}
                </div>
            </header>
            <main className="dashboard-main">
                <div className="job-list poppins-regular">
                    <Heading as='h2'>{clientName}'s Job Listings</Heading> {/* Replace "Your Job Listings" with client's name */}
                    {jobs.length > 0 ? (
                        <ul>
                            {jobs.map((job) => (
                                <li key={job.id} className='job-item'>
                                    <Link to={`/client-jobs/${job.id}`}>
                                        <div className="job-details">
                                            <span className='title poppins-medium'>{job.job_title}</span>{/* Display only job title */}
                                            <span className='tag poppins-regular'>Created on: {job.createdAt.split('T')[0]}</span> {/* Display created date */}
                                        </div>
                                    </Link>
                                        <Box display='flex' gap='2'>
                                            <Button visual='secondary' onClick={() => navigate(`/client-jobs/${job.id}/candidates-list`)}>
                                                View Applicants
                                                <FaUsers />
                                            </Button>
                                            <Button visual='secondary' onClick={() => navigate(`/invite-user/job/${job.id}`)}>
                                                Invite Applicant
                                                <FaUserPlus />
                                            </Button>
                                        </Box>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className='poppins-regular'>No jobs available. Start by creating a new job listing.</p>
                    )}
                </div>
            </main>
        </div>
    );
};

export default ClientDashboard;