import React, { useState } from 'react';
import styles from './CreateUseCaseModal.module.css';
import { Box, Button, Heading, Text } from '@chakra-ui/react';

const CreateUseCaseModal = ({ onClose, onSave }) => {
    const [useCase, setUseCase] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = () => {
        if (!useCase.trim() || !displayName.trim()) {
            setError('Both fields are required');
            return;
        }
        onSave({ useCase, displayName });
    };

    return (
        <div className={styles['modal-overlay']}>
            <div className={styles['modal-content']}>
                <Heading size='xl'>Create New Use Case</Heading>
                <div className={styles['modal-form']}>
                    <div className={styles['form-group']}>
                        <label>Use Case (will be converted to snake_case)</label>
                        <input
                            type="text"
                            value={useCase}
                            onChange={(e) => setUseCase(e.target.value)}
                            placeholder="Enter use case"
                        />
                    </div>
                    <div className={styles['form-group']}>
                        <label>Display Name</label>
                        <input
                            type="text"
                            value={displayName}
                            onChange={(e) => setDisplayName(e.target.value)}
                            placeholder="Enter display name"
                        />
                    </div>
                    {error && <div className={styles['error-message']}>{error}</div>}
                    <div className={styles['modal-actions']}>
                        <Button visual='standard' onClick={onClose}>
                            Cancel
                        </Button>
                        <Button visual='primary' onClick={handleSubmit}>
                            Create
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateUseCaseModal;