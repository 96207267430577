import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./JobListingPage.css";
import { UserContext } from "../context/UserContext";
import { getApiUrl } from "../utils/apiUtils";
import { useLogout } from "../utils/logout";
import Modal from "./Modal"; // Modal component for Contact Us
import { FaUserCircle } from "react-icons/fa";
import { Container, Heading, Button, Card, Box, Text } from "@chakra-ui/react";

const JobListingPage = () => {
  const { user } = useContext(UserContext);
  const [openJobs, setOpenJobs] = useState([]);
  const [eligibleJobsList, setEligibleJobsList] = useState([]);
  const [appliedJobsList, setAppliedJobsList] = useState([]);
  const [jobsWithVerdict, setJobsWithVerdict] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const navigate = useNavigate();
  const logout = useLogout();
  const [activeTab, setActiveTab] = useState("applied");

  const fetchJobs = async () => {
    setLoading(true);
    try {
      const [
        jobResponse,
        screeningResponse,
        candidateCvResponse,
        userJobResponse,
      ] = await Promise.all([
        fetch(getApiUrl("/api/client-jobs")),
        fetch(getApiUrl("/api/client-job-screening-requirements")),
        fetch(getApiUrl(`/api/check-cv/${user.id}`)),
        fetch(getApiUrl(`/api/user-jobs/${user.id}`)),
      ]);

      if (
        !jobResponse.ok ||
        !screeningResponse.ok ||
        !candidateCvResponse.ok ||
        !userJobResponse.ok
      ) {
        throw new Error("One or more API calls failed");
      }

      const [jobs, screeningRequirementsData, candidateCvData, userJobData] =
        await Promise.all([
          jobResponse.json(),
          screeningResponse.json(),
          candidateCvResponse.json(),
          userJobResponse.json(),
        ]);

      // Ensure that the response data is an array
      const screeningRequirementsArray = Array.isArray(
        screeningRequirementsData
      )
        ? screeningRequirementsData
        : [];
      const candidateCvDetailsArray =
        candidateCvData && Array.isArray(candidateCvData.details)
          ? candidateCvData.details
          : [];
      const userJobsArray = Array.isArray(userJobData) ? userJobData : [];
      const filteredJobs = Array.isArray(jobs)
        ? jobs.filter((job) => job.status === "Open")
        : [];

      // Create a map for faster lookup of screening requirements by job_id
      const screeningRequirementsMap = screeningRequirementsArray.reduce(
        (map, requirement) => {
          map[requirement.job_id] = requirement;
          return map;
        },
        {}
      );

      // Create a map for faster lookup of candidate CV details by job_id
      const candidateCvMap = candidateCvDetailsArray.reduce((map, cvDetail) => {
        map[cvDetail.job_id] = cvDetail;
        return map;
      }, {});

      // Assign screening requirements and CV upload status to jobs using the maps
      const jobsWithDetails = filteredJobs.map((job) => {
        const screeningRequirement = screeningRequirementsMap[job.id];
        const candidateCvDetail = candidateCvMap[job.id];
        return {
          ...job,
          cvUploadRequired: screeningRequirement?.cvUploadRequired || false,
          cvScreeningInstructions:
            screeningRequirement?.cvScreeningInstructions || "",
          cvUploaded: !!candidateCvDetail,
          cvFileUrl: candidateCvDetail?.file_url || "",
        };
      });
      console.log("jobsWithDetails:", jobsWithDetails);
      setOpenJobs(jobsWithDetails);

      // Map user jobs for faster lookups
      const eligibleJobs = userJobsArray.reduce((acc, userJob) => {
        if (userJob.status === "Eligible") acc[userJob.job_id] = userJob;
        return acc;
      }, {});

      const appliedJobs = userJobsArray.reduce((acc, userJob) => {
        if (
          userJob.status === "Applied" ||
          userJob.status === "CV Matched" ||
          userJob.status === "CV Partially Matched" ||
          userJob.status === "CV Not Matched"
        )
          acc[userJob.job_id] = userJob;
        return acc;
      }, {});

      // Create a map for faster lookup of verdicts by job_id
      const jobsWithVerdictMap = userJobsArray.reduce((map, userJob) => {
        if (userJob.status === "Accepted" || userJob.status === "Rejected")
          map[userJob.job_id] = userJob;
        return map;
      }, {});

      // Create eligible and applied jobs lists
      const eligibleJobsWithId = jobsWithDetails
        .filter((job) => eligibleJobs[job.id])
        .map((job) => ({
          ...job,
          userJobId: eligibleJobs[job.id].id,
        }));
      setEligibleJobsList(eligibleJobsWithId);
      console.log("eligibleJobs:", eligibleJobsWithId);

      const appliedJobsWithId = jobsWithDetails
        .filter((job) => appliedJobs[job.id])
        .map((job) => ({
          ...job,
          userJobId: appliedJobs[job.id].id,
        }));
      setAppliedJobsList(appliedJobsWithId);
      console.log("appliedJobs:", appliedJobsWithId);

      const jobsWithVerdictWithId = jobsWithDetails
        .filter((job) => jobsWithVerdictMap[job.id])
        .map((job) => ({
          ...job,
          userJobStatus: jobsWithVerdictMap[job.id].status,
          userJobId: jobsWithVerdictMap[job.id].id,
        }));
      setJobsWithVerdict(jobsWithVerdictWithId);
      console.log("jobsWithVerdict:", jobsWithVerdictWithId);

      if (appliedJobsWithId.length > 0) {
        setActiveTab("applied");
      } else if (eligibleJobsWithId.length > 0) {
        setActiveTab("eligible");
      } else {
        setActiveTab("past");
      }
    } catch (error) {
      console.error("Error fetching job data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else {
      fetchJobs();
    }
  }, [user]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };

    return date.toLocaleDateString("en-GB", options);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "applied":
        return appliedJobsList.length > 0 ? (
          <Container width="95%" bg="{colors.backgroundSecondary}">
            {appliedJobsList.map((job) => (
              <Card.Root key={job.id} width="100%" alignItems="center" mb="4">
                <Text> {job.job_title}</Text>
                <div className="poppins-regular tag-container">
                  <div className="tag poppins-light">
                    Applied on: {formatDate(job.updatedAt)}
                  </div>
                </div>
                <Button
                  visual="secondary"
                  ml="auto"
                  onClick={() => {
                    if (
                      !job.cvUploadRequired ||
                      (job.cvUploadRequired && job.cvUploaded)
                    ) {
                      navigate(`/interviews/${job.id}`);
                    } else
                      navigate("/cv-upload", {
                        state: {
                          jobId: job.id,
                          userJobId: job.userJobId,
                          isApplied: true,
                        },
                      });
                  }}
                >
                  Go to Interviews
                </Button>
              </Card.Root>
            ))}
          </Container>
        ) : (
          <div className="no-jobs-message poppins-regular centered">
            <p>No applied jobs found.</p>
          </div>
        );

      case "available":
        return eligibleJobsList.length > 0 ? (
          <Container width="95%" bg="{colors.backgroundSecondary}">
            {eligibleJobsList.map((job) => (
              <Card.Root
                key={job.id}
                width="100%"
                alignItems="center"
                justifyContent="space-between"
                mb="4"
              >
                {/* <div className='job-item-container'> */}
                {/* <div className='job-title-container'> */}
                <Text>{job.job_title}</Text>
                {/* </div> */}
                {/* </div> */}
                {/* <div className='button-container'> */}
                <Button
                  visual="secondary"
                  onClick={() =>
                    navigate(`/job-details/${job.id}`, {
                      state: { userJobId: job.userJobId },
                    })
                  }
                >
                  View Job Details
                </Button>
                {/* </div> */}
              </Card.Root>
            ))}
          </Container>
        ) : (
          <div className="no-jobs-message poppins-regular centered">
            <Text>No available jobs found.</Text>
          </div>
        );

      case "past":
        return jobsWithVerdict.length > 0 ? (
          <Container width="95%" bg="{colors.backgroundSecondary}">
            {jobsWithVerdict.map((job) => (
              <Card.Root
                key={job.id}
                width="100%"
                alignItems="center"
                justifyContent="space-between"
                mb="4"
              >
                {/* <div className='job-item-container'> */}
                {/* <div className='job-title-container'> */}
                <Text>{job.job_title}</Text>
                {/* </div> */}
                {/* </div> */}
                {/* <div > */}
                <Heading
                  as="h4"
                  fontSize='md'
                  ml="auto"
                  color={
                    job.userJobStatus === "Accepted" ? "green.500" : "red.500"
                  }
                >
                  {job.userJobStatus === "Accepted"
                    ? "Shortlisted"
                    : "Rejected"}
                </Heading>
                {/* </div> */}
              </Card.Root>
            ))}
          </Container>
        ) : (
          <div className="no-jobs-message poppins-regular centered">
            <Text>No past jobs found.</Text>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="job-listing-page">
      <header className="signup-navbar">
        <div className="left">
          <Box>
                    <Link to="/" className="logo poppins-regular">
                      <span className="logo-peh">Peh</span>
                      <span className="logo-chaan">chaan</span>
                    </Link>
                  </Box>
          <div className="credentials">
            <div className="mobile-user-info">
              <FaUserCircle className="user-icon" onClick={toggleUserMenu} />
              {isUserMenuOpen && (
                <div className="interviewhr-dropdown-menu">
                  <div className="poppins-regular">
                    {user.firstName} {user.lastName}
                  </div>
                  <Button visual="secondary" onClick={logout}>
                    Logout
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
          <p className="loader-text">Loading jobs, please wait...</p>
        </div>
      ) : (
        <>
          {/* <Container m='10'> */}
          <Container
            className="tabs"
            marginInline="auto"
            width="95%"
            display="flex"
            flexDir="row"
          >
            <Button
              className={`tab-button ${
                activeTab === "applied" ? "active" : ""
              }`}
              onClick={() => setActiveTab("applied")}
            >
              Applied Jobs
            </Button>
            <Button
              className={`tab-button ${
                activeTab === "available" ? "active" : ""
              }`}
              onClick={() => setActiveTab("available")}
            >
              Available Jobs
            </Button>
            <Button
              className={`tab-button ${activeTab === "past" ? "active" : ""}`}
              onClick={() => setActiveTab("past")}
            >
              Past Jobs
            </Button>
          </Container>
          {/* <Container width="95%" ml='32px' bg='{colors.backgroundSecondary}'> */}
          {renderTabContent()}
          {/* </Container> */}
          {/* </Container> */}

          {appliedJobsList.length === 0 &&
            eligibleJobsList.length === 0 &&
            jobsWithVerdict.length === 0 &&
            !loading && (
              <div className="no-jobs-message poppins-semibold centered">
                <p>
                  You have not been invited to any jobs. Please contact us for
                  any queries.
                </p>
                <Button onClick={toggleModal}>Contact Us</Button>
              </div>
            )}

          {modalOpen && (
            <Modal
              toggleModal={toggleModal}
              headerText="Contact Us"
              bodyText="Please reach out to us at: kunal@pehchaan.me"
              buttonText="Close"
            />
          )}
        </>
      )}
    </div>
  );
};

export default JobListingPage;
