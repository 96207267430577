import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getApiUrl } from '../utils/apiUtils';
import './JobApplicantReports.css';
import { ClientUserContext } from '../context/ClientUserContext';
import { AgGridReact } from 'ag-grid-react';
import { AgChartsReact } from 'ag-charts-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { PiFileXls } from 'react-icons/pi';
import { PiFileCsv } from 'react-icons/pi';
import { PiFilePdfLight } from 'react-icons/pi';
import { PiExport } from 'react-icons/pi';
//import { calculatePercentile } from '../utils/mathUtils';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { FaRegFilePdf } from 'react-icons/fa6';
import { BsArrowRepeat, BsFileEarmarkExcel } from 'react-icons/bs';
import { BsFiletypeCsv } from 'react-icons/bs';
import { BsFiletypeXls } from 'react-icons/bs';
import Modal from './Modal';
import { Box, Button, Flex, Text } from '@chakra-ui/react';

const JobApplicantReports = () => {
  const { clientJobId } = useParams();
  const { clientUser } = useContext(ClientUserContext);
  const [applicantReports, setApplicantReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [job, setJob] = useState(); // Store job name
  const [jobId, setJobId] = useState(); // Store job id
  const [jobName, setJobName] = useState('');
  const [cvRequirements, setCvRequirements] = useState(null);
  const [evaluationCriteriaMap, setEvaluationCriteriaMap] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [weightages, setWeightages] = useState({});
  const [showWeightageTable, setShowWeightageTable] = useState(false);
  const [tempWeightages, setTempWeightages] = useState({ ...weightages });
  const [refreshing, setRefreshing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [allCvData, setAllCvData] = useState(null);
  const [cutoffScore, setCutoffScore] = useState(0); // State for cutoff score
  // const[gridReady, setGridReady] = useState(false);
  // const[savedGridState, setSavedGridState] = useState(null);
  const [modalText, setModalText] = useState({
    headerText: '',
    bodyText: '',
    buttonText: '',
  });
  const [screeningInstructionsCount, setScreeningInstructionsCount] =
    useState(0);

  function toggleModal() {
    setShowModal(!showModal);
  }

  const gridRef = useRef(null); // Reference for accessing the grid API

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    };

    return date.toLocaleDateString('en-GB', options);
  };

  useEffect(() => {
    const fetchScreeningInstructions = async () => {
      try {
        const res = await fetch(
          getApiUrl(`/api/client-job-screening-requirements/job/${clientJobId}`)
        );
        const screeningData = await res.json();
        if (screeningData.screening_instructions) {
          const parsedData = JSON.parse(screeningData.screening_instructions);
          console.log(
            'Count of Screening Instructions: ',
            Object.keys(parsedData).length
          );
          setScreeningInstructionsCount(Object.keys(parsedData).length);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const fetchApplicantReports = async () => {
      try {
        const jobDetailsResponse = await fetch(
          getApiUrl(`/api/client-jobs/${clientJobId}`)
        );
        const jobDetailsData = await jobDetailsResponse.json();
        setJobName(jobDetailsData.job_title);
        setJobId(jobDetailsData.id);
        setJob(jobDetailsData);
        // console.log("JOB:", jobDetailsData);

        const cvRequirementsResponse = await fetch(
          getApiUrl(
            `/api/client-job-screening-requirements/job/${jobDetailsData.id}`
          )
        );
        const cvRequirementsData = await cvRequirementsResponse.json();
        setCvRequirements(cvRequirementsData);
        console.log('CV REQUIREMENTS DATA', cvRequirementsData);

        const response = await fetch(
          getApiUrl(`/api/scores/client-jobs/${clientJobId}/candidate-scores`)
        );
        const data = await response.json();
        console.log('data:', data); // Debug log

        if (!data || !data.report || data.report.length === 0) {
          setApplicantReports([]);
          setLoading(false);
          return;
        }
        // data.report also has the latest attempt details , we need to sort candidates based on the latest attempt, latest attempt can be null as well
        // so we need to handle that as well
        data.report.sort((a, b) => {
          if (a.latest_attempt && b.latest_attempt) {
            return new Date(a.latest_attempt) > new Date(b.latest_attempt)
              ? -1
              : new Date(a.latest_attempt) < new Date(b.latest_attempt)
              ? 1
              : 0;
          } else if (a.latest_attempt) {
            return -1;
          } else if (b.latest_attempt) {
            return 1;
          }
          return 0;
        });
        // console.log('sorted data:', sortedReport); // Debug log
        setApplicantReports(data.report);

        // Create a map of evaluation criteria for each interview
        const criteriaMap = {};
        data.report.forEach((applicant) => {
          Object.keys(applicant.interviews).forEach((interviewName) => {
            if (!criteriaMap[interviewName]) {
              criteriaMap[interviewName] = new Set();
            }
            const interviewScores = applicant.interviews[interviewName];
            Object.keys(interviewScores).forEach((criteria) => {
              criteriaMap[interviewName].add(criteria);
            });
          });
        });

        const finalCriteriaMap = {};
        Object.keys(criteriaMap).forEach((interview) => {
          finalCriteriaMap[interview] = Array.from(criteriaMap[interview]);
        });
        setEvaluationCriteriaMap(finalCriteriaMap);

        // Load weightages from localStorage if available, otherwise initialize with default values
        const savedWeightages =
          JSON.parse(localStorage.getItem(`weightages_${clientJobId}`)) || {};
        const initialWeightages = {};
        Object.keys(finalCriteriaMap).forEach((interviewName) => {
          initialWeightages[interviewName] = {};
          finalCriteriaMap[interviewName].forEach((criteria) => {
            initialWeightages[interviewName][criteria] =
              savedWeightages[interviewName]?.[criteria] ?? 1;
          });
        });
        setWeightages(initialWeightages);
        setTempWeightages(initialWeightages);
      } catch (error) {
        console.error('Error fetching applicant reports:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchScreeningInstructions();
    fetchApplicantReports();
  }, [clientJobId]);

  const refreshApplicantReports = async () => {
    setRefreshing(true);
    try {
      const response = await fetch(
        getApiUrl(`/api/scores/client-jobs/${clientJobId}/candidate-scores`)
      );
      const data = await response.json();
      data.report.sort((a, b) => {
        if (a.latest_attempt && b.latest_attempt) {
          return new Date(a.latest_attempt) > new Date(b.latest_attempt)
            ? -1
            : new Date(a.latest_attempt) < new Date(b.latest_attempt)
            ? 1
            : 0;
        } else if (a.latest_attempt) {
          return -1;
        } else if (b.latest_attempt) {
          return 1;
        }
        return 0;
      });
      if (!data || !data.report || data.report.length === 0) {
        setApplicantReports([]);
        return;
      }
      setApplicantReports(data.report);
    } catch (error) {
      console.error('Error refreshing applicant reports:', error);
    } finally {
      setRefreshing(false);
    }
  };

  const handleSendShortlistEmail = async () => {
    if (gridRef.current && gridRef.current.api) {
      const selectedNodes = gridRef.current.api.getSelectedNodes();
      if (selectedNodes.length === 0) {
        setModalText({
          headerText: 'Error',
          bodyText: 'Please select at least one candidate to shortlist',
          buttonText: 'Okay',
        });
        setShowModal(true);
        return;
      }
      const selectedData = selectedNodes.map((node) => node.data);
      console.log('Candidates Batched for Short Listing:', selectedData);

      try {
        const response = await fetch(
          getApiUrl('/api/user-jobs/sendEmail/shortlist'),
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              job_id: clientJobId,
              shortlists: selectedData,
            }),
          }
        );
        const data = await response.json();
        if (response.ok) {
          // Refresh the grid
          refreshApplicantReports();
          // alert('Candidates Shortlisted Successfully');
          setModalText({
            headerText: 'Shortlisting Successful',
            bodyText: 'The email has been sent to the selected candidates.',
            buttonText: 'Okay',
          });
          setShowModal(true);
        } else {
          // alert('Error Shortlisting Candidates: ' + data.message);
          setModalText({
            headerText: 'Error Shortlisting Candidates',
            bodyText: 'Error Shortlisting Candidates: ' + data.message,
            buttonText: 'Okay',
          });
          setShowModal(true);
        }
      } catch (error) {
        console.error('Error during shortlist request:', error);
        // alert('Error Shortlisting Candidates: ' + error.message);
        setModalText({
          headerText: 'Error Sending Email',
          bodyText: 'Error Shortlisting Candidates: ' + error.message,
          buttonText: 'Okay',
        });
        setShowModal(true);
      }
    } else {
      console.warn('Grid API is not ready yet.');
    }
  };

  const handleSendRejectionEmail = async () => {
    if (gridRef.current && gridRef.current.api) {
      const selectedNodes = gridRef.current.api.getSelectedNodes();
      if (selectedNodes.length === 0) {
        // alert('Please select at least one candidate to reject');
        setModalText({
          headerText: 'Error',
          bodyText: 'Please select at least one candidate to reject',
          buttonText: 'Okay',
        });
        setShowModal(true);
        return;
      }
      const selectedData = selectedNodes.map((node) => node.data);
      console.log('Candidates Batched for Rejection:', selectedData);

      try {
        const response = await fetch(
          getApiUrl('/api/user-jobs/sendEmail/reject'),
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              job_id: clientJobId,
              rejects: selectedData,
            }),
          }
        );
        const data = await response.json();
        if (response.ok) {
          // Refresh the grid
          refreshApplicantReports();
          // alert('Candidates Rejected Successfully');
          setModalText({
            headerText: 'Rejection Successful',
            bodyText: 'The email has been sent to the rejected candidates.',
            buttonText: 'Okay',
          });
          setShowModal(true);
        } else {
          // alert('Error Rejecting Candidates: ' + error.message);
          setModalText({
            headerText: 'Error Rejecting Candidates',
            bodyText: 'Error Rejecting Candidates: ',
            buttonText: 'Okay',
          });
          setShowModal(true);
        }
      } catch (error) {
        console.error('Error during rejection request:', error);
        // alert('Error Rejecting Candidates: ' + error.message);
        setModalText({
          headerText: 'Error Rejecting Candidates',
          bodyText: 'Error Rejecting Candidates: ' + error.message,
          buttonText: 'Okay',
        });
        setShowModal(true);
      }
    } else {
      console.warn('Grid API is not ready yet.');
    }
  };

  const handleWeightageChange = (interviewName, criteria, value) => {
    if (value === '' || isNaN(value)) {
      setTempWeightages((prev) => ({
        ...prev,
        [interviewName]: {
          ...prev[interviewName],
          [criteria]: value,
        },
      }));
    } else {
      const numericValue = parseFloat(value);
      setTempWeightages((prev) => ({
        ...prev,
        [interviewName]: {
          ...prev[interviewName],
          [criteria]: numericValue,
        },
      }));

      setWeightages((prev) => ({
        ...prev,
        [interviewName]: {
          ...prev[interviewName],
          [criteria]: numericValue,
        },
      }));
    }
  };

  const saveWeightages = () => {
    localStorage.setItem(
      `weightages_${clientJobId}`,
      JSON.stringify(tempWeightages)
    );
    setWeightages(tempWeightages);
    alert('Weightages saved successfully!');
  };

  const downloadExcel = () => {
    const wsData = [];
    const headers = ['Candidate', 'Total Score']; // Add the Total Score header
    let hasCheatingCriteria = false;
    let hasScreeningRound = false;
    Object.keys(evaluationCriteriaMap).forEach((interviewName) => {
      evaluationCriteriaMap[interviewName].forEach((criteria) => {
        if(criteria.toLowerCase().includes('cheating')) hasCheatingCriteria = true;
        else if(criteria.toLowerCase().includes('screening')) hasScreeningRound = true;
        else headers.push(`${interviewName} - ${criteria}`);
      });
    });
    
    if(hasCheatingCriteria) headers.push('Cheating Risk');
    if(hasScreeningRound) headers.push('Screening Evaluation');
    if (
      cvRequirements.cvUploadRequired &&
      cvRequirements.cvScreeningInstructions &&
      cvRequirements.cvScreeningInstructions.trim() !== ''
    ) headers.push('CV Evaluation');
    
    wsData.push(headers);

    applicantReports.forEach((applicant) => {
      const row = [applicant.userName];
      let totalWeightedScore = 0; // Total weighted score
      let hasHighRisk = false;
      let allNoCheating = true;
      let totalScreeningScore = 0; // total aggregate value


      Object.keys(evaluationCriteriaMap).forEach((interviewName) => {
        evaluationCriteriaMap[interviewName].forEach((criteria) => {
          const score = parseFloat(
            applicant.interviews[interviewName]?.[criteria] ?? 0
          );
          if(criteria.toLowerCase().includes('cheating')) {
            // Process cheating flags to determine risk level
            if (score > 1.33) hasHighRisk = true;
            if (score >= 0.75) allNoCheating = false; // Not "No Cheating" if any flag >= 0.75
          } else if(criteria.toLowerCase().includes('screening')) {
            totalScreeningScore += Math.round(score);
          } else {
            const weightage = weightages[interviewName]?.[criteria] ?? 1;
            const weightedScore = score * weightage;
            row.push(score.toFixed(1));
            if (!isNaN(weightedScore)) {
              totalWeightedScore += weightedScore;
            }
          }
        });
      });

      
      if(hasCheatingCriteria) {
        let cheatingEvaluation = 'No Cheating';
        if (hasHighRisk) {
          cheatingEvaluation = 'High Risk';
        } else if (allNoCheating) {
          cheatingEvaluation = 'No Cheating';
        } else {
          cheatingEvaluation = 'Moderate Risk';
        }
        row.push(cheatingEvaluation);
      }

      if(hasScreeningRound) {
        const screeningEvaluation = `${totalScreeningScore} / ${screeningInstructionsCount} criteria satisfied`
        row.push(screeningEvaluation);
      }

      if (
        cvRequirements.cvUploadRequired &&
        cvRequirements.cvScreeningInstructions &&
        cvRequirements.cvScreeningInstructions.trim() !== ''
      ) {
        const cvData = allCvData.find((cv) => cv.userId === applicant.userId);
        let cvEvaluation = '';

        if (cvData) {
          if (cvData.totalCount === 0) {
            cvEvaluation = `No Screening Criteria`;
          } else {
            cvEvaluation = `${cvData.satisfiedCount}/${cvData.totalCount} criteria satisfied`;
          }
        } else {
          cvEvaluation = 'CV Not Found';
        }

        row.push(cvEvaluation);
      }

      row.splice(1, 0, totalWeightedScore.toFixed(1));
      wsData.push(row);
    });

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Reports');

    XLSX.writeFile(wb, `ApplicantReports_${jobName}.xlsx`);
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    const tableColumn = ['Candidate', 'Total Score'];
    const tableRows = [];

    let hasCheatingCriteria = false;
    let hasScreeningRound = false;
    Object.keys(evaluationCriteriaMap).forEach((interviewName) => {
      evaluationCriteriaMap[interviewName].forEach((criteria) => {
        if(criteria.toLowerCase().includes('cheating')) hasCheatingCriteria = true;
        else if(criteria.toLowerCase().includes('screening')) hasScreeningRound = true;
        else tableColumn.push(`${interviewName} - ${criteria}`);
      });
    });
    
    if(hasCheatingCriteria) tableColumn.push('Cheating Risk');
    if(hasScreeningRound) tableColumn.push('Screening Evaluation');

    applicantReports.forEach((applicant) => {
      const rowData = [applicant.userName];
      let totalWeightedScore = 0; // Total weighted score
      let hasHighRisk = false;
      let allNoCheating = true;
      let totalScreeningScore = 0; // total aggregate value

      Object.keys(evaluationCriteriaMap).forEach((interviewName) => {
        evaluationCriteriaMap[interviewName].forEach((criteria) => {
          const score = parseFloat(
            applicant.interviews[interviewName]?.[criteria] ?? 0
          );
          if(criteria.toLowerCase().includes('cheating')) {
            // Process cheating flags to determine risk level
            if (score > 1.33) hasHighRisk = true;
            if (score >= 0.75) allNoCheating = false; // Not "No Cheating" if any flag >= 0.75
          } else if(criteria.toLowerCase().includes('screening')) {
            totalScreeningScore += Math.round(score);
          } else {
            const weightage = weightages[interviewName]?.[criteria] ?? 1;
            const weightedScore = score * weightage;
            rowData.push(score.toFixed(1));
            if (!isNaN(weightedScore)) {
              totalWeightedScore += weightedScore;
            }
          }
        });
      });

      
      if(hasCheatingCriteria) {
        let cheatingEvaluation = 'No Cheating';
        if (hasHighRisk) {
          cheatingEvaluation = 'High Risk';
        } else if (allNoCheating) {
          cheatingEvaluation = 'No Cheating';
        } else {
          cheatingEvaluation = 'Moderate Risk';
        }
        rowData.push(cheatingEvaluation);
      }

      if(hasScreeningRound) {
        const screeningEvaluation = `${totalScreeningScore} / ${screeningInstructionsCount} criteria satisfied`
        rowData.push(screeningEvaluation);
      }

      rowData.splice(1, 0, totalWeightedScore.toFixed(1));
      tableRows.push(rowData);
    });

    doc.text(`Applicant Reports: ${jobName}`, 14, 16);
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
    });
    doc.save(`ApplicantReports_${jobName}.pdf`);
  };

  // Generate columns based on the evaluation criteria, without additional API calls
  const generateColumns = () => {
    let hasCheatingCriteria = false;
    let hasScreeningRound = false;

    // Optimized loop to check for "cheating" criteria
    for (const interviewName of Object.keys(evaluationCriteriaMap)) {
      for (const criteria of evaluationCriteriaMap[interviewName]) {
        if (criteria.toLowerCase().includes('cheating')) {
          console.log('CheatingCriteria: ', criteria); // Debug log
          hasCheatingCriteria = true;
        }
        if (criteria.toLowerCase().includes('screening')) {
          console.log('Screening Instruction: ', interviewName); // Debug log
          hasScreeningRound = true;
        }
      }

      if (hasCheatingCriteria && hasScreeningRound) break; // Exit outer loop
    }

    console.log('hasCheatingCriteria: ', hasCheatingCriteria); // Debug log
    console.log('hasScreeningRound: ', hasScreeningRound); // Debug log

    const columns = [
      {
        headerName: 'Candidate',
        field: 'userName',
        cellRenderer: (params) => {
          console.log('params.data:', params.data); // Debug log
          return (
            <Link
              className="candidate-link"
              //to={`/jobs/all-scores/${clientJobId}/${params.data.userId}?candidateName=${encodeURIComponent(params.data.userName)}`}
              to={`/candidate/${params.data.userId}/job/${clientJobId}/cv`}
              state={{ from: `/client-jobs/${clientJobId}/applicant-reports` }}
            >
              {params.data.userName}
            </Link>
          );
        },
        sortable: true,
        filter: true,
      },
    ];

    // Conditionally add the "Cheating Risk" column
    if (hasCheatingCriteria) {
      columns.push({
        headerName: 'Cheating Risk',
        field: 'cheatingRisk', // Use the calculated `cheatingRisk` field
        cellRenderer: (params) => {
          const cheatingRisk = params.value || 'No Cheating';
          let style = {};

          // Determine styles based on risk level
          if (cheatingRisk === 'No Cheating') {
            style = { color: 'green', fontWeight: 'bold' };
          } else if (cheatingRisk === 'Moderate Risk') {
            style = { color: 'orange', fontWeight: 'bold' };
          } else if (cheatingRisk === 'High Risk') {
            style = { color: 'red', fontWeight: 'bold' };
          }

          return <span style={style}>{cheatingRisk}</span>;
        },
        sortable: true,
        filter: 'agTextColumnFilter', // Allow filtering by "Cheating Risk"
      });
    }

    // show cv screening criterias fulfilled

    if (
      cvRequirements.cvUploadRequired &&
      cvRequirements.cvScreeningInstructions &&
      cvRequirements.cvScreeningInstructions.trim() !== ''
    ) {
      columns.push({
        headerName: 'CV Screening',
        field: 'cv_screening',
        cellRenderer: (params) => {
          const { cv_screening, cv_screening_score } = params.data ?? {
            cv_screening_score: -1,
          };
          let style = {};          

          // Determine styles based on score level
          if (cv_screening_score < 0.33) {
            // satisfied none of the instructions
            style = { color: '#b22222', fontWeight: 'bold' };
          } else if (cv_screening_score < 0.75) {
            // satisfied some of the instructions
            style = { color: '#ffd700', fontWeight: 'bold' };
          } else if (cv_screening_score <= 1) {
            // satisfied all of the instructions
            style = { color: '#228b22', fontWeight: 'bold' };
          }

          return <span style={style}>{cv_screening}</span>;
        },
        sortable: true,
        filter: true,
      });
    }

    if (hasScreeningRound) {
      columns.push({
        headerName: 'Screening Evaluation',
        field: 'screeningResult',
        cellRenderer: (params) => {
          const { screeningEvaluation, screeningScore } = params.value ?? {
            screeningScore: -1,
          };
          let style = {};

          // Determine styles based on score level
          if (screeningScore < 0.33) {
            // satisfied none of the instructions
            style = { color: '#b22222', fontWeight: 'bold' };
          } else if (screeningScore < 0.75) {
            // satisfied some of the instructions
            style = { color: '#ffd700', fontWeight: 'bold' };
          } else if (screeningScore <= 1) {
            // satisfied all of the instructions
            style = { color: '#228b22', fontWeight: 'bold' };
          }

          return <span style={style}>{screeningEvaluation}</span>;
        },
        sortable: true,
        filter: 'agTextColumnFilter', // Allow filtering by "Screening Evaluation"
      });
    }

    // Add a Total Score column that sums up all relevant numeric columns
    columns.push({
      headerName: 'Total Score',
      field: 'totalScore',
      valueGetter: (params) => {
        let totalScore = 0;
        Object.keys(evaluationCriteriaMap).forEach((interviewName) => {
          evaluationCriteriaMap[interviewName]
            .filter((criteria) => !criteria.toLowerCase().includes('cheating')) // Exclude cheating criteria
            .filter(
              (criteria) => !criteria.toLowerCase().includes('typing speed')
            ) // Exclude typing speed criteria
            .filter((criteria) => !criteria.toLowerCase().includes('screening')) // Exclude screening evaluation
            .forEach((criteria) => {
              const score = parseFloat(
                params.data[`${interviewName}_${criteria}`] ?? 0
              );
              const weightage = weightages[interviewName]?.[criteria];
              console.log(weightage);
              if (weightage > 0 && !isNaN(score)) {
                totalScore += score * weightage;
              }
            });
        });
        return parseFloat(totalScore.toFixed(1));
      },
      cellStyle: (params) => {
        const totalScore = params.value || 0;
        return totalScore >= cutoffScore
          ? { color: 'green', fontWeight: 'bold' } // Green for scores >= cutoff
          : { color: 'red', fontWeight: 'bold' }; // Red for scores < cutoff
      },
      type: 'numericColumn',
      sortable: true,
      filter: 'agNumberColumnFilter',
    });

    Object.keys(evaluationCriteriaMap).forEach((interviewName) => {
      const interviewColumns = evaluationCriteriaMap[interviewName]
        .filter((criteria) => !criteria.toLowerCase().includes('cheating'))
        .filter((criteria) => !criteria.toLowerCase().includes('screening')) // Exclude screening evaluation
        .map((criteria) => ({
          headerName: criteria,
          field: `${interviewName}_${criteria}`, // Now directly access the prepared rowData fields
          sortable: true,
          filter: 'agNumberColumnFilter',
          type: 'numericColumn',
          valueGetter: (params) => {
            const value = parseFloat(
              params.data[`${interviewName}_${criteria}`]
            );
            return !isNaN(value)
              ? parseFloat(value.toFixed(1))
              : parseFloat('0.0'); // Restrict to 1 decimal place
          },
        }));

      columns.push({
        headerName: interviewName,
        children: interviewColumns, // Group the criteria columns under each interview name
      });
    });

    // push the latest attempt date , if latest_attempt is null -> then show 'All Interviews not attempted'
    columns.push({
      headerName: 'Latest Attempt',
      field: 'latest_attempt',
      sortable: true,
      filter: 'agDateColumnFilter',
    });

    // show the user job status, if Accepted or Rejected, dont allow to select the row
    // columns.push({
    //     headerName: 'Job Status',
    //     field: 'job_status',
    //     sortable: true,
    //     filter: true,
    // });

    return columns;
  };

  // Fetch CV Details
  useEffect(() => {
    const fetchCvDetailsForApplicants = async () => {
      try {
        const applicantCvPromises = applicantReports.map(async (applicant) => {
          const userId = applicant.userId;

          // Check if CV exists
          const cvExistenceResponse = await fetch(
            getApiUrl(`/api/check-cv/${userId}/${jobId}`)
          );
          const cvExistenceData = await cvExistenceResponse.json();

          let satisfiedCount = 0;
          let totalCount = 0;

          if (cvExistenceData.uploaded) {
            // Fetch CV details
            const cvDetailsResponse = await fetch(
              getApiUrl(`/api/cv-details/${userId}/${jobId}`)
            );
            const cvDetailsData = await cvDetailsResponse.json();

            const evaluationDetails =
              JSON.parse(cvDetailsData.cv_assessment_system)
                ?.job_requirements_status || [];
            satisfiedCount = evaluationDetails.filter(
              (req) => req.satisfied
            ).length;
            totalCount = evaluationDetails.length;
          }

          return {
            userId,
            satisfiedCount,
            totalCount,
          };
        });

        const allCvResults = await Promise.all(applicantCvPromises);
        setAllCvData(allCvResults);
      } catch (error) {
        console.error('Error fetching CV details for applicants:', error);
        throw error;
      }
    };
    fetchCvDetailsForApplicants();
  }, [applicantReports, jobId]);

  const rowData = applicantReports.map((applicant) => {
    const row = { userName: applicant.userName, userId: applicant.userId };
    let hasHighRisk = false;
    let allNoCheating = true;
    let cheatingFlagsFound = false;
    let screeningRoundFound = false;
    let screeningEvaluation = '';
    let screeningScore = 0; // ranges from [0, 1]
    let totalScreeningScore = 0; // total aggregate value

    // Map each interview and criterion to a unique field in the row
    Object.keys(applicant.interviews).forEach((interviewName) => {
      Object.keys(applicant.interviews[interviewName]).forEach((criteria) => {
        const score = parseFloat(
          applicant.interviews[interviewName][criteria] || 0
        );
        if (criteria.toLowerCase().includes('cheating')) {
          cheatingFlagsFound = true; // Mark that cheating data exists
          // Process cheating flags to determine risk level
          if (score > 1.33) hasHighRisk = true;
          if (score >= 0.75) allNoCheating = false; // Not "No Cheating" if any flag >= 0.75
        } else if (criteria.toLowerCase().includes('screening')) {
          screeningRoundFound = true;
          totalScreeningScore += score;
        } else {
          // Non-cheating criteria: add to row data
          row[`${interviewName}_${criteria}`] = score;
        }
      });
    });

    screeningEvaluation = `${Math.floor(
      totalScreeningScore
    )} / ${screeningInstructionsCount} criteria satisfied`;
    if (screeningInstructionsCount > 0) {
      screeningScore = totalScreeningScore / screeningInstructionsCount;
    }

    // Add aggregated cheating risk to the row
    if (!cheatingFlagsFound) {
      row.cheatingRisk = 'No Cheating'; // Default to "No Cheating" if no flags are found
    } else if (hasHighRisk) {
      row.cheatingRisk = 'High Risk';
    } else if (allNoCheating) {
      row.cheatingRisk = 'No Cheating';
    } else {
      row.cheatingRisk = 'Moderate Risk';
    }

    if (!screeningRoundFound) {
      row.screeningEvaluation = {}; // Default to "No Screening Round" if no screening rounds are found
    } else {
      row.screeningResult = { screeningScore, screeningEvaluation };
    }

    // For attaching total score in row data
    let totalScore = 0;
    Object.keys(evaluationCriteriaMap).forEach((interviewName) => {
      evaluationCriteriaMap[interviewName]
        .filter((criteria) => !criteria.toLowerCase().includes('cheating')) // Exclude cheating criteria
        .filter((criteria) => !criteria.toLowerCase().includes('typing speed')) // Exclude typing speed criteria
        .forEach((criteria) => {
          const score = parseFloat(row[`${interviewName}_${criteria}`] ?? 0);
          const weightage = weightages[interviewName]?.[criteria];
          console.log(weightage);
          if (weightage > 0 && !isNaN(score)) {
            totalScore += score * weightage;
          }
        });
    });
    // console.log("Applicant total_score", totalScore);
    row.total_score = parseFloat(totalScore.toFixed(1));

    row.latest_attempt = applicant.latest_attempt
      ? formatDate(applicant.latest_attempt)
      : 'N.A';
    row.job_status = applicant.userJobStatus;

    // Add CV criteria details
    const cvData = allCvData.find((cv) => cv.userId === applicant.userId);
    // console.log("CV DATA", cvData);

    if (cvData) {
      if (cvData.totalCount === 0) {
        row.cv_screening = `No Screening Criteria`;
      } else {
        row.cv_screening = `${cvData.satisfiedCount}/${cvData.totalCount} criteria satisfied`;
        row.cv_screening_score = cvData.satisfiedCount/cvData.totalCount
      }
    } else {
      row.cv_screening = 'CV Not Found';
    }

    return row;
  });

  // Function to calculate the average cutoff score
  useEffect(() => {
    const calculateAverageCutoffScore = () => {
      console.log('Row Data', rowData);

      if (!rowData || rowData.length === 0) return 0;

      const totalScores = rowData
        .map((candidate) => parseFloat(candidate.total_score ?? 0))
        .filter((score) => score !== 0); // Only include non-zero scores;

      // Check if any valid scores remain after filtering
      if (totalScores.length === 0) return 0;

      const totalSum = totalScores.reduce((acc, score) => acc + score, 0);
      return parseFloat((totalSum / totalScores.length).toFixed(1));
    };
    const initialCutoff = calculateAverageCutoffScore();
    console.log('Initial Cutoff:', initialCutoff);

    setCutoffScore(initialCutoff);
  }, [applicantReports]); // Runs when `rowData` changes

  // Function to dynamically update the cutoff score
  const handleCutoffChange = (e) => {
    const value = parseFloat(e.target.value) || 0; // Ensure it's a number or default to 0
    setCutoffScore(value);
  };

  const exportToCsv = () => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.exportDataAsCsv();
    } else {
      console.warn('Grid API is not ready yet.');
    }
  };
  /*
    // Create Chart Options (Bar chart as an example)
    const chartOptions = {
        data: rowData.map(row => ({ userName: row.userName, totalScore: parseFloat(row.totalScore) })),
        series: [
            {
                type: 'bar',
                xKey: 'userName',
                yKey: 'totalScore',
                yName: 'Total Score',
            },
        ],
        legend: {
            enabled: true,
        },
        axes: [
            {
                type: 'category',
                position: 'bottom',
                label: {
                    rotation: -45
                }
            },
            {
                type: 'number',
                position: 'left'
            }
        ]
    };
    */

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleWeightageTable = () => {
    setShowWeightageTable((prevShow) => !prevShow);
  };

  const handleExport = (format) => {
    setDropdownOpen(false);
    switch (format) {
      case 'csv':
        exportToCsv();
        break;
      case 'pdf':
        downloadPDF();
        break;
      case 'excel':
        downloadExcel();
        break;
      default:
        break;
    }
  };
  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
        <p className="loader-text">Loading applicant reports, please wait...</p>
      </div>
    );
  }

  if (applicantReports.length === 0) {
    return <p>No applicants or no data found for this job.</p>;
  }

  const candidateCount = rowData.length;
  console.log('candidateCount:', candidateCount);

  return (
    <>
      {showModal && (
        <Modal
          toggleModal={toggleModal}
          headerText={modalText.headerText}
          bodyText={modalText.bodyText}
          buttonText={modalText.buttonText}
        />
      )}
      <div className="applicant-reports-container historical-scores-container">
        <header className="dashboard-navbar">
          <div className="poppins-regular">
            <div>
              <Link
                to="/"
                className="logo"
                style={{
                  textDecoration: 'none',
                  marginBottom: '0px',
                  marginTop: '10px',
                }}
              >
                <span className="logo">
                  <span className="logo-peh">Peh</span>
                  <span className="logo-chaan">chaan</span>
                </span>
              </Link>
            </div>
            <div>
              <Link to="/client-dashboard" className="job-listings-nav">
                Job Listings
              </Link>
              &gt;{' '}
              <Link
                to={`/client-jobs/${clientJobId}/candidates-list`}
                className="job-listings-nav"
              >
                {job.job_title} [All Candidates]
              </Link>
              &gt; Scores
            </div>
          </div>
          <div className="export poppins-medium">
            <button className="btn" onClick={toggleDropdown}>
              <PiExport
                style={{
                  fontSize: '20px',
                  color: '#fff623',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              />
              Export
            </button>
            {dropdownOpen && (
              <div className="dropdown-menu">
                <button onClick={() => handleExport('csv')}>
                  <PiFileCsv
                    style={{
                      fontSize: '20px',
                      color: '#fff623',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  />{' '}
                  CSV
                </button>
                <button onClick={() => handleExport('pdf')}>
                  {' '}
                  <PiFilePdfLight
                    style={{
                      fontSize: '20px',
                      color: '#fff623',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  />{' '}
                  PDF
                </button>
                <button onClick={() => handleExport('excel')}>
                  <PiFileXls
                    style={{
                      fontSize: '20px',
                      color: '#fff623',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  />{' '}
                  Excel
                </button>
              </div>
            )}
          </div>
        </header>
        <main className="dashboard-main">
          <Flex justifyContent='space-between' alignItems='center'>
            <Box bg='{colors.backgroundSecondary}' display='flex' px='2' py='1' borderRadius='{radii.buttonRadius}'>
              <Text>Cutoff Score :{' '}</Text>
              <input
                className="cutoff-input"
                type="number"
                value={cutoffScore}
                onChange={handleCutoffChange}
              />
            </Box>
            <Flex gap='2' alignItems='center'>
              <Button visual='standard'
                onClick={toggleWeightageTable}
              >
                Change Weightage
              </Button>
              <Button visual='standard'
                onClick={refreshApplicantReports}
              >
                {refreshing ? (
                  <BsArrowRepeat
                    className="spinner-icon"
                    style={{ fontSize: '20px', marginRight: '8px' }}
                  />
                ) : (
                  <BsArrowRepeat
                    style={{ fontSize: '20px', marginRight: '8px' }}
                  />
                )}
                <div>{refreshing ? 'Refreshing...' : 'Refresh Scores'}</div>
              </Button>
              <Button visual='standard'
                onClick={handleSendShortlistEmail}
              >
                Send Shortlisting Email
              </Button>
              <Button visual='standard'
                onClick={handleSendRejectionEmail}
              >
                Send Rejection Email
              </Button>
            </Flex>
            <div className="candidates poppins-regular">
              <h3>Total Candidates Attempted: {candidateCount}</h3>
            </div>
          </Flex>
          <div
            className="ag-theme-quartz-dark poppins-medium"
            style={{ height: 'fit-content', width: '100%', marginTop: '10px' }}
          >
            <AgGridReact
              ref={gridRef} // Attach the grid reference
              rowData={rowData}
              columnDefs={generateColumns()}
              pagination={true}
              paginationPageSize={10}
              paginationPageSizeSelector={[10, 20, 50]}
              domLayout="autoHeight"
              selection={{
                mode: 'multiRow',
                isRowSelectable: (node) => {
                  const jobStatus = node.data?.job_status;
                  return jobStatus !== 'Accepted' && jobStatus !== 'Rejected';
                },
              }}
              getRowStyle={(params) => {
                const jobStatus = params.data?.job_status;
                if (jobStatus === 'Accepted') {
                  return { backgroundColor: 'rgba(177, 241, 168, 0.58)' }; // Light green with reduced opacity
                }
                if (jobStatus === 'Rejected') {
                  return { backgroundColor: '#5b5b5b' }; // Light red with reduced opacity
                }
                return null; // Default styling
              }}
              defaultColDef={{
                sortable: true,
                filter: true,
                resizable: true,
                type: 'numericColumn',
              }}
            />
          </div>
          <div>
            {showWeightageTable && (
              <div>
                <table className="reports-table poppins-medium">
                  <thead>
                    <tr>
                      <th>Interview</th>
                      <th>Criteria</th>
                      <th>Weightage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(evaluationCriteriaMap).map((interviewName) =>
                      evaluationCriteriaMap[interviewName].map((criteria) => (
                        <tr key={`${interviewName}-${criteria}`}>
                          <td>{interviewName}</td>
                          <td>{criteria}</td>
                          <td>
                            <input
                              type="number"
                              value={weightages[interviewName][criteria]}
                              onChange={(e) =>
                                handleWeightageChange(
                                  interviewName,
                                  criteria,
                                  e.target.value
                                )
                              }
                              defaultValue="1"
                              min="0"
                              step="0.1"
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                <button
                  onClick={saveWeightages}
                  className="save-weightage-btn poppins-medium"
                  style={{ marginTop: '10px' }}
                >
                  Save Weightages
                </button>
              </div>
            )}
          </div>
        </main>
      </div>
    </>
  );
};

export default JobApplicantReports;
