import React, { useState } from 'react';
import styles from './BrandingModal.module.css';
import { Box, Button, Heading, Text } from '@chakra-ui/react';

const BrandingModal = ({ onClose, onSave, initialBranding }) => {
    const defaultBranding = {
        brandingType: 'text',
        brandImage: '',
        brandText: 'Pehchaan',
        brandColor: '#FFD700',
        brandBgColor: '#000000',
        brandLink: 'https://www.pehchaaan.me',
        // buttonColor: '#EDC500',
        // buttonText: 'View Details',
        // buttonLink: 'https://www.pehchaaan.me/jobs',
        signature: ''
    };

    const [branding, setBranding] = useState(initialBranding || defaultBranding);

    const handleChange = (field, value) => {
        setBranding(prev => ({ ...prev, [field]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedBranding = {
            ...branding,
            brandColor: ensureHexColor(branding.brandColor),
            brandBgColor: ensureHexColor(branding.brandBgColor),
            // buttonColor: ensureHexColor(branding.buttonColor)
        };
        onSave(updatedBranding);
    };

    const ensureHexColor = (color) => {
        // Check if it's already a 6-character hex without a leading '#'
        if (/^[0-9A-Fa-f]{6}$/.test(color)) {
            return `#${color.toUpperCase()}`;
        }

        // Check if it's already a valid hex with '#'
        if (/^#[0-9A-Fa-f]{6}$/.test(color)) {
            return color.toUpperCase();
        }

        // Extract RGB values
        const rgb = color.match(/\d+/g);
        if (rgb) {
            const hex = `#${((1 << 24) + (parseInt(rgb[0]) << 16) + (parseInt(rgb[1]) << 8) + parseInt(rgb[2]))
                .toString(16)
                .slice(1)
                .toUpperCase()}`;
            return hex;
        }

        // Return unchanged if none of the above cases apply
        return color;
    };


    return (
        <div className={styles['modal-overlay']}>
            <div className={styles['modal-content']}>
                <div className={styles['modal-header']}>
                    <Heading as='h2'>Brand Settings</Heading>
                    <button className={styles['close-button']} onClick={onClose}>&times;</button>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className={styles['modal-body']}>
                        <div className={styles['branding-form']}>
                            <div className={styles['form-section']}>
                                <Heading as='h3'>Brand Identity</Heading>
                                <div className={styles['form-group']}>
                                    <label>Branding Type</label>
                                    <div className={styles['radio-group']}>
                                        <div className={styles['radio-option']}>
                                            <input
                                                type="radio"
                                                id="text"
                                                name="brandingType"
                                                value="text"
                                                checked={branding.brandingType === 'text'}
                                                onChange={(e) => handleChange('brandingType', e.target.value)}
                                            />
                                            <label htmlFor="text">Text Logo</label>
                                        </div>
                                        <div className={styles['radio-option']}>
                                            <input
                                                type="radio"
                                                id="image"
                                                name="brandingType"
                                                value="image"
                                                checked={branding.brandingType === 'image'}
                                                onChange={(e) => handleChange('brandingType', e.target.value)}
                                            />
                                            <label htmlFor="image">Image Logo</label>
                                        </div>
                                    </div>
                                </div>

                                {branding.brandingType === 'image' ? (
                                    <div className={styles['form-group']}>
                                        <label>Logo Image URL</label>
                                        <input
                                            type="url"
                                            className={styles['input-field']}
                                            value={branding.brandImage}
                                            onChange={(e) => handleChange('brandImage', e.target.value)}
                                            placeholder="Enter logo image URL"
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <div className={styles['form-group']}>
                                            <label>Brand Text</label>
                                            <input
                                                type="text"
                                                className={styles['input-field']}
                                                value={branding.brandText}
                                                onChange={(e) => handleChange('brandText', e.target.value)}
                                                placeholder="Enter brand text"
                                            />
                                        </div>
                                        <div className={styles['form-group']}>
                                            <label>Brand Text Color</label>
                                            <div className={styles['color-picker']}>
                                                <input
                                                    type="color"
                                                    className={styles['color-input']}
                                                    value={ensureHexColor(branding.brandColor)}
                                                    onChange={(e) => handleChange('brandColor', ensureHexColor(e.target.value))}
                                                />
                                                <span>{branding.brandColor}</span>
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div className={styles['form-group']}>
                                    <label>Brand Background Color</label>
                                    <div className={styles['color-picker']}>
                                        <input
                                            type="color"
                                            className={styles['color-input']}
                                            value={ensureHexColor(branding.brandBgColor)}
                                            onChange={(e) => handleChange('brandBgColor', ensureHexColor(e.target.value))}
                                        />
                                        <span>{branding.brandBgColor}</span>
                                    </div>
                                </div>

                                <div className={styles['form-group']}>
                                    <label>Brand Link</label>
                                    <input
                                        type="url"
                                        className={styles['input-field']}
                                        value={branding.brandLink}
                                        onChange={(e) => handleChange('brandLink', e.target.value)}
                                        placeholder="Enter brand link URL"
                                    />
                                </div>
                            </div>

                            <div className={styles['form-section']}>
                                <Heading as='h3'>Email Signature</Heading>
                                <div className={styles['form-group']}>
                                    <label>Signature Text</label>
                                    <input
                                        type="text"
                                        className={styles['input-field']}
                                        value={branding.signature}
                                        onChange={(e) => handleChange('signature', e.target.value)}
                                        placeholder="Enter email signature"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles['modal-footer']}>
                        <Button
                            visual='standard'
                            type="button"
                            // className={`${styles.button} ${styles['secondary-button']}`}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            visual='standard'
                        // className={`${styles.button} ${styles['primary-button']}`}
                        >
                            Save Branding & Apply To Templates
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default BrandingModal;
