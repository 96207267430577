import React from "react";
import "./Modal.css"; // Add your styling for the modal
import { Button } from "@chakra-ui/react";

const Modal = ({ toggleModal, headerText, bodyText, buttonText }) => {
  return (
    <div className="modal-overlay poppins-medium">
      <div className="modal-content">
        <h2 style={{ color: "#fedc40" }}>{headerText}</h2>
        <p>{bodyText}</p>
        <Button onClick={toggleModal}>{buttonText}</Button>
      </div>
    </div>
  );
};

export default Modal;
