import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getApiUrl } from '../utils/apiUtils';
import { ClientUserContext } from '../context/ClientUserContext'; // Import ClientUserContext
import ReactPlayer from 'react-player';
import { FaPlus } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import './CreateQuestions.css';


const CreateQuestions = () => {
    const [error, setError] = useState(null); // New state for error handling
    const [loading, setLoading] = useState(true);
    const [loaderMessage, setLoaderMessage] = useState('Fetching Questions');

    const navigate = useNavigate();
    const [job, setJob] = useState();
    const [interview, setInterview] = useState();
    const [questions, setQuestions] = useState();
    const [questionSetToDisplay, setQuestionSetToDisplay] = useState(1);
    const [gptResponse, setGptResponse] = useState();
    const [createQuestionForm, setCreateQuestionsForm] = useState({
        questionSet: 1,
        questionType: 'Video',
        scoringCriteria: '[0-1]',
    });
    const [interviewInstructions, setInterviewInstructions] = useState();
    const [questionLevelEvaluation, setQuestionLevelEvaluation] = useState();
    const [interviewEvaluationCriteria, setInterviewEvaluationCriteria] = useState();
    const [allEvaluationCategories, setAllEvaluationCategories] = useState();
    const [numberOfQuestionSet, setNumberOfQuestionSet] = useState(1);
    const [showSideDrawer, setShowSideDrawer] = useState(false);
    const { clientUser } = useContext(ClientUserContext); // Access client user details from context
    const { jobId, interviewId } = useParams();
    const { clientJobInterviewId } = useLocation().state;
    const newQuestionFormDialogRef = useRef();
    const [generateQuestionsForm, setGenerateQuestionsForm] = useState({
        promptText: '',
    });

    if (error) {
        console.log("Error: ", error);
    }

    useEffect(() => {
        const getInterviewEvaluationCriteria = async () => {
            try {
                const url = getApiUrl(`/api/interview-evaluation-criteria/${clientJobInterviewId}`);
                const res = await fetch(url);
                const criteria = await res.json();

                if(res.status === 200) {
                    console.log('Criteria: ', criteria);
                    setInterviewEvaluationCriteria(criteria);
                }

            } catch (error) {
                console.log(error);
            }
        }
        const getAllEvaluationCategories = async () => {
            try {
                const url = getApiUrl(`/api/job-interview-evaluation-categories`);
                const res = await fetch(url);
                const categories = await res.json();

                if(res.status === 200) {
                    console.log('Categories: ', categories);
                    setAllEvaluationCategories(categories.reduce((acc, category) => {
                        acc[category.id] = category
                        return acc;
                    }, {}));
                }

            } catch (error) {
                console.log(error);
            }
        }
        const getJobInterviewDetails = async () => {
            try {
                const url = getApiUrl(`/api/interviews/client-job-interview/${clientJobInterviewId}/details`);
                const jobInterviewResponse = await fetch(url);
                
                if(jobInterviewResponse.ok) {
                    const data = await jobInterviewResponse.json();
                    setJob(data.interviewData.clientjob);
                    setInterview(data.interviewData.interview);
                    setInterviewInstructions(data.interviewData.interview.instructions);
                    setQuestions(data.interviewData.interview.interview_questions.reduce((acc, question) => {
                        if(acc[question.question_set] === undefined) acc[question.question_set] = [ question ];
                        else acc[question.question_set].push(question);

                        return acc;
                    }, {}));
                    setNumberOfQuestionSet(data.interviewData.number_of_question_sets);
                }
            } catch (error) {
                console.log(error);    
            } finally {
                setLoading(false);
            }
        }
        
        
        getJobInterviewDetails();
        getInterviewEvaluationCriteria();
        getAllEvaluationCategories();
    }, []);

    useEffect(() => {
        const getQuestionLevelEvaluation = () => {
            const data = interviewEvaluationCriteria.filter(criteria => {
                return allEvaluationCategories[criteria.evaluation_category_id].evaluation_level === 'question';
            });
            console.log(data);
            if(data.length) setQuestionLevelEvaluation(data[0]);
        }

        if(allEvaluationCategories && interviewEvaluationCriteria) getQuestionLevelEvaluation();
    }, [allEvaluationCategories, interviewEvaluationCriteria]);

    const handleGenerateQuestionsFormChange = (e) => {
        const { name, value } = e.target; 
        setGenerateQuestionsForm(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    }

    const handleResponseChange = (e, idx) => {
        if(e.target.value.length === 0) {
            const responses = { ...gptResponse };
            delete responses[idx];
            setGptResponse(responses);
        } else setGptResponse(prev => ({ ...prev, [idx]: { question_script: e.target.value } }));
    }

    const handleCreateQuestionChange = (e) => {
        const { name, value } = e.target;
        setCreateQuestionsForm(prevData => ({
            ...prevData,
            [name]: value,
        }));
    }

    // For generating questions
    const generateQuestions = async (e) => {
        e.preventDefault();

        setLoading(true);
        setLoaderMessage('Generating Interview Questions');
        let sendJD = true;
        if(gptResponse) sendJD = false;

        try {
            const url = getApiUrl('/api/generate-interview-questions');
            const res = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    userQuery: {
                        job_title: job.job_title,
                        job_description: sendJD ? job.job_description : "",
                        interview_name: interview.interview_name,
                        text: generateQuestionsForm.promptText,
                        previous_response: gptResponse ? JSON.stringify(Object.values(gptResponse)) : '',
                        context_text: interviewInstructions.context_video_text
                    }
                })
            });
            const data = await res.json();
            console.log(data.questions);
            const responses = JSON.parse(data.questions);
            setGptResponse(responses.reduce((acc, response, idx) => {
                acc[idx] = response;
                return acc;
            }, {}));
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const handleCreateQuestionsFromResponse = async () => {
        let questionStartIndex;
        if(questions && questions[createQuestionForm.questionSet]) questionStartIndex = questions[createQuestionForm.questionSet].length + 1;
        else questionStartIndex = 1;
        
        console.log('Questions Data: ', {
            client_user_id: clientUser.id,
            interview_id: interview.id,
            interview_name: interview.interview_name,
            context_text: interviewInstructions.context_video_text,
            questions_data: Object.values(gptResponse),
            question_set: createQuestionForm.questionSet,
            question_type: createQuestionForm.questionType,    
            question_start_index: questionStartIndex,
            evaluation_category_id: questionLevelEvaluation?.evaluation_category_id,
            evaluation_instructions: questionLevelEvaluation?.instructions,
            scoring_criteria: createQuestionForm.scoringCriteria
        });

        try {
            const res = await fetch(getApiUrl('/api/interview-questions/create-interview-questions'), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    client_user_id: clientUser.id,
                    interview_id: interview.id,
                    interview_name: interview.interview_name,
                    questions_data: Object.values(gptResponse),
                    question_set: createQuestionForm.questionSet,
                    question_type: createQuestionForm.questionType,    
                    question_start_index: questionStartIndex,
                    evaluation_category_id: questionLevelEvaluation?.evaluation_category_id,
                    evaluation_instructions: questionLevelEvaluation?.instructions,
                    context_text: interviewInstructions.context_video_text, 
                })
            });
            if(res.ok) {
                setLoading(true);
                setLoaderMessage('Questions added to queue for processing. ');
                setTimeout(() => {
                    navigate(-1);
                }, 3000);
            }
        } catch(error) {
            console.log(error);
        }
    }

    return (
        <div className="create-questions-container">
            { loading && (
                <div className="loader-container">
                    <div className="loader"></div>
                    <div className="loader-message">{loaderMessage}...</div>
                </div>
            )}

            <header className="create-questions-navbar">
                <div className="poppins-regular">
                    <div>
                        <Link to="/" className="logo" style={{ textDecoration: 'none', marginBottom: '0px', marginTop: '10px' }}>
                            <span className="logo"><span className="logo-peh">Peh</span><span className="logo-chaan">chaan</span></span>
                        </Link>
                    </div>
                    <div>
                        <Link to={`/create-job/${jobId}/interviews`} className="interviews">
                            Interviews
                        </Link>
                        &gt;{" "}
                        Questions
                    </div>
                </div>
                <div className='poppins-regular'>
                    <h4>Welcome, </h4>
                    <h3>{clientUser.user_name}</h3> {/* Display user name from context */}
                </div>
            </header>

            <main className="create-questions-main">
                <div className="poppins-regular">
                    <h2>Interview Name: { interview && interview.interview_name }</h2>
                    <p id='tag'>{ interview && interview.status }</p>
                </div>
            </main>

            <div className='questions-list-container'>
                <h3 className='poppins-bold'>Interview Questions</h3>
                <div className='question-set-select poppins-semibold'>
                    {
                        questions && Object.keys(questions).map(set => {
                            return (<span key={set} className={`question-set-option ${questionSetToDisplay == set ? 'selected' : ''}`} onClick={() => setQuestionSetToDisplay(parseInt(set))}>Set { set }</span>)
                        })
                    }
                </div>
                { (questions && questions[questionSetToDisplay] && questions[questionSetToDisplay].length !== 0) ? (
                    <ul className="questions-list poppins-regular">
                        { questions[questionSetToDisplay].sort((a, b) => a.question_order - b.question_order).map(question => (
                            <li key={question.id}>
                                <div className='left'>
                                    <p><strong>Question: {question.question_order}</strong> {question.question.question_text}</p>
                                    <p><strong>Instructions:</strong> {question.question.text_instructions}</p>
                                </div>
                                <div className='right iframe-video'>
                                    {question.question.media_url && (
                                        <ReactPlayer
                                            key={question.question_id}
                                            url={question.question.media_url}
                                            playing={false}
                                            controls={true}
                                            width="100%"
                                            height="100%"
                                            // className="iframe-video"
                                            className="react-player"
                                            muted={false}
                                        />
                                    )}
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>There are no questions for this interview</p>
                )}

            </div>

        </div>
    );
};

export default CreateQuestions;
