import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import { getApiUrl } from '../utils/apiUtils';
import TemplateModal from './TemplateModal';
import BrandingModal from './BrandingModal';
import { RiSettingsLine } from "react-icons/ri";
import './ClientEmailTemplates.css';
import { Box, Button, Heading, Text } from '@chakra-ui/react';

const Header = ({ jobId, onBrandingClick }) => {
    return (
        <header className="header">
            <div className="headerLeft">
                <Link to="/" className="logo">
                    <span className="logoPeh">Peh</span>
                    <span className="logoChaan">chaan</span>
                </Link>
                <div className="breadcrumb">
                    <Link to="/client-dashboard">Job Listings</Link> &gt;{" "}
                    <Link to={`/client-jobs/${jobId}`}>Job Details</Link> &gt;{" "}
                    <span>Email Templates</span>
                </div>
            </div>
            <Button
                visual='secondary'
                // className="brandingButton"
                onClick={onBrandingClick}>
                <RiSettingsLine size={20} />
                Manage Branding
            </Button>
        </header>
    );
};

const SearchBar = ({ value, onChange }) => {
    return (
        <div className="searchContainer">
            <input
                type="text"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder="Search templates by display name..."
                className="searchInput"
            />
            <svg className="searchIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
        </div>
    );
};

const TemplateList = ({ title, templates, onTemplateAction, actionType }) => {
    return (
        <div className="templateList">
            <Heading size='2xl' className="sectionTitle">{title}</Heading>
            <div className="listHeader">
                <div>Use Case</div>
                <div>Last Modified</div>
                <div>Actions</div>
            </div>
            {templates.map(template => (
                <div key={template.id} className="templateItem">
                    <div>{template.displayName ? template.displayName : template.useCase?.replace(/_/g, ' ')}</div>
                    <div>{new Date(template.updatedAt).toLocaleDateString()}</div>
                    <div className="actionButtons">
                        {actionType === 'default' ? (
                            <Button
                                onClick={() => onTemplateAction(template, true)}
                                visual='seconary'
                            >
                                View Default
                            </Button>
                        ) : (
                            <>
                                <Button
                                    onClick={() => onTemplateAction(template, true)}
                                    visual='secondary'
                                >
                                    View Default
                                </Button>
                                <Button
                                    visual='secondary'
                                    onClick={() => onTemplateAction(template, false, !template.hasCustom)}
                                // className="actionButton"
                                >
                                    {template.hasCustom ? 'Edit Template' : 'Create Template'}
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

const ClientEmailTemplates = () => {
    const { jobId } = useParams();
    const navigate = useNavigate();
    const [clientUser, setClientUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [clientTemplates, setClientTemplates] = useState([]);
    const [defaultTemplates, setDefaultTemplates] = useState([]);
    const [useCases, setUseCases] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [clientBranding, setClientBranding] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [isBrandingModalOpen, setIsBrandingModalOpen] = useState(false);

    useEffect(() => {
        const user = localStorage.getItem('clientUser');
        if (!user) {
            setLoading(false);
            return;
        }
        const parsedUser = JSON.parse(user);
        setClientUser(parsedUser);
        fetchData(parsedUser);
    }, [jobId]);


    const handleBrandingUpdate = async (brandingDetails) => {
        try {
            setLoading(true);
            console.log("Inside the handleBrandingUpdate function with brandingDetails : ", brandingDetails);
            const brandingData = {
                clientId: clientUser.client_id,
                ...brandingDetails
            }

            await updateClientBranding(brandingData);
            await updateAllTemplates(clientTemplates, brandingDetails, jobId);
            // creating templates for which custom templates do not exist with branding details
            const templatesToCreate = defaultTemplates.filter(template => !clientTemplates.some(ct => ct.notificationTemplate.notification_use_case_id === template.notification_use_case_id));
            console.log("Templates to create : ", templatesToCreate);
            const createPromises = templatesToCreate.map(async (template) => {
                const tempObj = {
                    ...template,
                    notificationTemplate: {
                        emailTemplate: template.emailTemplate,
                        notification_use_case_id: template.notification_use_case_id
                    }
                }
                const updatedTemplate = updateTemplateWithBranding(tempObj, brandingDetails);
                try {
                    const response = await fetch(getApiUrl(`/api/notification-templates`), {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            emailTemplate: updatedTemplate.notificationTemplate.emailTemplate,
                            notification_channel: "Email",
                            notification_use_case_id: template.notification_use_case_id,
                            isDefault: false
                        })
                    });
                    const data = await response.json();
                    if (!response.ok) {
                        throw new Error('Failed to create template');
                    }
                    else {
                        // link the template to the job 
                        const clientJobNotificationResponse = await fetch(getApiUrl(`/api/client-job-notifications`), {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                clientJobId: jobId,
                                notificationId: data.id
                            })
                        });
                        if (!clientJobNotificationResponse.ok) {
                            throw new Error('Failed to save template');
                        }

                    }
                    return data;
                } catch (error) {
                    console.error(`Error creating template ${template.notification_use_case_id}:`, error);
                    throw error;
                }
            });
            await Promise.all(createPromises);

            await fetchData(clientUser); // Refresh templates after update
            setIsBrandingModalOpen(false);
        } catch (error) {
            setError('Failed to update branding across templates');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const updateClientBranding = async (brandingData) => {
        try {
            console.log("Inside the updateClientBranding function with brandingData : ", brandingData);
            const clientId = brandingData.clientId;
            const response = await fetch(getApiUrl(`/api/client-branding-details/client/${clientId}`), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(brandingData)
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error('Failed to update client branding details');
            }
            return data;
        }
        catch (error) {
            console.error('Error updating client branding:', error);
            throw new Error('Failed to update client branding details');
        }
    }
    const handleSaveTemplate = async (template) => {
        console.log("Saving request received with template inside ClientEmailTemplate: ", template);
        try {
            if (template.isNew) {
                console.log("Client wants to create a new custom template");
                const response = await fetch(getApiUrl(`/api/notification-templates`), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        emailTemplate: template.notificationTemplate.emailTemplate,
                        "notification_channel": "Email",
                        notification_use_case_id: template.notificationTemplate.notification_use_case_id,
                        isDefault: false
                    })
                });
                if (!response.ok) {
                    setIsModalOpen(false);
                    throw new Error('Failed to save template');
                }
                else {
                    // link the template to the job 
                    const responseJson = await response.json();
                    const clientJobNotificationResponse = await fetch(getApiUrl(`/api/client-job-notifications`), {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            clientJobId: jobId,
                            notificationId: responseJson.id
                        })
                    });
                    if (!clientJobNotificationResponse.ok) {
                        setIsModalOpen(false);
                        throw new Error('Failed to save template');
                    }
                    else {
                        fetchData(clientUser); // Refresh templates
                        setIsModalOpen(false);
                        setIsEditing(false);
                        return;
                    }

                }
            }
            else {
                console.log("Client wants to update an existing custom template with id : ", template.id);
                const response = await fetch(getApiUrl(`/api/notification-templates/${template.id}`), {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        emailTemplate: template.notificationTemplate.emailTemplate,
                        notification_channel: "Email",
                        notification_use_case_id: template.notificationTemplate.notification_use_case_id,
                        isDefault: false
                    })
                });
                if (!response.ok) {
                    setIsModalOpen(false);
                    throw new Error('Failed to save template');
                }
                else {
                    console.log("Template updated successfully");
                    fetchData(clientUser); // Refresh templates
                    setIsModalOpen(false);
                    setIsEditing(false);
                    return;
                }
            }
        } catch (error) {
            console.error('Error saving template:', error);
            setError('Failed to save template');
        }
    };

    const extractBrandingDetails = (template) => {
        if (!template) return undefined;

        const parser = new DOMParser();
        const doc = parser.parseFromString(template.notificationTemplate.emailTemplate, 'text/html');

        const headerTd = doc.querySelector('td.email-brand-tableData');
        const brandElement = doc.querySelector('.email-brand-logo');
        const brandLink = doc.querySelector('.email-brand-link');
        const button = doc.querySelector('a.email-button-link');
        const signatureSpan = doc.querySelector('span.sender-signature');

        const hasImage = brandElement?.querySelector('img');

        return {
            brandingType: hasImage ? 'image' : 'text',
            brandImage: hasImage?.src || '',
            brandText: !hasImage ? brandElement?.textContent || 'Pehchaan' : '',
            brandColor: brandElement?.style.color || '#FFD700',
            brandBgColor: headerTd?.style.backgroundColor || '#000000',
            brandLink: brandLink?.href || 'https://www.pehchaaan.me',
            buttonColor: button?.style.backgroundColor || '#EDC500',
            buttonText: button?.textContent || 'View Details',
            buttonLink: button?.href || 'https://www.pehchaaan.me/jobs',
            signature: signatureSpan?.textContent || ''
        };
    };


    const updateAllTemplates = async (templates, brandingDetails, jobId) => {
        const updatePromises = templates.map(async (template) => {
            const updatedTemplate = updateTemplateWithBranding(template, brandingDetails);
            console.log("Request for update all");
            // console.log("Received template : ", template);
            // console.log("for notification use case id : ", template.notificationTemplate.notification_use_case_id);
            // console.log("Updated template : ", updatedTemplate);
            try {
                const response = await fetch(getApiUrl(`/api/notification-templates/${template.notificationTemplate.id}`), {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        emailTemplate: updatedTemplate.notificationTemplate.emailTemplate,
                        notification_channel: "Email",
                        notification_use_case_id: template.notificationTemplate.notification_use_case_id
                    })
                });

                if (!response.ok) {
                    throw new Error(`Failed to update template ${template.id}`);
                }

                return await response.json();
            } catch (error) {
                console.error(`Error updating template ${template.id}:`, error);
                throw error;
            }
        });

        return Promise.all(updatePromises);
    };

    const updateTemplateWithBranding = (template, branding) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(template.notificationTemplate.emailTemplate, 'text/html');

        // Update brand header
        const headerTd = doc.querySelector('td.email-brand-tableData');
        if (headerTd) {
            headerTd.style.backgroundColor = branding.brandBgColor;
        }

        const brandLink = doc.querySelector('.email-brand-link');
        if (brandLink) {
            brandLink.href = branding.brandLink;
        }

        const brandElement = doc.querySelector('.email-brand-logo');
        if (brandElement) {
            if (branding.brandingType === 'image') {
                brandElement.innerHTML = `<img src="${branding.brandImage}" alt="Brand Logo" style="max-height: 50px;" />`;
            } else {
                brandElement.innerHTML = branding.brandText;
                brandElement.style.color = branding.brandColor;
            }
        }

        // Update button
        // const button = doc.querySelector('a.email-button-link');
        // if (button) {
        //     button.href = branding.buttonLink;
        //     button.textContent = branding.buttonText;
        //     button.style.backgroundColor = branding.buttonColor;
        // }

        // Update signature
        const signatureSpan = doc.querySelector('span.sender-signature');
        if (signatureSpan) {
            signatureSpan.textContent = branding.signature;
        }

        // return {
        //     ...template,
        //     emailTemplate: doc.documentElement.outerHTML
        // };
        return {
            ...template,
            notificationTemplate: {
                ...template.notificationTemplate,
                emailTemplate: doc.documentElement.outerHTML
            }
        }
    };

    const fetchData = async (parsedUser) => {
        try {
            const [clientResponse, defaultResponse, useCasesResponse, clientBrandingResponse] = await Promise.all([
                fetch(getApiUrl(`/api/client-job-notifications/job/${jobId}`)),
                fetch(getApiUrl('/api/notification-templates-default')),
                fetch(getApiUrl('/api/notification-use-cases')),
                fetch(getApiUrl(`/api/client-branding-details/client/${parsedUser.client_id}`))
            ]);

            if (!clientResponse.ok || !defaultResponse.ok || !useCasesResponse.ok || !clientBrandingResponse.ok) {
                throw new Error('Failed to fetch templates');
            }

            const [clientData, defaultData, useCasesData, clientBrandingData] = await Promise.all([
                clientResponse.json(),
                defaultResponse.json(),
                useCasesResponse.json(),
                clientBrandingResponse.json()
            ]);

            setClientTemplates(clientData);
            setDefaultTemplates(defaultData);
            setUseCases(useCasesData);
            if (!clientBrandingData.found) {
                setClientBranding({
                    brandingType: 'text',
                    brandImage: '',
                    brandText: 'Pehchaan',
                    brandColor: '#FFD700',
                    brandBgColor: '#000000',
                    brandLink: 'https://www.pehchaaan.me',
                    signature: 'The Pehchaan Team'
                })
            }
            else {
                console.log("Client branding data : ", clientBrandingData);
                setClientBranding(clientBrandingData.brandingDetail);
            }
        } catch (error) {
            setError('Failed to load templates');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const processedTemplates = defaultTemplates.map(template => {
        const useCase = useCases.find(uc => uc.id === template.notification_use_case_id);
        const customTemplate = clientTemplates.find(
            ct => ct.notificationTemplate.notification_use_case_id === template.notification_use_case_id
        );

        return {
            id: template.id,
            useCase: useCase?.use_case,
            displayName: useCase?.displayName,
            updatedAt: template.updatedAt,
            hasCustom: !!customTemplate,
            defaultTemplate: template,
            customTemplate: customTemplate?.notificationTemplate
        };
    }).filter(template =>
    // template.useCase?.toLowerCase().includes(searchQuery.toLowerCase())
    {
        if (!template.useCase) return false;

        // Normalize search query and use case for comparison
        const normalizedSearchQuery = searchQuery.trim().toLowerCase();
        const normalizedUseCase = template.displayName ? template.displayName.toLowerCase() : template.useCase.toLowerCase();

        return normalizedUseCase.includes(normalizedSearchQuery);
    }
    );

    const handleTemplateAction = (template, isViewingDefault, isNew = false) => {
        console.log("inside the handleTemplateAction ", template);
        const adjustedObj = {
            ...template,
            notificationTemplate: {
                ...template.notificationTemplate,
                emailTemplate: template.defaultTemplate.emailTemplate
            }
        }
        const changedDefaultTemplateWithBranding = updateTemplateWithBranding(adjustedObj, clientBranding);
        console.log("Changed default template with branding : ", changedDefaultTemplateWithBranding);
        const tempObj = {
            ...changedDefaultTemplateWithBranding,
            emailTemplate: changedDefaultTemplateWithBranding.notificationTemplate.emailTemplate,
            notification_use_case_id: changedDefaultTemplateWithBranding.defaultTemplate.notification_use_case_id
        }
        const templateToUse = isViewingDefault ? template.defaultTemplate :
            (template.customTemplate || tempObj);

        if (isNew) {
            templateToUse.isNew = true;
        }
        else {
            templateToUse.isNew = false;
        }
        setSelectedTemplate({
            id: templateToUse.id,
            isNew: templateToUse.isNew,
            notificationTemplate: {
                emailTemplate: templateToUse.emailTemplate,
                notification_use_case_id: templateToUse.notification_use_case_id
            },
            isDefault: isViewingDefault
        });
        setIsModalOpen(true);
        setIsEditing(!isViewingDefault);
    };

    if (loading) {
        return (
            <div className="client-email-loader">
                <div className="client-email-spinner"></div>
                <Text>Loading Templates, Please wait ...</Text>
            </div>
        );
    }

    if (!clientUser) {
        return (
            <div className="client-email-container">
                <div className="client-email-content">
                    <h2>Please login to view and edit email templates</h2>
                    <Button visual='secondary' onClick={() => navigate('/client-login')}>
                        Login
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className="client-email-container">
            <Header
                jobId={jobId}
                onBrandingClick={() => setIsBrandingModalOpen(true)}
            />

            <div className="client-email-content">
                <SearchBar
                    value={searchQuery}
                    onChange={setSearchQuery}
                />

                {error && <div className="error">{error}</div>}

                <TemplateList
                    title="Email Templates"
                    templates={processedTemplates}
                    onTemplateAction={handleTemplateAction}
                    actionType="custom"
                />
            </div>

            {isModalOpen && selectedTemplate && (
                <TemplateModal
                    template={selectedTemplate}
                    isEditing={isEditing}
                    onClose={() => {
                        setIsModalOpen(false);
                        setIsEditing(false);
                    }}
                    onSave={handleSaveTemplate}
                    onEdit={() => setIsEditing(true)}
                />
            )}

            {isBrandingModalOpen && (
                <BrandingModal
                    onClose={() => setIsBrandingModalOpen(false)}
                    onSave={handleBrandingUpdate}
                    initialBranding={
                        clientBranding
                    }
                />
            )}
        </div>
    );
};

export default ClientEmailTemplates;