import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getApiUrl } from "../utils/apiUtils";
import "./JobDetails.css";
import ReactPlayer from "react-player";
import ProgressBar from "./ProgressBar";
import { ClientUserContext } from "../context/ClientUserContext";
import {
  Text,
  List,
  Center,
  Heading,
  Separator,
  Button,
  Bleed,
  Box,
  Flex,
} from "@chakra-ui/react";

const JobDetails = () => {
  const { jobId } = useParams();
  const [job, setJob] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [interviews, setInterviews] = useState([]);
  const [selectedInterview, setSelectedInterview] = useState(null);
  const [expandedInterviewId, setExpandedInterviewId] = useState(null); // For toggling interview details
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { clientUser } = useContext(ClientUserContext); // Fetch client user from context
  const navigate = useNavigate();
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [activeTab, setActiveTab] = useState("description");
  // For editing the screening requirements in the settings tab
  const [screeningRequirement, setScreeningRequirement] = useState({}); // Screening requirement object - data from API
  const [isEditing, setIsEditing] = useState(false); // Local state for editing mode
  const [processLoading, setProcessLoading] = useState(false); // Loading state for saving process

  async function handleEditSettingsSave() {
    // Save the updated screening requirements
    console.log("Saving updated screening requirements:", screeningRequirement);
    try {
      setProcessLoading(true);
      const response = await fetch(
        getApiUrl(
          `/api/client-job-screening-requirements/${screeningRequirement.id}`
        ),
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            cvUploadRequired: screeningRequirement.cvUploadRequired,
            cvScreeningInstructions:
              screeningRequirement.cvScreeningInstructions,
          }),
        }
      );
      if (response.ok) {
        console.log("Screening requirements updated successfully");
        setIsEditing(false);
      } else {
        throw new Error("Failed to update screening requirements");
      }
      setProcessLoading(false);
    } catch (error) {
      console.error("Error updating screening requirements:", error);
      setError("Failed to update screening requirements.");
      setProcessLoading(false);
    }
  }
  // Fetch Job and Interviews when component mounts
  useEffect(() => {
    if (!clientUser || !clientUser.client_id) {
      // If client user context or client_id is missing, redirect to login
      console.error("Client user not available, redirecting to login.");
      navigate("/client-login");
      return;
    }
    const fetchJobDetails = async () => {
      try {
        // Fetch Job details
        const jobResponse = await fetch(getApiUrl(`/api/client-jobs/${jobId}`));
        if (!jobResponse.ok) throw new Error("Failed to fetch job details");
        const jobData = await jobResponse.json();
        console.log(jobData);
        setJob(jobData);
        // Fetch Interviews linked to this Job
        const interviewsResponse = await fetch(
          getApiUrl(`/api/client-jobs/${jobId}/interviews`)
        );
        if (!interviewsResponse.ok)
          throw new Error("Failed to fetch interviews");
        const interviewsData = await interviewsResponse.json();
        // Sort interviews by order and determine availability
        const sortedInterviews = interviewsData.sort(
          (a, b) => a.client_job_interview_order - b.client_job_interview_order
        );
        setInterviews(sortedInterviews);
        // Fetch the screening requirement for this job
        const screeningRequirementResponse = await fetch(
          getApiUrl(`/api/client-job-screening-requirements/job/${jobId}`)
        );
        if (screeningRequirementResponse.ok) {
          const screeningRequirementData =
            await screeningRequirementResponse.json();
          console.log(screeningRequirementData);
          setScreeningRequirement(screeningRequirementData);
        }
      } catch (error) {
        console.error("Error fetching job/interviews:", error);
        setError(
          "Failed to load job details / interviews / screening requirements."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [jobId, clientUser, navigate]);

  // Fetch Interview details when drilling down
  const handleInterviewClick = async (clientJobInterviewId) => {
    try {
      const interviewResponse = await fetch(
        getApiUrl(
          `/api/interviews/client-job-interview/${clientJobInterviewId}/details`
        )
      );
      if (!interviewResponse.ok)
        throw new Error("Failed to fetch interview details");
      const interview = await interviewResponse.json();
      console.log(interview.interviewData);
      setSelectedInterview(interview.interviewData);
    } catch (error) {
      console.error("Error fetching interview details:", error);
      setError("Failed to load interview details.");
    }
  };

  const toggleInterview = (interviewId, event, isToggleButton) => {
    // Only toggle if clicking the toggle button
    if (isToggleButton) {
      event.stopPropagation(); // Prevent event bubbling
      setExpandedInterviewId(
        interviewId === expandedInterviewId ? null : interviewId
      );
    }
  };

  // Function to toggle the visibility of the full job description
  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "description":
        return (
          <div className="tab-content">
            <Text style={{ whiteSpace: "pre-line", lineHeight: "2" }}>
              {showFullDescription
                ? job?.job_description.trim()
                : `${job?.job_description.substring(0, 150)}...`}
            </Text>
            <Button visual="secondary" onClick={toggleDescription}>
              {showFullDescription ? "Read Less" : "Read More"}
            </Button>
          </div>
        );
      case "applicants":
        return (
          // <div className="tab-content">
          <Flex gap="6" px="10" py="10" minHeight="40vh">
            {/* <Link to={`/client-jobs/${job.id}/candidates-list`} className="tab-link">
                                View All Applicants
                            </Link> */}
            <Link
              to={`/client-jobs/${job.id}/candidates-list`}
              style={{ textDecoration: "none" }}
            >
              <Button visual="secondary">View All Applicants</Button>
            </Link>
            <Link
              to={`/invite-user/job/${job.id}`}
              style={{ textDecoration: "none" }}
            >
              <Button visual="secondary">Invite New Applicants</Button>
            </Link>
            {/* <Link to={`/invite-user/job/${job.id}`} className="tab-link">
                                Invite New Applicants
                            </Link> */}
          </Flex>
          // </div>
        );
      case "settings":
        return (
          <div className="tab-content">
            <Heading as="h3">Job Settings</Heading>
            {/* <Box bg='{colors.backgroundSecondary}' borderRadius='{radii.containerRadius}' padding='4' mt='4'>
                            <h4>CV Requirements</h4>
                            <div className="setting-item poppins-regular">
                                <label className="checkbox-label">
                                    <input
                                        type="checkbox"
                                        checked={screeningRequirement?.cvUploadRequired}
                                        onChange={() => isEditing && setScreeningRequirement(prev => ({ ...prev, cvUploadRequired: !prev.cvUploadRequired }))}
                                        disabled={!isEditing}
                                    />
                                    Require CV Upload
                                </label>
                            </div>
                            <div className="screening-instructions">
                                <h4>CV Screening Instructions</h4>
                                {isEditing ? (
                                    <textarea
                                        value={screeningRequirement?.cvScreeningInstructions}
                                        onChange={(e) => setScreeningRequirement(prev => ({ ...prev, cvScreeningInstructions: e.target.value }))}
                                        className="instructions-textarea poppins-regular"
                                        placeholder="Enter CV screening instructions..."
                                    />
                                ) : (
                                    <pre className="instructions-display poppins-regular">{screeningRequirement?.cvScreeningInstructions || 'No instructions set'}</pre>
                                )}
                            </div>

                            <div className="settings-actions">
                                {isEditing ? (
                                    <>
                                        <button className="save-button poppins-medium" onClick={handleEditSettingsSave}>Save Changes</button>
                                        <button className="cancel-button poppins-medium" onClick={() => setIsEditing(false)}>Cancel</button>
                                    </>
                                ) : (
                                    <button className="edit-button poppins-medium" onClick={() => setIsEditing(true)}>Edit Requirements</button>
                                )}
                            </div>
                        </Box> */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              bg="{colors.backgroundSecondary}"
              borderRadius="{radii.containerRadius}"
              px="4"
              py="2"
              mt="4"
            >
              <Text>Email Settings</Text>
              <Button
                visual="secondary"
                onClick={() => navigate(`/client-jobs/${jobId}/settings/email`)}
              >
                Edit Email Templates
              </Button>
            </Box>
          </div>
        );
      case "interviews":
        return (
          <>
            {/* <h2>Interviews</h2> */}
            <List.Root as="ul" px="6" py="6" className="interviews-list">
              {interviews.map((interview) => (
                <List.Item
                  width="100%"
                  marginInline="auto"
                  px="4"
                  py="2"
                  key={interview.client_job_interview_id}
                  onClick={() => {
                    toggleInterview(interview.client_job_interview_id);
                    handleInterviewClick(interview.client_job_interview_id);
                  }}
                >
                  <div className="interview-name-and-toggle">
                    <span className="poppins-medium">
                      {interview.interview.interview_name}
                    </span>
                    <Button
                      visual="secondary"
                      onClick={(e) => {
                        toggleInterview(
                          interview.client_job_interview_id,
                          e,
                          true
                        );
                        handleInterviewClick(interview.client_job_interview_id);
                      }}
                    >
                      {expandedInterviewId === interview.client_job_interview_id
                        ? "Hide Details"
                        : "View Details"}
                    </Button>
                  </div>
                  {/* Drill down into Interview details */}
                  {expandedInterviewId ===
                    interview.client_job_interview_id && (
                    <div className="interview-details poppins-regular">
                      <Flex gap="10">
                        <div>
                          <span className="tag">
                            <strong>Time Limit:</strong>{" "}
                            {selectedInterview?.interview?.interview_time_limit}{" "}
                            seconds
                          </span>{" "}
                          {/* Display created date */}
                        </div>
                        <div>
                          <span className="tag">
                            <strong>Time Limit per Answer:</strong>{" "}
                            {
                              selectedInterview?.interview
                                ?.time_limit_per_answer
                            }{" "}
                            seconds
                          </span>{" "}
                          {/* Display created date */}
                        </div>
                        <div>
                          <span className="tag">
                            <strong>Status:</strong>{" "}
                            {selectedInterview?.interview?.status}
                          </span>{" "}
                          {/* Display created date */}
                        </div>
                      </Flex>

                      {/* <p><strong>Time Limit:</strong> {selectedInterview?.interview?.interview_time_limit} seconds</p>
                                <p><strong>Time Limit per Answer:</strong> {selectedInterview?.interview?.time_limit_per_answer} seconds</p>
                                <p><strong>Status:</strong> {selectedInterview?.interview?.status}</p> */}
                      <Box mt="6" spaceY="8">
                        <Box>
                          <Heading as="h4">Pre-Interview Instructions</Heading>
                        </Box>
                        <Box>
                          <Heading as="h5" mt="2">
                            Instructions
                          </Heading>
                          <Text>
                            {selectedInterview?.interview?.instructions
                              ?.pre_interview_instructions ||
                              "No specific instructions"}
                          </Text>
                        </Box>
                        <Box>
                          <Heading as="h5" mt="2">
                            Welcome Message
                          </Heading>
                          <Text>
                            {selectedInterview?.interview?.instructions
                              ?.welcome_message || "No welcome message"}
                          </Text>
                        </Box>

                        {/* Conditionally render the welcome video iframe */}
                        {selectedInterview?.interview?.instructions
                          ?.welcome_video_url && (
                          <div>
                            <h5>Welcome Video</h5>
                            {/* <iframe
                                                            src={selectedInterview.interview?.instructions.welcome_video_url}
                                                            frameBorder="0"
                                                            allow="autoplay; fullscreen"
                                                            allowFullScreen
                                                            title="Welcome Video"
                                                            className="iframe-video"
                                                        /> */}
                            <div className="iframe-video">
                              <Center width="fit-content" height="100%">
                                <ReactPlayer
                                  key={"welcome-video"}
                                  url={
                                    selectedInterview.interview?.instructions
                                      .welcome_video_url
                                  }
                                  playing={false}
                                  controls={true}
                                  width="100%"
                                  height="100%"
                                  muted={false}
                                />
                              </Center>
                            </div>
                          </div>
                        )}

                        {/* Conditionally render the context video iframe */}
                        {selectedInterview?.interview?.instructions
                          ?.context_video_url && (
                          <div>
                            <h5>Context Video</h5>
                            {/* <iframe
                                                            src={selectedInterview.interview?.instructions.context_video_url}
                                                            frameBorder="0"
                                                            allow="autoplay; fullscreen"
                                                            allowFullScreen
                                                            title="Context Video"
                                                            className="iframe-video"
                                                        /> */}
                            <Center width="fit-content" height="100%">
                              <ReactPlayer
                                key={"context-video"}
                                url={
                                  selectedInterview.interview?.instructions
                                    .context_video_url
                                }
                                playing={false}
                                controls={true}
                                width="100%"
                                height="100%"
                                className="iframe-video"
                                muted={false}
                              />
                            </Center>
                          </div>
                        )}
                      </Box>

                      <Heading as="h4" mt="4">
                        Interview Questions
                      </Heading>
                      <ul className="questions-list poppins-regular">
                        {selectedInterview?.interview?.interview_questions
                          ?.sort((a, b) => a.question_order - b.question_order) // Sort questions by question_order
                          .map((question) => (
                            <li key={question.id}>
                              <div className="left">
                                <Text>
                                  <strong>
                                    Question {question.question_order}:
                                  </strong>{" "}
                                  {question.question.question_text}
                                </Text>
                                <Text>
                                  <strong>Instructions:</strong>{" "}
                                  {question.question.text_instructions}
                                </Text>
                                <Text>
                                  <strong>Question Set:</strong>{" "}
                                  {question.question_set}
                                </Text>
                              </div>
                              <div className="right">
                                {question.question.question_type ===
                                  "Video" && (
                                  // <iframe
                                  //     src={question.question.media_url}
                                  //     frameBorder="0"
                                  //     allow="autoplay; fullscreen"
                                  //     allowFullScreen
                                  //     title={`Media for Question ${question.question_order}`}
                                  //     className="iframe-video"
                                  // />

                                  <div className="iframe-video">
                                    <Center width="fit-content" height="100%">
                                      <ReactPlayer
                                        key={question.question_id}
                                        url={question.question.media_url}
                                        playing={false}
                                        controls={true}
                                        width="100%"
                                        height="100%"
                                        // className="iframe-video"
                                        className="react-player"
                                        muted={false}
                                      />
                                    </Center>
                                  </div>
                                )}
                                {question.question.question_type ===
                                  "Image" && (
                                  <img
                                    src={question.question.media_url}
                                    alt={`Media for Question ${question.question_order}`}
                                    className="iframe-video"
                                  />
                                )}
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                </List.Item>
              ))}
            </List.Root>
          </>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
        <p className="loader-text">Loading job details, please wait...</p>
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };

    return date.toLocaleDateString("en-GB", options);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(job.job_link);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000); // Reset copy message after 2 seconds
  };

  return (
    <>
      {processLoading && (
        <div className="loader-on-top">
          <div className="loader-spinner"></div>
        </div>
      )}
      <div className="job-details-container-outer">
        <header className="dashboard-navbar">
          <div className="left poppins-regular">
            <Link
              to="/"
              className="logo"
              style={{
                textDecoration: "none",
                marginBottom: "0px",
                marginTop: "10px",
              }}
            >
              <span className="logo">
                <span className="logo-peh">Peh</span>
                <span className="logo-chaan">chaan</span>
              </span>
            </Link>
          </div>
          <div className="poppins-regular">
            <Link to="/client-dashboard" className="job-listings-nav">
              Job Listings
            </Link>
            &gt; Job Details
          </div>
        </header>

        {/* <div className="job-title-container"> */}
        <Box display="flex" flexDir="row" gap="2" mt="4" alignItems="center">
          <Heading as="h2">{job?.job_title}</Heading>
          <div>
            {job?.job_link && (
              <Button visual="secondary" ml="2" onClick={handleCopy}>
                {isCopied ? "Copied!" : "Copy Job Link"}
              </Button>
            )}
          </div>
        </Box>
        {/* </div> */}
        {/* Showing the tabs */}
        <div className="job-content">
          <div className="job-header">
            {/* <h1>{job.job_title}</h1> */}
            <span className="date-tag poppins-medium">
              Published on: {formatDate(job.createdAt)}
            </span>
          </div>
          <div className="tabs-container">
            <div className="tabs">
              <button
                className={`tab ${activeTab === "description" ? "active" : ""}`}
                onClick={() => setActiveTab("description")}
              >
                Description
              </button>
              <button
                className={`tab ${activeTab === "interviews" ? "active" : ""}`}
                onClick={() => setActiveTab("interviews")}
              >
                Interviews
              </button>
              <button
                className={`tab ${activeTab === "settings" ? "active" : ""}`}
                onClick={() => setActiveTab("settings")}
              >
                Settings
              </button>
              <button
                className={`tab ${activeTab === "applicants" ? "active" : ""}`}
                onClick={() => setActiveTab("applicants")}
              >
                Applicants
              </button>
            </div>
            {renderTabContent()}
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDetails;
