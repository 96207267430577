import React from "react";
import { useNavigate } from "react-router-dom";
import "./ThankYouPage.css";
import { Button } from "@chakra-ui/react";

const ThankYouPage = () => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate("/");
  };

  return (
    <div className="thank-you-container">
      <h1>Thank You!</h1>
      <p>Your interview has been successfully completed.</p>
      <Button onClick={handleHomeClick}>Go to Home</Button>
    </div>
  );
};

export default ThankYouPage;
