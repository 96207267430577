export const addMaxScore = async (
  newInterviewFormData,
  questionLevelEvaluation,
  questionLevelEvalId,
  allEvaluationCategories,
  evaluationCriteria,
  linkData,
  getApiUrl
) => {
  // Calculate Max Score for question level criterias
  let totalScoreForQuesLevel = 0;
  if (
    questionLevelEvaluation?.[0]?.value?.name &&
    !questionLevelEvaluation[0].value?.name
      .toLowerCase()
      .includes('screening') &&
    !questionLevelEvaluation[0].value?.name.toLowerCase().includes('cheating')
  ) {
    const scoringCriteriaForQues =
      allEvaluationCategories[questionLevelEvalId].scoring_criteria;
    let criteriaArray = JSON.parse(scoringCriteriaForQues);
    let maxValue = Math.max(...criteriaArray);

    totalScoreForQuesLevel = maxValue * newInterviewFormData.questionsPerSet;
  }

  // Calculate Max Score for question level criterias
  const excludedIds = questionLevelEvaluation.map((item) => item.value.id);
  const filteredCriteria = evaluationCriteria.filter(
    (criteria) =>
      !excludedIds.includes(criteria.id) && // Exclude IDs from questionLevelEvaluation
      !criteria.name.toLowerCase().includes('screening') && // Name should not include 'screening'
      !criteria.name.toLowerCase().includes('cheating') // Name should not include 'cheating'
  );

  // Calculate the sum of the maximum elements from scoring_criteria
  const totalScoreForInterviewLevel = filteredCriteria.reduce(
    (sum, criteria) => {
      const scores = JSON.parse(criteria.scoring_criteria); // Parse the scoring_criteria string to array
      const maxScore = Math.max(...scores); // Find the maximum score in the array
      return sum + maxScore; // Add the maximum score to the total sum
    },
    0
  );

  const maximumScore = totalScoreForQuesLevel + totalScoreForInterviewLevel;
  console.log('Maximum Score for the Client Job Interview', maximumScore);

  // save max score for the client job interview
  const maxScoreResponse = await fetch(
    getApiUrl('/api/client-job-interview-max-score'),
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        client_job_interview_id: linkData.id,
        max_score: maximumScore,
      }),
    }
  );
  if (maxScoreResponse.ok)
    console.log(
      'Maximum Score added for the client job interview successfully'
    );
};
