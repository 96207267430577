import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./Homepage.css";
import landingPageHeroRight from "../assets/landing_page_hero_right.png";
import landingPagePatternRight from "../assets/landing_page_pattern_right.png";
import landingPagePatternTopLeft from "../assets/landing_page_pattern_top_left.png";
import Modal from "./Modal"; // Modal component for Contact Us
import Footer from "./Footer";
import {
  Box,
  Button,
  Card,
  Container,
  Flex,
  Heading,
  Text,
  Image,
} from "@chakra-ui/react";

const Homepage = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleSignupClick = () => {
    navigate("/signup");
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleClientLoginClick = () => {
    navigate("/client-login");
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <Container gap={{ base: "10", md: "20" }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        align={{ base: "center", md: "flex-start" }}
      >
        <Box>
          <Link to="/" className="logo poppins-regular">
            <span className="logo-peh">Peh</span>
            <span className="logo-chaan">chaan</span>
          </Link>
        </Box>
      </Flex>
      <Flex
        direction="column"
        align="center"
        padding={{ base: "5px 0", md: "10px 0" }}
        position="relative"
      >
        <Flex
          direction={{ base: "column", lg: "row" }}
          justify={{ base: "center", lg: "space-between" }} // Change 'space-around' to 'space-between'
          align={{ base: "center", lg: "flex-start" }}
          width="80%"
          position="relative"
          zIndex={2}
          gap={{ base: "3", md: "4" }} // Reduce the gap between items
        >
          <Card.Root
            orient="vertical"
            alignItems="flex-start"
            gap="2"
            zIndex="10"
            maxWidth={{ base: "100%", md: "350px" }}
            bg="white"
          >
            <Card.Title color="#222">Hire 10x Faster</Card.Title>
            <Card.Description color="#222">
              Pehchaan's AI Interviewer will turbo-charge your recruitment
              process.
            </Card.Description>
            <Button className="primary poppins-medium" onClick={toggleModal}>
              Contact Us
            </Button>
          </Card.Root>
          <Flex
            width={{ base: "100%", md: "45%" }}
            justify="center"
            align="center"
            position="relative"
            zIndex={0}
          >
            <Flex position="relative" width="100%" justify="center">
              <Image
                src={landingPageHeroRight}
                alt="Main Hero"
                width="100%"
                maxWidth="300px"
                borderRadius="80%"
                zIndex={-1}
                display={{ base: "none", lg: "block" }} // Hide on small screens, show on large screens
              />
              <Image
                src={landingPagePatternRight}
                alt="Pattern Right"
                position="absolute"
                bottom="-10px"
                // left="-10px" // Adjusted to shift the background pattern to the left
                width="125%"
                maxWidth="350px"
                zIndex={-2}
                display={{ base: "none", lg: "block" }} // Hide on small screens, show on large screens
              />
            </Flex>
          </Flex>

          <Flex
            direction="column"
            align="stretch"
            gap="2"
            maxWidth={{ base: "100%", md: "370px" }}
          >
            <Card.Root
              orient="vertical"
              alignItems="center"
              gap="6"
              zIndex="10"
            >
              <Card.Title>For Job Seekers</Card.Title>
              <Card.Description textAlign="center" width="85%">
                Find your dream job, prepare for interviews, and showcase your
                skills.
              </Card.Description>
              <Flex gap="2" flexWrap="wrap" justify="center">
                <Button visual="secondary" onClick={handleLoginClick}>
                  Candidate Log In
                </Button>
                <Button visual="secondary" onClick={handleSignupClick}>
                  Create Account
                </Button>
              </Flex>
            </Card.Root>

            <Card.Root
              orient="vertical"
              alignItems="center"
              gap="6"
              zIndex="10"
            >
              <Card.Title>For Recruiters</Card.Title>
              <Card.Description textAlign="center" width="85%">
                Post job openings, assess candidates and close your hiring 10x
                faster.
              </Card.Description>
              <Button visual="secondary" onClick={handleClientLoginClick}>
                Recruiter Log In
              </Button>
            </Card.Root>
          </Flex>
        </Flex>

        <Container mt={{ base: "10", md: "20" }} textAlign="center">
          <Heading as="h2" fontSize={{ base: "lg", md: "xl" }}>
            Coming Soon for Job Seekers
          </Heading>
          <Flex
            direction={{ base: "column", md: "row" }}
            justify="space-between"
            gap={{ base: "4", md: "6" }}
            marginTop="20px"
          >
            <Card.Root
              orient="vertical"
              gap="4"
              alignItems="center"
              padding="6"
            >
              <Card.Title>Interview Prep</Card.Title>
              <Card.Description>
                Prepare for Interviews with your AI mentor.
              </Card.Description>
            </Card.Root>
            <Card.Root
              orient="vertical"
              gap="4"
              alignItems="center"
              padding="6"
            >
              <Card.Title>Showcase Yourself</Card.Title>
              <Card.Description>
                Create a professional profile to showcase your skills.
              </Card.Description>
            </Card.Root>
            <Card.Root
              orient="vertical"
              gap="4"
              alignItems="center"
              padding="6"
            >
              <Card.Title>Knowledge Factory</Card.Title>
              <Card.Description>
                Learn from an AI Coach to boost your career.
              </Card.Description>
            </Card.Root>
          </Flex>
        </Container>

        <Footer />

        {modalOpen && (
          <Modal
            toggleModal={toggleModal}
            headerText="Contact Us"
            bodyText="Please reach out to us at: kunal@pehchaan.me"
            buttonText="Close"
          />
        )}
      </Flex>
    </Container>
  );
};

export default Homepage;
