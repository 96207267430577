   
const SKILLS_OPTIONS = {
  "skills": [
    { label: "JavaScript", value: "JavaScript" },
    { label: "Python", value: "Python" },
    { label: "Java", value: "Java" },
    { label: "C++", value: "C++" },
    { label: "React", value: "React" },
    { label: "Node.js", value: "Node.js" },
    { label: "SQL", value: "SQL" },
    { label: "Git", value: "Git" },
    { label: "Docker", value: "Docker" },
    { label: "Kubernetes", value: "Kubernetes" },
    { label: "Microservices", value: "Microservices" },
    { label: "Agile Development", value: "Agile Development" },
    { label: "REST APIs", value: "REST APIs" },
    { label: "R", value: "R" },
    { label: "Pandas", value: "Pandas" },
    { label: "NumPy", value: "NumPy" },
    { label: "Machine Learning", value: "Machine Learning" },
    { label: "Deep Learning", value: "Deep Learning" },
    { label: "Data Visualization", value: "Data Visualization" },
    { label: "TensorFlow", value: "TensorFlow" },
    { label: "PyTorch", value: "PyTorch" },
    { label: "Big Data", value: "Big Data" },
    { label: "Statistics", value: "Statistics" },
    { label: "Data Mining", value: "Data Mining" },
    { label: "Data Wrangling", value: "Data Wrangling" },
    { label: "Wireframing", value: "Wireframing" },
    { label: "Prototyping", value: "Prototyping" },
    { label: "User Research", value: "User Research" },
    { label: "Adobe XD", value: "Adobe XD" },
    { label: "Figma", value: "Figma" },
    { label: "Sketch", value: "Sketch" },
    { label: "Interaction Design", value: "Interaction Design" },
    { label: "Visual Design", value: "Visual Design" },
    { label: "Usability Testing", value: "Usability Testing" },
    { label: "Typography", value: "Typography" },
    { label: "Design Systems", value: "Design Systems" },
    { label: "Responsive Design", value: "Responsive Design" },
    { label: "SEO", value: "SEO" },
    { label: "Content Marketing", value: "Content Marketing" },
    { label: "Google Analytics", value: "Google Analytics" },
    { label: "Social Media Marketing", value: "Social Media Marketing" },
    { label: "Email Marketing", value: "Email Marketing" },
    { label: "PPC Campaigns", value: "PPC Campaigns" },
    { label: "Copywriting", value: "Copywriting" },
    { label: "Keyword Research", value: "Keyword Research" },
    { label: "Marketing Automation", value: "Marketing Automation" },
    { label: "Conversion Rate Optimization (CRO)", value: "Conversion Rate Optimization (CRO)" },
    { label: "Brand Management", value: "Brand Management" },
    { label: "AWS", value: "AWS" },
    { label: "Azure", value: "Azure" },
    { label: "Google Cloud", value: "Google Cloud" },
    { label: "CI/CD", value: "CI/CD" },
    { label: "Jenkins", value: "Jenkins" },
    { label: "Terraform", value: "Terraform" },
    { label: "Ansible", value: "Ansible" },
    { label: "Containerization", value: "Containerization" },
    { label: "Monitoring and Logging", value: "Monitoring and Logging" },
    { label: "Linux", value: "Linux" },
    { label: "Scripting", value: "Scripting" },
    { label: "Project Planning", value: "Project Planning" },
    { label: "Scrum", value: "Scrum" },
    { label: "Agile Methodologies", value: "Agile Methodologies" },
    { label: "Risk Management", value: "Risk Management" },
    { label: "Stakeholder Management", value: "Stakeholder Management" },
    { label: "Budgeting", value: "Budgeting" },
    { label: "Team Leadership", value: "Team Leadership" },
    { label: "Scheduling", value: "Scheduling" },
    { label: "Resource Allocation", value: "Resource Allocation" },
    { label: "JIRA", value: "JIRA" },
    { label: "Trello", value: "Trello" },
    { label: "Conflict Resolution", value: "Conflict Resolution" },
    { label: "Network Security", value: "Network Security" },
    { label: "Penetration Testing", value: "Penetration Testing" },
    { label: "Ethical Hacking", value: "Ethical Hacking" },
    { label: "Incident Response", value: "Incident Response" },
    { label: "Risk Assessment", value: "Risk Assessment" },
    { label: "Firewall Management", value: "Firewall Management" },
    { label: "Security Auditing", value: "Security Auditing" },
    { label: "Threat Detection", value: "Threat Detection" },
    { label: "Encryption", value: "Encryption" },
    { label: "Security Compliance", value: "Security Compliance" },
    { label: "SIEM Tools", value: "SIEM Tools" },
    { label: "SEO Writing", value: "SEO Writing" },
    { label: "Blogging", value: "Blogging" },
    { label: "Technical Writing", value: "Technical Writing" },
    { label: "Creative Writing", value: "Creative Writing" },
    { label: "Content Editing", value: "Content Editing" },
    { label: "Proofreading", value: "Proofreading" },
    { label: "Content Strategy", value: "Content Strategy" },
    { label: "Social Media Writing", value: "Social Media Writing" },
    { label: "Research", value: "Research" },
    { label: "Requirements Gathering", value: "Requirements Gathering" },
    { label: "Business Process Modeling", value: "Business Process Modeling" },
    { label: "UML", value: "UML" },
    { label: "Business Intelligence", value: "Business Intelligence" },
    { label: "Cost-Benefit Analysis", value: "Cost-Benefit Analysis" },
    { label: "Market Research", value: "Market Research" },
    { label: "Adobe Photoshop", value: "Adobe Photoshop" },
    { label: "Adobe Illustrator", value: "Adobe Illustrator" },
    { label: "CorelDRAW", value: "CorelDRAW" },
    { label: "Brand Identity", value: "Brand Identity" },
    { label: "Print Design", value: "Print Design" },
    { label: "Web Design", value: "Web Design" },
    { label: "Layout Design", value: "Layout Design" },
    { label: "Color Theory", value: "Color Theory" },
    { label: "Illustration", value: "Illustration" },
    { label: "Motion Graphics", value: "Motion Graphics" },
    { label: "3D Design", value: "3D Design" }
  ]
};

export default SKILLS_OPTIONS;

  