import React, { useState, useRef, useEffect } from 'react';
import Quill from 'quill';
import { EmailEditor } from './EmailEditor';
import { updateTemplate } from '../utils/updateTemplate';
import styles from './TemplateModal.module.css';
import { Box, Button, Heading, Text } from '@chakra-ui/react';

const Delta = Quill.import('delta');

const TemplateModal = ({ template, onClose, onSave }) => {
    const [isEditing, setIsEditing] = useState(false);
    // Branding information on top 
    const [brandingType, setBrandingType] = useState('text');
    const [brandImage, setBrandImage] = useState('');
    const [brandText, setBrandText] = useState('Pehchaan');
    const [brandColor, setBrandColor] = useState('#FFD700');
    const [brandBgColor, setBrandBgColor] = useState('#000000');
    const [brandLink, setBrandLink] = useState('https://www.pehchaaan.me');

    // Button inside the email template
    const [buttonColor, setButtonColor] = useState('#EDC500');
    const [buttonText, setButtonText] = useState('View Details');
    const [buttonLink, setButtonLink] = useState('https://www.pehchaaan.me/jobs');
    const [signature, setSignature] = useState('');

    // Quill editor reference
    const quillRef = useRef();
    const [htmlContent, setHtmlContent] = useState('');
    const [previewContent, setPreviewContent] = useState('');
    const [defaultDelta, setDefaultDelta] = useState(() => new Delta());
    const [readOnly, setReadOnly] = useState(false);

    useEffect(() => {
        if (template?.notificationTemplate?.emailTemplate) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(template.notificationTemplate.emailTemplate, 'text/html');

            // Extract branding information
            const headerTd = doc.querySelector('td.email-brand-tableData');
            const brandElementAnchorTag = headerTd?.querySelector('.email-brand-link');
            const brandElement = headerTd?.querySelector('.email-brand-logo');
            if (headerTd) {
                const bgColor = headerTd.style.backgroundColor;
                if (bgColor) {
                    const rgb = bgColor.match(/\d+/g);
                    if (rgb) {
                        const hex = `#${((1 << 24) + (parseInt(rgb[0]) << 16) + (parseInt(rgb[1]) << 8) + parseInt(rgb[2])).toString(16).slice(1).toUpperCase()}`;
                        setBrandBgColor(hex);
                    }
                }
            }

            if (brandElementAnchorTag) {
                setBrandLink(brandElementAnchorTag.href);
            }

            if (brandElement) {
                const img = brandElement.querySelector('img');
                if (img) {
                    setBrandingType('image');
                    setBrandImage(img.src);
                } else {
                    setBrandingType('text');
                    setBrandText(brandElement.innerText);
                    // const brandColorElement = brandElement.querySelector('h1.brand-email-logo');
                    if (brandElement?.style.color) {
                        const color = brandElement.style.color;
                        const rgb = color.match(/\d+/g);
                        if (rgb) {
                            const hex = `#${((1 << 24) + (parseInt(rgb[0]) << 16) + (parseInt(rgb[1]) << 8) + parseInt(rgb[2])).toString(16).slice(1).toUpperCase()}`;
                            setBrandColor(hex);
                        }
                    }
                }
            }

            // Extract button color
            const button = doc.querySelector('a.email-button-link');
            if (button?.style.backgroundColor) {
                const rgb = button.style.backgroundColor.match(/\d+/g);
                if (rgb) {
                    const hex = `#${((1 << 24) + (parseInt(rgb[0]) << 16) + (parseInt(rgb[1]) << 8) + parseInt(rgb[2])).toString(16).slice(1).toUpperCase()}`;
                    setButtonColor(hex);
                }
            }
            if (button) {
                setButtonText(button.innerText);
                setButtonLink(button.href);
            }

            // Extract signature 
            const signatureSpan = doc.querySelector('span.sender-signature');
            if (signatureSpan) {
                setSignature(signatureSpan.innerText);
            }
            // Extract email body content
            const emailBodyDiv = doc.querySelector('.email-body-all-para');
            if (emailBodyDiv) {
                const tempContainer = document.createElement('div');
                tempContainer.innerHTML = emailBodyDiv.innerHTML;
                console.log("tempContainer : \n", tempContainer);
                const tempQuill = new Quill(tempContainer);
                console.log("tempQuill get contents : \n", tempQuill.getContents());
                setDefaultDelta(tempQuill.getContents());
            }

            setHtmlContent(template.notificationTemplate.emailTemplate);
            setPreviewContent(template.notificationTemplate.emailTemplate);

            console.log("template that is loaded :  \n", template);
        }
    }, [template]);

    const handleTextChange = (delta, oldDelta, source) => {
        if (source === 'user' && quillRef.current) {
            const newContent = updateTemplate({
                html: quillRef.current.root.innerHTML,
                brandingType,
                brandImage,
                brandText,
                brandColor,
                brandBgColor,
                brandLink,
                buttonColor,
                buttonText,
                buttonLink,
                defaultHTML: htmlContent,
                signature
            });
            setHtmlContent(newContent);
            // setPreviewContent(newContent);  
        }
    };

    const refreshPreview = () => {
        if (quillRef.current) {
            console.log("quillRef.current.root.innerHTML : \n", quillRef.current.root.innerHTML);
            console.log("Order 2 ")
            const newContent = updateTemplate({
                html: quillRef.current.root.innerHTML,
                brandingType,
                brandImage,
                brandText,
                brandColor,
                brandBgColor,
                brandLink,
                buttonColor,
                buttonText,
                buttonLink,
                signature,
                defaultHTML: htmlContent
            });
            console.log("New content on refreshing Preview  : \n", newContent);
            console.log("Order 3")
            setHtmlContent(newContent);
            setPreviewContent(newContent);
            console.log("Order 4")
            return newContent;
        }
        return previewContent;
    };

    const handleSave = () => {
        console.log("RECEIVED REQUEST TO SAVE TEMPLATE");

        if (quillRef.current) {
            const newContent = updateTemplate({
                html: quillRef.current.root.innerHTML,
                brandingType,
                brandImage,
                brandText,
                brandColor,
                brandBgColor,
                brandLink,
                buttonColor,
                buttonText,
                buttonLink,
                signature,
                defaultHTML: htmlContent
            });

            console.log("on saving template : \n", newContent);

            onSave({
                ...template,
                notificationTemplate: {
                    ...template.notificationTemplate,
                    emailTemplate: newContent
                }
            });

            setHtmlContent(newContent); // Update state after saving
            setPreviewContent(newContent);
            setIsEditing(false);
        }
    };

    return (
        <div className={styles['modal-overlay']}>
            <div className={styles['modal-content']}>
                <div className={styles['modal-header']}>
                    <h2>Email Template Editor</h2>
                    <button className={styles['close-button']} onClick={onClose}>&times;</button>
                </div>

                <div className={styles['modal-body']}>
                    {template.isDefault || !isEditing ? (
                        <div className={styles['template-preview']}>
                            <iframe
                                title="Email Template Preview"
                                srcDoc={previewContent}
                                className={styles['preview-frame']}
                                sandbox="allow-same-origin"
                            />
                        </div>
                    ) : (
                        <div className={styles['editor-container']}>
                            <div className={styles['editor-controls']}>
                                <div className={styles['control-section']}>
                                    <h3>Branding</h3>
                                    <div className={styles['branding-controls']}>
                                        <select
                                            value={brandingType}
                                            onChange={(e) => setBrandingType(e.target.value)}
                                        >
                                            <option value="text">Text Logo</option>
                                            <option value="image">Image Logo</option>
                                        </select>

                                        {brandingType === 'image' ? (
                                            <input
                                                type="url"
                                                value={brandImage}
                                                onChange={(e) => setBrandImage(e.target.value)}
                                                placeholder="Enter logo image URL"
                                                className={styles['url-input']}
                                            />
                                        ) : (
                                            <div className={styles['text-controls']}>
                                                <input
                                                    type="text"
                                                    value={brandText}
                                                    onChange={(e) => setBrandText(e.target.value)}
                                                    placeholder="Enter company name"
                                                    className={styles['text-input']}
                                                />
                                                <div className={styles['color-picker']}>
                                                    <label>Text Color:</label>
                                                    <input
                                                        type="color"
                                                        value={brandColor}
                                                        onChange={(e) => {
                                                            const hexColor = e.target.value;
                                                            setBrandColor(hexColor.length === 7 ? hexColor : '#000000');
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div className={styles['email-brand-link']}>
                                            <label>Brand Link:</label>
                                            <input
                                                type="url"
                                                placeholder="Enter brand link URL"
                                                className={styles['url-input']}
                                                value={brandLink}
                                                onChange={(e) => setBrandLink(e.target.value)}
                                            />
                                        </div>
                                        <div className={styles['color-picker']}>
                                            <label>Background:</label>
                                            <input
                                                type="color"
                                                value={brandBgColor}
                                                onChange={(e) => {
                                                    const hexColor = e.target.value;
                                                    setBrandBgColor(hexColor.length === 7 ? hexColor : '#000000');
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles['control-section']}>
                                    <h3>Button Style</h3>
                                    <div className={styles['button-controls']}>
                                        <div className={styles['color-picker']}>
                                            <label>Button Color:</label>
                                            <input
                                                type="color"
                                                value={buttonColor}
                                                onChange={(e) => setButtonColor(e.target.value)}
                                            />
                                        </div>
                                        <div className={styles['button-text-change']}>
                                            <label>Button Text:</label>
                                            <input
                                                type="text"
                                                value={buttonText}
                                                onChange={(e) => setButtonText(e.target.value)}
                                                placeholder="Enter button text"
                                            />
                                        </div>
                                        <div className={styles['email-button-link']}>
                                            <label>Button Link:</label>
                                            <input
                                                type="url"
                                                placeholder="Enter button link URL"
                                                className={styles['url-input']}
                                                value={buttonLink}
                                                onChange={(e) => setButtonLink(e.target.value)}
                                            />
                                        </div>
                                        <div className={styles['email-regards-para']}>
                                            <label>Edit Signature</label>
                                            <input
                                                type="text"
                                                value={signature}
                                                onChange={(e) => setSignature(e.target.value)}
                                                placeholder="Enter signature"
                                                className={styles['text-input']}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles['editor-preview-container']}>
                                <div className={styles['editor-section']}>
                                    <h3>Email Content</h3>
                                    <EmailEditor
                                        ref={quillRef}
                                        readOnly={readOnly}
                                        defaultValue={defaultDelta}
                                        onTextChange={handleTextChange}
                                    />
                                </div>

                                <div className={styles['preview-section']}>
                                    <div className={styles['preview-header']}>
                                        <h3>Preview</h3>
                                        <Button visual='standard' onClick={refreshPreview}>
                                            Refresh Preview
                                        </Button>
                                    </div>
                                    <iframe
                                        title="Template Preview"
                                        srcDoc={previewContent}
                                        className={styles['preview-frame']}
                                        sandbox="allow-same-origin"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className={styles['modal-footer']}>
                    {template.isDefault ? (
                        <Button visual='primary' onClick={onClose}>Close</Button>
                    ) : isEditing ? (
                        <>
                            <Button visual='standard' onClick={() => setIsEditing(false)}>Cancel</Button>
                            <Button visual='standard' onClick={handleSave}>Save Changes</Button>
                        </>
                    ) : (
                        <>
                            <Button visual='standard' onClick={onClose}>Close</Button>
                            <Button visual='standard' onClick={() => setIsEditing(true)}>Edit Template</Button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TemplateModal;