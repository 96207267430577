import React, { useState, useEffect } from 'react';
import { getApiUrl } from '../utils/apiUtils';
import TemplateModal from './TemplateModal';
import CreateUseCaseModal from './CreateUseCaseModal';
import { Link } from 'react-router-dom';
import styles from './AdminEditTemplates.module.css';
import { Box, Button, Heading, Text } from '@chakra-ui/react';

const Header = () => {
    return (
        <header className={styles.header}>
            <div className={styles.left}>
                <Link to="/" className={styles.logo}>
                    <span className={styles.logoPeh}>Peh</span>
                    <span className={styles.logoChaan}>chaan</span>
                </Link>
            </div>
        </header>
    );
};

const SearchBar = ({ value, onChange, placeholder }) => {
    return (
        <div className={styles.searchContainer}>
            <input
                type="text"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder={placeholder}
                className={styles.searchInput}
            />
            <svg className={styles.searchIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
        </div>
    );
};

const AdminEditTemplates = () => {
    const [useCases, setUseCases] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [newUseCaseData, setNewUseCaseData] = useState(null);
    const [editingDisplayName, setEditingDisplayName] = useState(null);
    const [tempDisplayName, setTempDisplayName] = useState('');

    const filteredTemplates = templates.filter(template => {
        const useCase = useCases.find(uc => uc.id === template.notification_use_case_id);
        const searchLower = searchQuery.toLowerCase();
        return useCase?.use_case.toLowerCase().includes(searchLower) ||
            useCase?.displayName?.toLowerCase().includes(searchLower);
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const [useCasesResponse, templatesResponse] = await Promise.all([
                fetch(getApiUrl('/api/notification-use-cases')),
                fetch(getApiUrl('/api/notification-templates-default'))
            ]);

            if (!useCasesResponse.ok || !templatesResponse.ok) {
                throw new Error('Failed to fetch data');
            }

            const [useCasesData, templatesData] = await Promise.all([
                useCasesResponse.json(),
                templatesResponse.json()
            ]);

            setUseCases(useCasesData);
            setTemplates(templatesData);
        } catch (error) {
            setError('Failed to load data');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCreateUseCase = async (useCaseData) => {
        setNewUseCaseData(useCaseData);
        setSelectedTemplate({
            id: 'new',
            notificationTemplate: {
                emailTemplate: getDefaultTemplate(),
                notification_use_case_id: 'new'
            }
        });
        setIsCreateModalOpen(false);
        setIsTemplateModalOpen(true);
    };

    const handleUpdateDisplayName = async (useCaseId) => {
        try {
            const response = await fetch(getApiUrl(`/api/notification-use-cases/${useCaseId}`), {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    displayName: tempDisplayName
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update display name');
            }

            setEditingDisplayName(null);
            setTempDisplayName('');
            fetchData();
        } catch (error) {
            setError(error.message);
            console.error('Error:', error);
        }
    };

    const handleSaveTemplate = async (template) => {
        try {
            if (newUseCaseData) {
                const formattedUseCase = newUseCaseData.useCase
                    .trim()
                    .replace(/\s+/g, '_')
                    .toUpperCase();

                const useCaseResponse = await fetch(getApiUrl('/api/notification-use-cases/create'), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        use_case: formattedUseCase,
                        displayName: newUseCaseData.displayName.trim()
                    })
                });

                if (!useCaseResponse.ok) {
                    throw new Error('Failed to create use case');
                }

                const useCaseData = await useCaseResponse.json();

                const templateResponse = await fetch(getApiUrl('/api/notification-templates'), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        emailTemplate: template.notificationTemplate.emailTemplate,
                        notification_channel: "Email",
                        notification_use_case_id: useCaseData.id,
                        isDefault: true,
                    })
                });

                if (!templateResponse.ok) {
                    throw new Error('Failed to create template');
                }

                setNewUseCaseData(null);
            } else {
                const response = await fetch(getApiUrl(`/api/notification-templates/${template.id}`), {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        emailTemplate: template.notificationTemplate.emailTemplate,
                        notification_channel: "Email",
                        notification_use_case_id: template.notificationTemplate.notification_use_case_id,
                        isDefault: true,
                    })
                });

                if (!response.ok) {
                    throw new Error('Failed to update template');
                }
            }

            fetchData();
            setIsTemplateModalOpen(false);
        } catch (error) {
            setError(error.message);
            console.error('Error:', error);
        }
    };

    const handleTemplateClick = (template) => {
        setSelectedTemplate({
            id: template.id,
            notificationTemplate: {
                emailTemplate: template.emailTemplate,
                notification_use_case_id: template.notification_use_case_id
            }
        });
        setIsTemplateModalOpen(true);
    };

    if (loading) {
        return (
            <div className={styles.loader}>
                <div className={styles.spinner}></div>
                <p>Loading...</p>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <Header />

            <div className={styles.content}>
                <div className={styles.topSection}>
                    <div className={styles.createSection}>
                        <button
                            onClick={() => setIsCreateModalOpen(true)}
                            className={`${styles.createButton} poppins-regular`}
                        >
                            Create New Use Case
                        </button>
                    </div>
                    <SearchBar
                        value={searchQuery}
                        onChange={setSearchQuery}
                        placeholder="Search by use case or display name..."
                    />
                </div>

                {error && (
                    <div className={styles.error}>
                        {error}
                        <button onClick={() => setError(null)}>✕</button>
                    </div>
                )}

                <div className={styles.templateList}>
                    <Heading as='h2' className={styles.sectionTitle}>Default Templates</Heading>
                    <div className={styles.listHeader}>
                        <div>Use Case</div>
                        <div>Last Modified</div>
                        <div>Actions</div>
                    </div>
                    {filteredTemplates.map(template => {
                        const useCase = useCases.find(uc => uc.id === template.notification_use_case_id);
                        return (
                            <div key={template.id} className={styles.templateItem}>
                                <div>
                                    {editingDisplayName === useCase?.id ? (
                                        <div className={styles.displayNameEdit}>
                                            <input
                                                type="text"
                                                value={tempDisplayName}
                                                onChange={(e) => setTempDisplayName(e.target.value)}
                                                className={styles.displayNameInput}
                                            />
                                            <Button
                                                onClick={() => handleUpdateDisplayName(useCase.id)}
                                                visual='secondary'
                                                className={styles.saveDisplayNameButton}
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setEditingDisplayName(null);
                                                    setTempDisplayName('');
                                                }}
                                                visual='secondary'
                                                className={styles.cancelDisplayNameButton}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    ) : (
                                        <div className={styles.displayNameContainer}>
                                            <span>{useCase?.displayName || useCase?.use_case.replace(/_/g, ' ')}</span>
                                            <Button
                                                onClick={() => {
                                                    setEditingDisplayName(useCase?.id);
                                                    setTempDisplayName(useCase?.displayName || '');
                                                }}
                                                visual='standard'
                                                className={styles.editDisplayNameButton}
                                            >
                                                Edit Name
                                            </Button>
                                        </div>
                                    )}
                                </div>
                                <div>{new Date(template.updatedAt).toLocaleDateString()}</div>
                                <div>
                                    <Button
                                        onClick={() => handleTemplateClick(template)}
                                        visual='secondary'
                                    // className={`${styles.editButton} poppins-regular`}
                                    >
                                        Edit Template
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            {isTemplateModalOpen && selectedTemplate && (
                <TemplateModal
                    template={selectedTemplate}
                    onClose={() => {
                        setIsTemplateModalOpen(false);
                        setNewUseCaseData(null);
                    }}
                    onSave={handleSaveTemplate}
                />
            )}

            {isCreateModalOpen && (
                <CreateUseCaseModal
                    onClose={() => setIsCreateModalOpen(false)}
                    onSave={handleCreateUseCase}
                />
            )}
        </div>
    );
};

const getDefaultTemplate = () => {
    return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html dir="ltr" lang="en">
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="Content-Type" />
    <meta name="x-apple-disable-message-reformatting" />
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet" />
  </head>
  <body style="background-color: #ffffff; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif; margin: 0; padding: 0;">
    <table align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="max-width: 37.5em; margin: 0 auto; padding: 20px 0 48px;">
      <tbody>
        <tr style="width: 100%;" class="email-brand-tableRow">
          <td class="email-brand-tableData" style="text-align: center; background-color: #000; padding: 20px;">
            <a class="email-brand-link" href="https://www.pehchaan.me/" target="_blank" style="text-decoration: none;">
              <h1 class="email-brand-logo" style="display: inline-block; font-family: 'Poppins', sans-serif; color: #ffffff; margin: 0; font-size: 32px;">
                Peh<span style="color: #EDC500;">chaan</span>
              </h1>
            </a>
          </td>
        </tr>
        <tr class="email-content-tableRow">
          <td class="email-content-tableData" style="padding: 20px;">
            <div class="email-main-div">
              <div class="email-body-all-para">
                <p class="email-para-content" style="font-size: 16px; line-height: 26px; margin: 16px 0;">
                  Hi {{firstName}} {{lastName}},<br><br>
                  Welcome to Pehchaan, your trusted platform for career growth and job interview preparation. We're excited to have you on board and look forward to supporting you on your journey.<br><br>
                </p>
              </div>
              <a class="email-button-link" href="https://www.pehchaan.me/jobs" target="_blank" style="display: block; max-width: 100%; background-color:#EDC500; border-radius: 3px; color: #000000; font-size: 16px; text-align: center; padding: 12px; font-family: 'Poppins', sans-serif; text-decoration: none;">
                <span class="email-button-text" style="display: inline-block; line-height: 120%;color:#000000;">Get Started</span>
              </a>
              <br><br>
              <div class="email-regards-para">
                Best,<br />
                <span class="sender-signature">The Pehchaan Team</span><br><br>
                <hr style="width: 100%; border: none; border-top: 1px solid #eaeaea; margin: 20px 0;" />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </body>
</html>`;
};

export default AdminEditTemplates;