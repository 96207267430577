import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getApiUrl } from '../utils/apiUtils';
import { ClientUserContext } from '../context/ClientUserContext'; // Import ClientUserContext
import { IoIosCloseCircle } from 'react-icons/io';
import { IoClose, IoChevronForward } from 'react-icons/io5';
import styles from './CreateInterviews.module.css';
import CreateInterviewForm from './CreateInterviewForm';

const CreateInterviews = () => {
  const [error, setError] = useState(null); // New state for error handling
  const [loading, setLoading] = useState(true);
  const [loaderMessage, setLoaderMessage] = useState('Fetching Interviews');

  //   const [errors, setErrors] = useState({}); // New state for error handling

  const [job, setJob] = useState();
  const [interviews, setInterviews] = useState([]);
  const [reusedJobInterviews, setReusedJobInterviews] = useState([]);
  const [allInterviews, setAllInterviews] = useState([]);
  const [allEvaluationCategories, setAllEvaluationCategories] = useState();
  // const [publishVerifyModal, setPublishVerifyModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState('interviews-list');
  const { clientUser } = useContext(ClientUserContext); // Access client user details from context
  const clientId = clientUser.client_id;
  console.log('Client Id: ', clientId);
  console.log('Client User Id: ', clientUser.id);

  //Filter Functionality
  const [jobSearch, setJobSearch] = useState('');
  const [interviewSearch, setInterviewSearch] = useState('');
  const [filteredInterviews, setFilteredInterviews] = useState([]);

  const { jobId } = useParams();

  const navigate = useNavigate();

  if (error) {
    console.log('Error: ', error);
  }

  const getInterviews = async () => {
    try {
      const url = getApiUrl(`/api/client-jobs/${jobId}/interviews`);
      const res = await fetch(url);
      const data = await res.json();

      const sortedInterviews = data.sort(
        (a, b) => a.client_job_interview_order - b.client_job_interview_order
      );
      if (res.status === 200) {
        console.log(sortedInterviews);
        setInterviews(sortedInterviews);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllInterviews = async () => {
    try {
      // Step 1: Fetch all jobs for the given client ID
      const jobsUrl = getApiUrl(`/api/client-jobs/client/${clientId}`);
      const jobsRes = await fetch(jobsUrl);

      if (!jobsRes.ok) {
        throw new Error(`Failed to fetch jobs: ${jobsRes.status}`);
      }

      const jobsData = await jobsRes.json();
      console.log('Jobs Data: ', jobsData);

      // Step 2: Extract job IDs from the jobs data excluding the current jobId
      // const jobIds = jobsData.map((job) => job.id);
      const jobIds = jobsData
        .map((job) => job.id)
        .filter((id) => id.toString() !== jobId.toString()); // Ensures type safety

      // Step 3: Fetch interviews for each job ID
      const interviewsPromises = jobIds.map(async (jobId) => {
        try {
          const url = getApiUrl(`/api/client-jobs/${jobId}/interviews`);
          const res = await fetch(url);

          if (!res.ok) {
            console.warn(
              `Failed to fetch interviews for job ${jobId}: ${res.status}`
            );
            return []; // Return an empty array on failure
          }

          const data = await res.json();
          return data.map((interview) => ({ ...interview, jobId })); // Include jobId for context
        } catch (error) {
          console.error(`Error fetching interviews for job ${jobId}:`, error);
          return []; // Return an empty array on exception
        }
      });

      // Wait for all interview fetches to complete
      const interviewsResults = await Promise.all(interviewsPromises);

      // Flatten the array of interviews
      const allInterviews = interviewsResults.flat();

      // // Sort interviews by job ID and interview order
      // const sortedInterviews = allInterviews.sort((a, b) => {
      //     if (a.jobId === b.jobId) {
      //         return a.client_job_interview_order - b.client_job_interview_order;
      //     }
      //     return a.jobId - b.jobId;
      // });

      // console.log("All Interviews:", sortedInterviews);
      // setAllInterviews(sortedInterviews);
      // setLoading(false);

      // Group interviews by job ID
      const interviewsByJob = jobIds
        .map((jobId) => {
          const job = jobsData.find((job) => job.id === jobId);
          return {
            job,
            interviews: allInterviews.filter(
              (interview) => interview.jobId === jobId
            ),
          };
        })
        .filter((group) => group.interviews.length > 0); // Exclude jobs with no interviews

      console.log('Interviews By Job: ', interviewsByJob);
      setAllInterviews(interviewsByJob);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    const getJob = async () => {
      try {
        const url = getApiUrl(`/api/client-jobs/${jobId}/`);
        const res = await fetch(url);
        const data = await res.json();

        if (res.status === 200) {
          console.log(data);
          setJob(data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const getAllEvaluationCategories = async () => {
      try {
        const url = getApiUrl(`/api/job-interview-evaluation-categories`);
        const res = await fetch(url);
        const categories = await res.json();

        if (res.status === 200) {
          setAllEvaluationCategories(
            categories.reduce((acc, category) => {
              acc[category.id] = category;
              return acc;
            }, {})
          );
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getInterviews();
    getJob();
    getAllEvaluationCategories();
    getAllInterviews();
  }, []);

  const filterInterviews = async () => {
    // Filter logic whenever search inputs or allInterviews change
    const filtered = allInterviews
      .filter((group) =>
        group.job.job_title.toLowerCase().includes(jobSearch.toLowerCase())
      )
      .map((group) => ({
        ...group,
        interviews: group.interviews.filter((interview) =>
          interview.interview.interview_name
            .toLowerCase()
            .includes(interviewSearch.toLowerCase())
        ),
      }))
      .filter((group) => group.interviews.length > 0); // Remove jobs with no matching interviews

    setFilteredInterviews(filtered);
  };
  useEffect(() => {
    filterInterviews();
  }, [jobSearch, interviewSearch, allInterviews]);

  const getReusedJobInterviews = async () => {
    try {
      const url = getApiUrl(`/api/reused-job-interview/${jobId}`);
      const res = await fetch(url);
      const data = await res.json();

      if (res.status === 200) {
        console.log('ReusedJobInterviews:', data);
        setReusedJobInterviews(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReusedJobInterviews();
  }, []);

  const handleAddToJob = async (prevInterview) => {
    console.log('Specific Interview Data', prevInterview);
    const prevJobId = prevInterview.jobId;
    const prevInterviewId = prevInterview.interview.id;

    console.log('Previous JobId: ', prevJobId);
    console.log('Previous InterviewId: ', prevInterviewId);

    try {
      setLoaderMessage('Creating the Interview');

      // Adding to ReusedInterviewsList
      const res8 = await fetch(getApiUrl('/api/reused-job-interview'), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          job_id: jobId,
          interview_id: prevInterviewId,
        }),
      });
      if (res8.ok) {
        console.log('Added entry to reusedJobInterview');
        const newEntry = await res8.json();
        setReusedJobInterviews((prev) => [...prev, newEntry]);
      }

      //Creating Interview
      const res = await fetch(getApiUrl('/api/interviews'), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          client_user_id: clientUser.id,
          interview_name: prevInterview.interview.interview_name,
          interview_time_limit: prevInterview.interview.interview_time_limit,
          time_limit_per_answer: prevInterview.interview.time_limit_per_answer,
          status: prevInterview.interview.status,
        }),
      });
      const data = await res.json(); // data.id -> interview id

      //Linking Interview to ClientJobInterview
      console.log('Interview Created Successfully');
      if (res.ok) {
        const link = await fetch(
          getApiUrl('/api/client-job-interviews/link/'),
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              job_id: jobId,
              interview_id: data.id,
              interview_order: interviews.length + 1,
              question_set_selection_method:
                prevInterview.question_set_selection_method,
              question_set_fixed: prevInterview.question_set_fixed,
              number_of_question_sets: prevInterview.number_of_question_sets,
            }),
          }
        );

        if (link.ok) {
          console.log('Client Job Interview Created Successfully');
          const linkData = await link.json(); // linkData.id -> client_job_interview_id

          const newInterview = { 
            client_job_interview_id: linkData.id, 
            client_job_interview_order: linkData.interview_order,
            interview: data 
          };

          setInterviews(prevInterviews => ([
            ...prevInterviews,
            newInterview
          ]));

          //Fetching and Adding Evaluation Criteria
          setLoaderMessage('Adding Evaluation Criteria');

          const url = getApiUrl(
            `/api/interview-evaluation-criteria/${prevInterview.client_job_interview_id}`
          );
          const res = await fetch(url);
          const evaluationCriteriaData = await res.json();
          const evaluationCriteria = evaluationCriteriaData.map(criterion => {
            return { id: criterion.evaluation_category_id, description: criterion.instructions };
          });

          // add each evaluation criteria to the interview
          const evaluationResponse = await fetch(getApiUrl('/api/interview-evaluation-criteria/create-with-prompts'), {
						method: 'POST',
						headers: { 'Content-Type' : 'application/json' },
						body: JSON.stringify({
								evaluation_criteria: evaluationCriteria,
								client_job_interview_id: linkData.id
						})
					});
					if(evaluationResponse.ok) console.log('Evaluation criteria and Prompts added to the job for processing');

          //Fetching and Adding Maximum Score
          setLoaderMessage('Adding Maximum Score');

          const url_a = getApiUrl(
            `/api/client-job-interview-max-score/client-job-interview/${prevInterview.client_job_interview_id}`
          );
          const res_a = await fetch(url_a);
          const maxScoreData = await res_a.json();          

          // Add maximum score to the interview
          const scoreResponse = await fetch(getApiUrl('/api/client-job-interview-max-score'), {
						method: 'POST',
						headers: { 'Content-Type' : 'application/json' },
						body: JSON.stringify({
								client_job_interview_id: linkData.id,
                max_score: maxScoreData?.max_score,
						})
					});
					if(scoreResponse.ok) console.log('Maximum Score added to the interview successfully');
        }

        //Fetching and Adding Instructions
        setLoaderMessage('Adding the Instructions');

        const url2 = getApiUrl(
          `/api/interviews/${prevInterviewId}/instructions`
        );
        const res2 = await fetch(url2);
        const interviewInstructions = await res2.json();

        const instructionsResponse = await fetch(
          getApiUrl(`/api/interviews/${data.id}/instructions`),
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              pre_interview_instructions:
                interviewInstructions.pre_interview_instructions,
              welcome_message: interviewInstructions.welcome_message,
              welcome_video_url: interviewInstructions.welcome_video_url,
              context_video_url: interviewInstructions.context_video_url,
              context_video_text: interviewInstructions.context_video_text,
              interview_response_type:
                interviewInstructions.interview_response_type,
              language: interviewInstructions.language,
            }),
          }
        );
        if (instructionsResponse.ok) {
          console.log('Added instructions to the interview');
        }

        //Fetching and Adding Questions
        setLoaderMessage('Adding Questions');

        const url3 = getApiUrl(`/api/interviews/${prevInterviewId}/questions`);
        const res3 = await fetch(url3);
        const questionsArray = await res3.json();

        //Creating Questions

        await Promise.all(
          questionsArray.map(async (questionObject) => {
            const res4 = await fetch(getApiUrl(`/api/questions`), {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                client_user_id: clientUser.id,
                question_text: questionObject.question.question_text,
                question_type: questionObject.question.question_type,
                media_url: questionObject.question.media_url,
                text_instructions: questionObject.question.text_instructions,
              }),
            });

            const data2 = await res4.json(); // data2.id -> question id

            // Linking Question to Interview
            if (res4.ok) {
              const res5 = await fetch(getApiUrl('/api/interview-questions/link'), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  interview_id: data.id,
                  question_id: data2.id,
                  question_order: questionObject.question_order,
                  question_set: questionObject.question_set,
                }),
              });
              const interviewQuestionData = await res5.json();
              if (res5.ok) {
                console.log('Question Linked Successfully');
              }

              // Fetching Reference answers for questions using question id
              const url6 = getApiUrl(`/api/job-interview-prompts/reference-answers/${questionObject.id}`);
              const res6 = await fetch(url6);
              const referenceAnsArray = await res6.json();

              // Creating all Reference answers concurrently
              await Promise.all(
                referenceAnsArray.map(async (refAns) => {
                  await fetch(getApiUrl(`/api/job-interview-prompts/reference-answers`), {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                      interview_question_id: interviewQuestionData.id,
                      evaluation_category_id: refAns.evaluation_category_id,
                      answer: refAns.answer,
                      score: refAns.score,
                    }),
                  });
                })
              );
              console.log('Reference answers for a Question generated Successfully');
            }
          })
        );
        console.log('Questions Created Successfully');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      getAllInterviews();
    }
  };

  // const handleToggleJob = async () => {
  //     try {
  //         let newStatus = job.status === 'Open' ? 'Closed' : 'Open';
  //         const res = await fetch(getApiUrl(`/api/client-jobs/${jobId}`), {
  //             method: 'PUT',
  //             headers: { 'Content-Type': 'application/json' },
  //             body: JSON.stringify({ status: newStatus })
  //         });
  //         if(res.ok) {
  //             setJob(jobInfo => ({ ...jobInfo, status: newStatus }));
  //         }
  //     } catch (error) {
  //         console.log(error);
  //     }
  // }

  // const handlePublishJob = async (clientJobInterview) => {
  //     const clientJobInterviewId = clientJobInterview.client_job_interview_id;
  //     try {
  //         const res = await fetch(getApiUrl(`/api/interviews/client-job-interview/${clientJobInterviewId}/details`));
  //         const interview = await res.json();

  //         if(res.ok) {
  //             // Validate number of questions in each set for the interview
  //             const numberOfSets = interview.interviewData.number_of_question_sets;
  //             const numberOfQuestions = new Array(numberOfSets).fill(0);
  //             interview.interviewData.interview.interview_questions.forEach(question => {
  //                 numberOfQuestions[question.question_set - 1]++;
  //             });
  //             console.log(numberOfQuestions);

  //             if(numberOfQuestions[0] === 0) {
  //                 setError('There are no questions in some question sets');
  //                 setPublishVerifyModal(true);
  //                 return;
  //             }

  //             for(let idx = 0; idx < numberOfSets - 1; idx++) {
  //                 if(numberOfQuestions[idx] !== numberOfQuestions[idx + 1]) {
  //                     setError('The interview contains uneven number of questions in different sets');
  //                     setPublishVerifyModal(true);
  //                     return;
  //                 }
  //             }

  //             const interviewRes = await fetch(getApiUrl(`/api/interviews/${clientJobInterview.interview.id}`), {
  //                 method: 'PUT',
  //                 headers: { 'Content-Type': 'application/json' },
  //                 body: JSON.stringify({
  //                     interview_name: clientJobInterview.interview.interview_name,
  //                     status: 'Published',
  //                     interview_time_limit: clientJobInterview.interview.time_limit_per_answer * numberOfQuestions[0],
  //                     time_limit_per_answer: clientJobInterview.interview.time_limit_per_answer
  //                 })
  //             });

  //             if(interviewRes.ok) {
  //                 console.log('Interview is published');
  //                 setInterviews(prevInterviews => {
  //                     return prevInterviews.reduce((acc, intr, idx) => {
  //                         if(intr.client_job_interview_id !== clientJobInterview.client_job_interview_id) {
  //                             acc[idx] = intr;
  //                             console.log(acc[idx]);
  //                         } else {
  //                             acc[idx] = { ...intr, interview: {
  //                                 ...intr.interview,
  //                                 status: 'Published'
  //                             }}
  //                             console.log(acc[idx]);
  //                         }
  //                         return acc;
  //                     }, []);
  //                 })
  //             }
  //         }
  //     } catch(error) {
  //         console.log(error);
  //     }
  // }

  // const togglePublishVerifyModal = async () => {
  //     setPublishVerifyModal(!publishVerifyModal);
  // }

  const handleJobSearchChange = (e) => {
    setJobSearch(e.target.value);
  };

  const handleInterviewSearchChange = (e) => {
    setInterviewSearch(e.target.value);
  };

  const TabContent = ({ selectedTab, tabData, jobData }) => {
    const INITIAL_QUESTION_FORM_DATA = {
      // Form Page 1 Data
      interviewName: '',
      interviewResponseType: 'audio',
      interviewLanguage: 'en-IN',

      // Form Page 2 Data
      preInterviewInstructions:
        "Please speak clearly into your phone or laptop's microphone.",
      welcomeMessage: `Welcome to Round ${
        jobData.interviews.length + 1
      } of our interview process. We wish you all the best!`,
      contextVideoText: '',
      contextVideoUrl: '',
      welcomeVideoUrl: '',

      // Form Page 3 Data
      skillsToEvaluate: [],
      interviewEvaluationCriteria: [],
      numberOfQuestionSets: 1,
      setSelectionMethod: 'fixed',
      questionsPerSet: 1,
      timeLimitPerQuestion: 60,
      questionDifficultyLevel: 'Entry Level',
      scoringCriteria: '[0-1]',
      mediaOrientation: 'potrait',
      videoTemplate: 'video_with_avatar',

      // Form Page 4 Data
      questionSpecificationPrompt: '',
    };
    const [newInterviewFormData, setNewInterviewFormData] = useState(
      INITIAL_QUESTION_FORM_DATA
    );
    const navigate = useNavigate();

    switch (selectedTab) {
      case 'interviews-list':
        return (
          <div className={styles.interviewsListContainer}>
            <h3>Interview List</h3>

            <div className={styles.interviewsList}>
              {jobData.interviews.length !== 0 ? (
                jobData.interviews.map((interview, idx) => {
                  return (
                    <div
                      className={styles.interviewCard}
                      key={idx}
                      onClick={() =>
                        navigate(`${interview.interview.id}/questions`, {
                          state: {
                            clientJobInterviewId:
                              interview.client_job_interview_id,
                          },
                        })
                      }
                    >
                      <p>
                        {interview.client_job_interview_order}.{' '}
                        {interview.interview.interview_name}
                      </p>
                      <p className={styles.statusTag}>
                        {interview.interview.status}
                      </p>
                    </div>
                  );
                })
              ) : (
                <p>No Interviews to show.</p>
              )}
            </div>
          </div>
        );
      case 'existing-interviews':
        return (
          <div className={styles.interviewsListContainer}>
            <h3>Jobs and Interviews</h3>
            {/* Filter and Search */}
            <div className={styles.searchBar}>
              <input
                type="text"
                placeholder="Search by Job Name"
                value={jobSearch}
                onChange={handleJobSearchChange}
                className={styles.searchInput}
              />
              <input
                type="text"
                placeholder="Search by Interview Name"
                value={interviewSearch}
                onChange={handleInterviewSearchChange}
                className={styles.searchInput}
              />
            </div>
            <div className={styles.jobsList}>
              {filteredInterviews.length !== 0 ? (
                filteredInterviews.map((jobWithInterviews, idx) => (
                  <div key={idx} className={styles.jobCard}>
                    <h4>{jobWithInterviews.job.job_title}</h4>
                    <div className={styles.interviewsSubList}>
                      {jobWithInterviews.interviews.length !== 0 ? (
                        jobWithInterviews.interviews.map(
                          (interview, subIdx) => {
                            // Check if the interview exists in the `interviews` state
                            const isDisabled = reusedJobInterviews.some(
                              (reusedJobInterview) =>
                                reusedJobInterview.interview_id ===
                                interview.interview.id
                            );

                            return (
                              <div
                                className={`${styles.interviewCard} ${
                                  isDisabled ? styles.disabledCard : ''
                                }`}
                                key={subIdx}
                                onClick={() =>
                                  !isDisabled &&
                                  navigate(
                                    `${interview.interview.id}/questions`,
                                    {
                                      state: {
                                        clientJobInterviewId:
                                          interview.client_job_interview_id,
                                      },
                                    }
                                  )
                                }
                              >
                                <p>
                                  {interview.client_job_interview_order}.{' '}
                                  {interview.interview.interview_name}
                                </p>
                                <button
                                  className={styles.addToJobButton}
                                  disabled={isDisabled} // Disable the button if the interview exists
                                  onClick={(e) => {
                                    e.stopPropagation(); // Stop the event from propagating to the parent
                                    if (!isDisabled) {
                                      setLoading(true);
                                      handleAddToJob(interview);
                                    }
                                  }}
                                >
                                  {isDisabled ? 'Already Added' : 'Add to Job'}
                                </button>
                              </div>
                            );
                          }
                        )
                      ) : (
                        <p>No Interviews for this job.</p>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <p>No Jobs or Interviews to show.</p>
              )}
            </div>
          </div>
        );

      case 'create-interview':
        return (
          <CreateInterviewForm
            newInterviewFormData={newInterviewFormData}
            setNewInterviewFormData={setNewInterviewFormData}
            allEvaluationCategories={tabData}
            jobData={jobData}
          />
        );
    }
  };

  return (
    <div className={styles.jobInterviewContainer}>
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
          <div className="loader-message">{loaderMessage}...</div>
        </div>
      )}
      {/* { publishVerifyModal && <Modal toggleModal={ togglePublishVerifyModal } headerText='Error publishing interview' bodyText={error} buttonText='Close' /> } */}

      <div className={styles.bgBlob1}></div>
      <div className={styles.bgBlob2}></div>
      <div className={styles.bgBlob3}></div>
      <header className={styles.navbar}>
        <Link to="/" className="logo">
          <span className="logo-peh">Peh</span>
          <span className="logo-chaan">chaan</span>
        </Link>
        {/* <div>
                    <p>Welcome, </p>
                    <h3>{clientUser.user_name}</h3>
                </div> */}
      </header>
      <div className={styles.jobInterviewMain}>
        <div className={styles.interviewSidebar}>
          <div>{job && <h4>{job.job_title}</h4>}</div>
          <div className={styles.interviewSidebarTab}>
            <button
              className={
                selectedTab === 'create-interview' ? styles.active : ''
              }
              onClick={() => setSelectedTab('create-interview')}
            >
              <p>Create Interview</p>
            </button>
          </div>
          <div className={styles.interviewSidebarTab}>
            <button
              className={selectedTab === 'interviews-list' ? styles.active : ''}
              onClick={() => {
                setSelectedTab('interviews-list');
                getInterviews();
              }}
            >
              <p>Interviews List</p>
            </button>
          </div>
          <div className={styles.interviewSidebarTab}>
            <button
              className={
                selectedTab === 'existing-interviews' ? styles.active : ''
              }
              onClick={() => setSelectedTab('existing-interviews')}
            >
              <p>Use Existing Interviews</p>
            </button>
          </div>
        </div>

        <div className={styles.jobInterviewContent}>
          <TabContent
            selectedTab={selectedTab}
            jobData={{ ...job, interviews: interviews }}
            tabData={allEvaluationCategories}
          />
          {/* <div className='interviews'>        
                    {
                        interviews.length !== 0 && interviews.map(interview => (
                            <div className='interview' key={interview.interview.id}>
                                <div className='info'>
                                    <h4>{ interview.client_job_interview_order }. { interview.interview.interview_name }</h4>
                                    <p id='tag'>{interview.interview.status}</p>
                                </div>
                            </div>
                        ))
                    }
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default CreateInterviews;
